import {Component, OnInit, ViewChild} from '@angular/core';
import {filter, first, tap} from 'rxjs/operators';
import {AppInitService} from '../../../../shared/service/app-init.service';
import {CicloCorsoRuoloInterface} from '../../../../shared/interface/CicloCorsoRuoloInterface';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DialogInfoComponent, DialogInfoI} from '../../../../shared/components/dialog-info/dialog-info.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    AuthorityType,
    CicliCorsiDiStudiService,
    CicloCorsoDiStudiInfoDTO,
    CicloCorsoDiStudiInfoView,
    CollegioDocentiInfoView,
    CollegioDocentiRuolo,
    CollegioDocentiTipoPersonale,
    CommissioneDiAutovalutazioneInfoView,
    CorsoDiStudiViewImpl,
    DirettoreDiDipartimentoInfoView,
    Esterno,
    GruppoDiCoordinamentoInfoView,
    GruppoPTAInfoView,
    MediaService,
    MembroCollegioDocentiDTO,
    RuoloUtenteCorsoDiStudiInfoView,
    SegretarioAmministrativoDiDipartimentoInfoView,
    SottoruoloUtenteCorsoDiStudiInfoView,
    SottoruoloUtenteStudenteInfoView,
    StoricoMembriCorsoDiStudiInfoView,
    StoricoMembriStudenteInfoView, TipoMembroCommissioneDiAutovalutazione,
    TipoMembroCorsoDiStudi,
    UpdateCommissioneDiAutovalutazioneDTO,
    UpdateCommissioneDiAutovalutazioneResponseDTO,
    UpdateCoordinatoreCollegioDocentiRequestDTO,
    UpdateCoordinatoreCollegioDocentiResponseDTO,
    UpdateDirettoriDiDipartimentoDTO,
    UpdateDirettoriDiDipartimentoResponseDTO,
    UpdateGruppoDiCoordinamentoDTO,
    UpdateGruppoDiCoordinamentoResponseDTO,
    UpdateGruppoPTADTO,
    UpdateGruppoPTAResponseDTO,
    UpdateMembriCollegioDocentiDTO,
    UpdateMembriCollegioDocentiResponseDTO,
    UpdateSegretariAmministrativiDiDipartimentoDTO,
    UpdateSegretariAmministrativiDiDipartimentoResponseDTO,
    UserAdminRoleUpdateDTO,
    UserAdminRoleUpdateDTORequest,
    UserFilterForm,
    UserRoleBasedInfoView,
    UsersService
} from '../../../../../api-clients/generated/services';
import {BehaviorSubject, finalize, map, of, switchMap, take, takeUntil} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {
    AbstractDefaultComponent
} from '../../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {TableData, TableDataRows} from '../../../../shared/components/table/model/generic-table-model';
import {
    DialogTableMultiSelectComponent
} from '../../../../shared/components/dialog-table-multi-select/dialog-table-multi-select.component';
import {
    DialogTableMultiSelectConfiguration
} from '../../../../shared/components/dialog-table-multi-select/dialog-table-multi-select.model';
import {get, head, isEmpty, sortBy, trim, unionBy} from 'lodash';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {SnackbarTypes} from '../../../../../@fuse/services/confirmation/snackbar/snackbar.component';
import {
    DialogSelectDocentiComponent
} from "../../../../shared/components/dialog-select-docenti/dialog-select-docenti.component";
import {
    DialogStoricoComponent,
    DialogStoricoI
} from "../../../../shared/components/dialog-storico/dialog-storico.component";
import {MatTabGroup} from "@angular/material/tabs";
import {UpperCasePipe} from "@angular/common";
import {LogoutService} from "../../../../shared/service/logout.service";
import {openMustRefreshDialog} from "../../../../shared/utils/utils";
import {
    DialogEditCommissioneDiAutovalutazioneComponent,
    DialogEditCommissioneDiAutovalutazioneComponentI
} from "./dialog-edit-commissione-di-autovalutazione/dialog-edit-commissione-di-autovalutazione.component";
import {cloneDeep} from "lodash-es";
import {CollegioDocentiSubTabData} from "./collegio-docenti-group/collegio-docenti-group.component";
import {CommAutovalSubTabData} from "./comm-autoval-group/comm-autoval-group.component";
import RuoloEnum = UserFilterForm.RuoloEnum;


@Component({
    selector: 'app-cycle-information',
    templateUrl: './cycle-information.component.html',
    styleUrls: ['./cycle-information.component.scss']
})
export class CycleInformationComponent extends AbstractDefaultComponent implements OnInit {
    @ViewChild('DialogTableMultiSelectComponent') dialogTableMultiSelectComponent: DialogTableMultiSelectComponent;
    nCiclo: string;
    codiceCorso: string;
    startYear: number;
    endYear: number;
    numberCycle: number;
    titleCycle: string;
    coordinator: CollegioDocentiInfoView;
    department: string;
    form: FormGroup;
    loading: boolean = false;
    completeNameCoordinator: string;
    listaMembers: Array<CollegioDocentiInfoView>;
    listaCoordinatori: Array<GruppoDiCoordinamentoInfoView>;
    listaAdmin: RuoloUtenteCorsoDiStudiInfoView[];
    listaEsterni: Esterno[];
    showSelect: boolean = false;
    ctrlSelectFormControl: FormControl = new FormControl(null);
    ctrlCorso: FormControl = new FormControl(null);
    sottoruoli: AuthorityType[];
    currentRuolo: AuthorityType;
    sottoruoliUtenteCorsiDiStudi: Array<SottoruoloUtenteCorsoDiStudiInfoView>;
    sottoruoliUtenteStudente: Array<SottoruoloUtenteStudenteInfoView>;
    listaDocentiEmptyError: string;
    listaCoordinatoriEmptyError: string;
    loadingInfoRequest: boolean;
    loadingDocenti: boolean;
    isGetDocentiError: boolean = false;
     storiciCollegio: StoricoMembriCorsoDiStudiInfoView[] | undefined;
     storiciCoordinatori: StoricoMembriCorsoDiStudiInfoView[] | undefined;
     storiciGruppoCoordinamento: StoricoMembriCorsoDiStudiInfoView[] | undefined;
    listaPTA: Array<GruppoPTAInfoView>;
    listaPTAEmptyError: string;
    AuthorityType = AuthorityType;
    private storiciPTA: StoricoMembriCorsoDiStudiInfoView[] | undefined;
    _tabsGroup: MatTabGroup;
    private userID: string;
    private adminsTimestamp: Date | undefined;
    private gruppoDiCoordinamentoTimestamp: Date | undefined;
    private collegioDocentiTimestamp: Date | undefined;
    private gruppoPTATimestamp: Date | undefined;
    private coordinatoreTimestamp: Date | undefined;
    corsi: Array<CorsoDiStudiViewImpl> | undefined;
    isReadOnly: boolean;
    storiciCommessioneDiAutovalutazione: StoricoMembriCorsoDiStudiInfoView[] | undefined;
    membriCommissioneDiAutovalutazione: Array<CommissioneDiAutovalutazioneInfoView>;
    private gruppoCommissioneDataInizio: Date | undefined;
    protected readonly UpperCasePipe = UpperCasePipe;
    listaSAD: SegretarioAmministrativoDiDipartimentoInfoView[];
    storiciSAD: StoricoMembriCorsoDiStudiInfoView[];
    listaDirettoriDipartimento: DirettoreDiDipartimentoInfoView[];
    storiciDirettoriDipartimento: StoricoMembriCorsoDiStudiInfoView[];
    SADTimestamp: Date | undefined;
    direttoriDipartimentoTimestamp: Date | undefined;
    @ViewChild(MatTabGroup) set tabsGroup(tabsGroup: MatTabGroup) {
        if (tabsGroup && !this._tabsGroup) {
            this._tabsGroup = tabsGroup;
            this.handleFragmentNavigation(tabsGroup)
        }
    }

    constructor(public appInitService: AppInitService,
                private formBuilder: FormBuilder,
                private dialog: MatDialog,
                private cicliCorsiDiStudiService: CicliCorsiDiStudiService,
                private _translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private router: Router,
                private usersService: UsersService,
                private mediaService: MediaService,
                private sanitizer: DomSanitizer,
                private localStorageService: LocalStorageService,
                private activatedRoute: ActivatedRoute,
                private logOutService: LogoutService,
                //private timeService: TimeService
    ) {
        super();
        this.form = this.formBuilder.group({
            membriGruppoCoordinamento: [null, Validators.required]
        });
        this._translocoService.selectTranslate('error.empty_professor_list').subscribe(value => this.listaDocentiEmptyError = value);
        this._translocoService.selectTranslate('error.empty_coordinator_list').subscribe(value => this.listaCoordinatoriEmptyError = value);
        this._translocoService.selectTranslate('error.empty_coordinator_list').subscribe(value => this.listaPTAEmptyError = value);
        this.userID = this.localStorageService?.profile?.id;
    }

    ngOnInit(): void {
        this.getSottoruoli$();
        this.valueChange();
        //this.timeService.updateCurrentTime();
    }

    handleFragmentNavigation(tabsGroup: MatTabGroup) {
        this.activatedRoute.fragment.pipe(takeUntil(this.destroy$), first()).subscribe((fragment: string) => {
            console.log('typed fragment', fragment);
            const tabs = (tabsGroup?._tabs as any)._results;
            console.log(tabs)
            let matchIndex = tabs?.findIndex(x => x.ariaLabel === fragment);
            console.log('matched fragment with tab at position', matchIndex)
            if (matchIndex >= 0) {
                tabsGroup.selectedIndex = matchIndex;
            }
        });
    }

    getInfoRequest(firstTime?: boolean): void {
        if (firstTime) {
            this.loadingInfoRequest = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        const esterniRequest = (this.currentSottoruolo === AuthorityType.COORDINATORE || this.currentSottoruolo === AuthorityType.GRUPPODICOORDINAMENTO) ?
            this.usersService.getEsterniCiclo() : of(null);
        const selectedInService$ = this.appInitService.isAreaAdministrator ? this.appInitService.isCicloAmmSelectedInService : this.appInitService.isDipartimentoRuoloCicloSelectedInService;
        selectedInService$.pipe(
            filter(Boolean),
            take(1),
            tap((value: CicloCorsoRuoloInterface) => this.init(value)),
            switchMap(() => this.cicliCorsiDiStudiService.getCicloCorsoDiStudiInfo()),
            switchMap(cicloCorsoInfo => esterniRequest.pipe(map(esterniInfo => [cicloCorsoInfo, esterniInfo]))),
            takeUntil(this.destroy$),
            finalize(() => {
                this.loadingInfoRequest = false;
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: ([cicloCorsoInfoResp, esterniResp]) => {
                // info ciclo
                const cicloCorsoInfo = cicloCorsoInfoResp as CicloCorsoDiStudiInfoDTO;
                this.listaCoordinatori = (cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.membriGruppoDiCoordinamento)?.sort();
                this.membriCommissioneDiAutovalutazione = (cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.membriCommissioneDiAutovalutazione)?.sort();

                this.listaAdmin = cicloCorsoInfo?.cicloCorsoDiStudiRuoliAdmin;
                this.storiciCollegio = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.COLLEGIODOCENTI);
                this.storiciCoordinatori = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.COORDINATORE);
                this.storiciGruppoCoordinamento = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.GRUPPODICOORDINAMENTO);
                this.storiciPTA = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.GRUPPOPTA);
                this.storiciCommessioneDiAutovalutazione = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.COMMISSIONEDIAUTOVALUTAZIONE);

                this.listaPTA = (cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.membriGruppoPTA).sort();
                this.getReadOnlyFields(cicloCorsoInfo?.cicloCorsoDiStudiInfoView, cicloCorsoInfo?.cicloCorsoDiStudiRuoliAdmin);
                this.adminsTimestamp = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.timestampUltimaModificaAdmin;
                this.gruppoDiCoordinamentoTimestamp = this.storiciGruppoCoordinamento?.find(s => !s.dataFine)?.dataInizio;
                this.gruppoCommissioneDataInizio = this.storiciCommessioneDiAutovalutazione?.find(s => !s.dataFine)?.dataInizio;
                this.collegioDocentiTimestamp = this.storiciCollegio?.find(s => !s.dataFine)?.dataInizio;
                this.gruppoPTATimestamp = this.storiciPTA?.find(s => !s.dataFine)?.dataInizio;
                this.coordinatoreTimestamp = this.storiciCoordinatori?.find(s => !s.dataFine)?.dataInizio;
                // esterni
                this.listaEsterni = esterniResp as Esterno[];

                this.listaSAD = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.membriSegretariAmministrativiDiDipartimento?.sort();
                this.storiciSAD = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO);
                this.listaDirettoriDipartimento = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.membriDirettoriDiDipartimento?.sort();
                this.storiciDirettoriDipartimento = cicloCorsoInfo?.cicloCorsoDiStudiInfoView?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.DIRETTOREDIDIPARTIMENTO);
                this.direttoriDipartimentoTimestamp = this.storiciDirettoriDipartimento?.find(s => !s.dataFine)?.dataInizio;
                this.SADTimestamp = this.storiciSAD?.find(s => !s.dataFine)?.dataInizio;

            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                    () => this.logOutService.goToHome(),
                    () => this.getInfoRequest(),
                    err?.error?.message);
            }
        });
    }

    init(dipartimentoRuoloCiclo: CicloCorsoRuoloInterface): void {
        this.nCiclo = dipartimentoRuoloCiclo.ciclo;
        this.codiceCorso = dipartimentoRuoloCiclo.codiceCorsoStudi;
    }

    getReadOnlyFields(infoCycle: CicloCorsoDiStudiInfoView, infoAdmin: RuoloUtenteCorsoDiStudiInfoView[]): void {
        this.startYear = infoCycle?.cicloDottorato?.annoInizio;
        this.endYear = infoCycle?.cicloDottorato?.annoFine;
        this.numberCycle = infoCycle?.cicloDottorato?.numero;
        this.titleCycle = infoCycle?.denominazione;
        this.coordinator = infoCycle?.membriCollegioDocenti?.find((membro: CollegioDocentiInfoView) =>
            membro.ruolo === CollegioDocentiRuolo.COORDINATORE);
        if (!!this.coordinator) {
            this.completeNameCoordinator = (this.coordinator?.user?.cognome).toUpperCase() + ' ' + (this.coordinator?.user?.nome).toUpperCase();
        } else {
            this.completeNameCoordinator = this.listaCoordinatoriEmptyError;
        }
        this.department = infoCycle?.corsoDiStudi?.dipartimento?.nome;
        this.listaMembers = infoCycle?.membriCollegioDocenti;
        this.listaCoordinatori = infoCycle?.membriGruppoDiCoordinamento;
        this.membriCommissioneDiAutovalutazione = infoCycle?.membriCommissioneDiAutovalutazione;
        this.listaAdmin = infoAdmin;
    }


    getSottoruoli$(): void {
        this.loading = true;
        const selectedInService$ = this.appInitService.isAreaAdministrator ? this.appInitService.isCicloAmmSelectedInService : this.appInitService.isDipartimentoRuoloCicloSelectedInService;

        selectedInService$.pipe(
            filter(Boolean),
            take(1),
            tap(value => this.currentRuolo = value?.ruolo),
            switchMap((value) => {
                if (!this.appInitService.isAreaAdministrator && this.isDocenteOrPersonalePtaOrEsterno(this.currentRuolo)) {
                    return this.appInitService.currentSottoruoliUtente$.pipe(first());
                } else {
                    return of(null);
                }
            }),
            tap((sottoruoli: AuthorityType[]) => {
                console.log(sottoruoli)
                if (!!sottoruoli) {
                    this.sottoruoli = sottoruoli;
                    this.setCurrentSottoruolo();
                }
            }),
            takeUntil(this.destroy$),
            finalize(() => this.loading = false)
        ).subscribe({
                next: (value) => {
                    if (this.appInitService.isAreaAdministrator) {
                        this.setCorsoAmm();
                    } else {
                        this.showSelect = this.isDocenteOrPersonalePtaOrEsterno(this.currentRuolo) && !isEmpty(this.sottoruoli);
                        this.getInfoRequest(true);
                    }
                },
                error: (err) => {
                    console.log(err)
                    this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                        () => this.logOutService.goToHome(),
                        () => this.getSottoruoli$(),
                        err?.error?.message);
                }
            }
        );
    }

    private setCorsoAmm() {
        this.corsi = this.appInitService.getCorsiAmm()
        this.isReadOnly = true;
        if (this.corsi.some(c => c.codiceEsse3 === this.appInitService.codiceCorsoStudiAmmSelected)) {
            this.ctrlCorso.setValue(this.appInitService.codiceCorsoStudiAmmSelected);
            this.getInfoRequest(true);
        } else if (this.corsi?.length === 1) {
            this.ctrlCorso.setValue(this.corsi[0].codiceEsse3);
            if (this.appInitService.isAreaAdministrator) {
                this.appInitService.setCodiceCorsoStudiAmm(this.ctrlCorso.getRawValue());
            }
            this.getInfoRequest(true);
        }
    }

    isDocenteOrPersonalePtaOrEsterno(ruolo?: AuthorityType): boolean {
        return (ruolo === AuthorityType.DOCENTE || ruolo === AuthorityType.PERSONALEPTA || ruolo === AuthorityType.ESTERNO);
    }

    setCurrentSottoruolo(): void {
        const savedSottoruolo: AuthorityType = this.localStorageService?.dipartimentoRuoloCiclo?.sottoruolo;
        if (!!savedSottoruolo) {
            const findedSottoruolo = this.sottoruoli.includes(savedSottoruolo);
            if (findedSottoruolo) {
                this.ctrlSelectFormControl.setValue(savedSottoruolo, {emitEvent: false});
            } else {
                this.ctrlSelectFormControl.setValue(head(this.sottoruoli), {emitEvent: false});
            }
        } else {
            // this.initialSottoruolo = head(this.sottoruoli);
            this.ctrlSelectFormControl.setValue(head(this.sottoruoli), {emitEvent: false});
        }
        this.localStorageService.setSottoruoloCiclo(this.ctrlSelectFormControl?.value);
        this.appInitService.cicloCorsoRuoloSelected$.next(this.appInitService.cicloCorsoRuoloSelected);
    }

    valueChange(): void {
        this.ctrlSelectFormControl.valueChanges.pipe(
            tap(value => {
                this.localStorageService.setSottoruoloCiclo(value);
                this.appInitService.cicloCorsoRuoloSelected$.next(this.appInitService.cicloCorsoRuoloSelected);
                if (this.currentSottoruolo === AuthorityType.COORDINATORE || this.currentSottoruolo === AuthorityType.GRUPPODICOORDINAMENTO) {
                    this.esterniRequest();
                }
            }),
        ).subscribe();
    }


    buildNomeCompleto(member: CollegioDocentiInfoView): string {
        if (member?.docenteNonTrovato) {
            return trim((member?.cognome || '') + ' ' + (member?.nome || ''));
        } else {
            return trim((member?.user?.cognome || '') + ' ' + (member?.user?.nome || ''));
        }
    }

    buildNomeCompletoRuoloUtente(member: RuoloUtenteCorsoDiStudiInfoView): string {
        return trim((member?.user?.cognome || '') + ' ' + (member?.user?.nome || ''));
    }

    buildNomeCompletoEsterno(member: Esterno): string {
        return trim((member?.cognome || '') + ' ' + (member?.nome || ''));
    }

    showModifyCoordinators(): boolean {
        return (this.currentRuolo === AuthorityType.ADMIN || this.currentRuolo === AuthorityType.SUPERADMIN || this.currentSottoruolo === AuthorityType.COORDINATORE);
    }

    showModifyCommissioneDiAutovalutazione(): boolean {
        return (this.currentRuolo === AuthorityType.ADMIN || this.currentRuolo === AuthorityType.SUPERADMIN || this.currentSottoruolo === AuthorityType.COORDINATORE);
    }


    showModifyGruppoPTA(): boolean {
        return (this.currentRuolo === AuthorityType.ADMIN || this.currentRuolo === AuthorityType.SUPERADMIN || this.currentSottoruolo === AuthorityType.COORDINATORE);
    }

    showModifyCollegioDocenti(): boolean {
        return (this.currentRuolo === AuthorityType.ADMIN || this.currentRuolo === AuthorityType.SUPERADMIN || this.currentSottoruolo === AuthorityType.COORDINATORE);
    }

    showModifyCoordinatorCollegioDocenti(): boolean {
        return (this.currentRuolo === AuthorityType.ADMIN || this.currentRuolo === AuthorityType.SUPERADMIN);
    }

    get currentSottoruolo(): AuthorityType {
        return this.ctrlSelectFormControl?.value;
    }


    confirmModifyCoordinators(): void {
        const coordinatoriTable: Array<TableDataRows> = this.listaCoordinatori.map(coordinator => (
            {
                'key': coordinator.user?.codiceFiscale,
                'data': {
                    codiceFiscale: coordinator.user?.codiceFiscale,
                    nomeCognome: (coordinator?.user?.cognome ? coordinator?.user?.cognome : '') + ' ' + (coordinator?.user?.nome ? coordinator?.user?.nome : ''),
                }
            }));

        const data: DialogTableMultiSelectConfiguration = {
            title: 'cycle_doctorate.coordinators',
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.sendCoordinatoors(tableData, dialogRef, loading$)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'cycle_doctorate.coordinators',
            },
            righeSelezionate: this.sortRigheSelezionate(coordinatoriTable),
            isSingleChoiceSelection: false,
            //showSelectToSetDataTableByRole: true,
            rolesToFetch: [RuoloEnum.DOCENTE, RuoloEnum.PERSONALEPTA],
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }


    confirmModifyCommissioneDiAutovalutazione(commAutovalSubTabData: CommAutovalSubTabData): void {
        const membriCommAutovalOfSubgroup = this.membriCommissioneDiAutovalutazione.filter(member =>
            member.tipoMembro === commAutovalSubTabData.tipoMembro);
        const tableDataRows: Array<TableDataRows> = membriCommAutovalOfSubgroup.map(commaut => (
            {
                'key': commaut.user?.codiceFiscale,
                'data': {
                    codiceFiscale: commaut.user?.codiceFiscale,
                    nomeCognome: (commaut?.user?.cognome ? commaut?.user?.cognome : '') + ' ' + (commaut?.user?.nome ? commaut?.user?.nome : ''),
                }
            }));
        const listaDaVisualizzare = unionBy([
            ...membriCommAutovalOfSubgroup
                .map(value => value.user),
            ...this.listaMembers
                .filter(d => !d.docenteNonTrovato)
                .map(value => value.user || {
                    nome: value.nome,
                    cognome: value.cognome,
                    codiceFiscale: value.codiceFiscale
                })], 'codiceFiscale');
        const data: DialogEditCommissioneDiAutovalutazioneComponentI = {
            title: 'cycle_doctorate.self_assessment_commission_' + commAutovalSubTabData.historyLabel,
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.sendCommissioneAutovalutazione(tableData, dialogRef, loading$, commAutovalSubTabData.tipoMembro)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'cycle_doctorate.coordinators',
            },
            righeSelezionate: this.sortRigheSelezionate(tableDataRows),
            isSingleChoiceSelection: false,
            listaDaVisualizzare: commAutovalSubTabData.tipoMembro === TipoMembroCommissioneDiAutovalutazione.COLLEGIODOCENTI ? listaDaVisualizzare : undefined,
            fetchDottorandi: commAutovalSubTabData.tipoMembro === TipoMembroCommissioneDiAutovalutazione.DOTTORANDO
        };
        this.dialog.open(DialogEditCommissioneDiAutovalutazioneComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }

    confirmModifyGruppoPTA(): void {
        const ptaTable: Array<TableDataRows> = !isEmpty(this.listaPTA) ? this.listaPTA.map(pta => (
            {
                'key': pta.user?.codiceFiscale,
                'data': {
                    codiceFiscale: pta.user?.codiceFiscale,
                    nomeCognome: (pta?.user?.cognome ? pta?.user?.cognome : '') + ' ' + (pta?.user?.nome ? pta?.user?.nome : ''),
                }
            })) : undefined;

        const data: DialogTableMultiSelectConfiguration = {
            title: 'cycle_doctorate.PTA_group',
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.sendPTAGroup(tableData, dialogRef, loading$)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'cycle_doctorate.PTA_group',
            },
            righeSelezionate: this.sortRigheSelezionate(ptaTable),
            rolesToFetch: [RuoloEnum.PERSONALEPTA]
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }

    confirmModifyCoordinatorCollegioDocenti(): void {
        const coordinatoreTable: Array<TableDataRows> = this.coordinator ?
            [{
                'key': !!this.coordinator?.user?.codiceFiscale ? this.coordinator?.user?.codiceFiscale : this.coordinator?.codiceFiscale,
                'data': {
                    codiceFiscale: !!this.coordinator?.user?.codiceFiscale ? this.coordinator?.user?.codiceFiscale : this.coordinator?.codiceFiscale,
                    nomeCognome: (this.coordinator?.user?.cognome ? this.coordinator?.user?.cognome : this.coordinator?.cognome ? this.coordinator?.cognome : '') + ' ' + (this.coordinator?.user?.nome ? this.coordinator?.user?.nome : this.coordinator?.nome ? this.coordinator?.nome : ''),
                }
            }] : undefined;

        const data: DialogTableMultiSelectConfiguration = {
            title: 'cycle_doctorate.coordinator',
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.sendCoordinatoorCollegioDocenti(tableData, dialogRef, loading$)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'cycle_doctorate.coordinator'
            },
            righeSelezionate: this.sortRigheSelezionate(coordinatoreTable),
            isSingleChoiceSelection: true,
            infoMessage: 'cycle_doctorate.update_coordinator_message',
            showInvitaEsterni: false,
            showAggiungiDocenteManualmente: false,
            rolesToFetch: [RuoloEnum.DOCENTE]
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });

    }


    sendCommissioneAutovalutazione(tableData: TableData, dialogRef: MatDialogRef<DialogEditCommissioneDiAutovalutazioneComponent>, loading$: BehaviorSubject<boolean>, tipoAutovalSubgroup: TipoMembroCommissioneDiAutovalutazione): void {
        let membri: string[] = tableData?.selectedRows.map(row => row.key);
        // adding members from the other group
        if(tipoAutovalSubgroup === TipoMembroCommissioneDiAutovalutazione.COLLEGIODOCENTI) {
            membri.push(...this.membriCommissioneDiAutovalutazione
                ?.filter(m => m.tipoMembro === TipoMembroCommissioneDiAutovalutazione.DOTTORANDO)
                ?.map(m => m.user?.codiceFiscale));
        } else {
            membri.push(...this.membriCommissioneDiAutovalutazione
                ?.filter(m => m.tipoMembro === TipoMembroCommissioneDiAutovalutazione.COLLEGIODOCENTI)
                ?.map(m => m.user?.codiceFiscale));
        }
        const body: UpdateCommissioneDiAutovalutazioneDTO = {
            membriCommissioneDiAutovalutazione: membri,
            timestamp: this.gruppoCommissioneDataInizio
        };
        loading$.next(true);
        this.cicliCorsiDiStudiService.updateCommissioneDiAutovalutazione(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
                next: (value: UpdateCommissioneDiAutovalutazioneResponseDTO) => {
                    this.membriCommissioneDiAutovalutazione = value?.membriCommissioneDiAutovalutazione;
                    this.storiciCommessioneDiAutovalutazione = value?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.COMMISSIONEDIAUTOVALUTAZIONE);
                    this.gruppoCommissioneDataInizio = this.storiciCommessioneDiAutovalutazione?.find(s => !s.dataFine)?.dataInizio;
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close();
                },
                error: (err) => {
                    if (err?.status == 400 && err?.error?.code == 1033) {
                        openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                    } else {
                        this.showSnackbarErrorMessage(err);
                    }
                }
            }
        );

    }
    sendCoordinatoors(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>, loading$: BehaviorSubject<boolean>): void {
        const membriGruppoDiCoordinamento: string[] = tableData?.selectedRows.map(row => row.key);
        const body: UpdateGruppoDiCoordinamentoDTO = {
            membriGruppoDiCoordinamento: membriGruppoDiCoordinamento,
            timestamp: this.gruppoDiCoordinamentoTimestamp
        };
        loading$.next(true);
        this.cicliCorsiDiStudiService.updateGruppoDiCoordinamento(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
                next: (value: UpdateGruppoDiCoordinamentoResponseDTO) => {
                    this.listaCoordinatori = value?.membriGruppoDiCoordinamento;
                    this.storiciGruppoCoordinamento = value?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.GRUPPODICOORDINAMENTO);
                    this.gruppoDiCoordinamentoTimestamp = this.storiciGruppoCoordinamento?.find(s => !s.dataFine)?.dataInizio;
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close();
                },
                error: (err) => {
                    if (err?.status == 400 && err?.error?.code == 1033) {
                        openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                    } else {
                        this.showSnackbarErrorMessage(err);
                    }
                }
            }
        );

    }


    sendPTAGroup(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>, loading$: BehaviorSubject<boolean>): void {
        const membriGruppoPTA: string[] = tableData?.selectedRows.map(row => row.key);
        const body: UpdateGruppoPTADTO = {membriGruppoPTA: membriGruppoPTA, timestamp: this.gruppoPTATimestamp};
        loading$.next(true);
        this.cicliCorsiDiStudiService.updateGruppoPTA(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
                next: (value: UpdateGruppoPTAResponseDTO) => {
                    this.listaPTA = value?.membriGruppoPTA;
                    this.storiciPTA = value?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.GRUPPOPTA);
                    this.gruppoPTATimestamp = this.storiciPTA?.find(s => !s.dataFine)?.dataInizio;
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close();
                },
                error: (err) => {
                    if (err?.status == 400 && err?.error?.code == 1033) {
                        openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                    } else {
                        this.showSnackbarErrorMessage(err);
                    }
                }
            }
        );

    }


    sendCoordinatoorCollegioDocenti(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>, loading$: BehaviorSubject<boolean>): void {
        const selectedCoordinator = tableData?.addedRows.map(row => row.data);
        loading$.next(true);
        const body: UpdateCoordinatoreCollegioDocentiRequestDTO = {
            timestamp: this.coordinatoreTimestamp,
            codice_fiscale: head(selectedCoordinator)?.codiceFiscale,
            tipoPersonale: CollegioDocentiTipoPersonale.PERSONALEACCADEMICO
        }
        this.cicliCorsiDiStudiService.updateCoordinatoreCollegioDocenti(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {

                loading$.next(false);
            })
        ).subscribe({
                next: (value: UpdateCoordinatoreCollegioDocentiResponseDTO) => {
                    this.coordinator = value?.coordinatoreCollegioDocenti;
                    this.storiciCoordinatori = value?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.COORDINATORE);
                    this.getInfoRequest();
                    this.completeNameCoordinator = (this.coordinator?.user?.cognome).toUpperCase() + ' ' + (this.coordinator?.user?.nome).toUpperCase();
                    this.coordinatoreTimestamp = this.storiciCoordinatori?.find(s => !s.dataFine)?.dataInizio;
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close();
                },
                error: (err) => {
                    if (err?.status == 400 && err?.error?.code == 1033) {
                        openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                    } else {
                        this.showSnackbarErrorMessage(err);
                    }
                }
            }
        );

    }

    sendCollegioDocentiMembers(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>,
                               loading$: BehaviorSubject<boolean>, tipoCollegio: CollegioDocentiTipoPersonale, customRows: any[]): void {
        const membriCollegioDocentiCensiti: MembroCollegioDocentiDTO[] = tableData?.selectedRows.map(row => ({
            codiceFiscale: row.key,
        }))?.filter(m =>
            m.codiceFiscale !== this.coordinator?.codiceFiscale
        ) || [];
        const membriCollegioDocentiNonCensiti: MembroCollegioDocentiDTO[] = customRows?.map(
            row => row.data
        ) || [];
        const body: UpdateMembriCollegioDocentiDTO = {
            membriCollegioDocenti: [...membriCollegioDocentiCensiti, ...membriCollegioDocentiNonCensiti],
            tipoPersonale: tipoCollegio,
            timestamp: this.collegioDocentiTimestamp
        };
        loading$.next(true);
        this.cicliCorsiDiStudiService.updateMembriCollegioDocenti(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
                next: (value: UpdateMembriCollegioDocentiResponseDTO) => {
                    this.listaMembers = value?.membriCollegioDocenti;
                    this.storiciCollegio = value?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.COLLEGIODOCENTI);
                    this.collegioDocentiTimestamp = this.storiciCollegio?.find(s => !s.dataFine)?.dataInizio;
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close();
                },
                error: (err) => {
                    if (err?.status == 400 && err?.error?.code == 1033) {
                        openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                    } else {
                        this.showSnackbarErrorMessage(err);
                    }
                }
            }
        );

    }

    sendAdminGroup(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>, loading$: BehaviorSubject<boolean>): void {
        const userAdminRoleUpdateDTOList: UserAdminRoleUpdateDTO[] = tableData?.selectedRows.map(
            (row: TableDataRows) => ({
                codiceFiscale: row?.key,
                toAssign: true
            }));
        (tableData?.removedRows.forEach(
            (row: TableDataRows) => (
                userAdminRoleUpdateDTOList.push({codiceFiscale: row?.key, toAssign: false})
            )
        ));
        const body: UserAdminRoleUpdateDTORequest = {
            userAdminRoleUpdateDTOList: userAdminRoleUpdateDTOList,
            timestamp: this.adminsTimestamp
        };
        loading$.next(true);
        this.usersService.userAdminRoleUpdate(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
            next: (value: CicloCorsoDiStudiInfoDTO) => {
                this.listaAdmin = value?.cicloCorsoDiStudiRuoliAdmin.map((adminRuolo: RuoloUtenteCorsoDiStudiInfoView) => ({
                    ruolo: AuthorityType.ADMIN,
                    user: adminRuolo?.user
                }));
                this.adminsTimestamp = value?.cicloCorsoDiStudiInfoView?.timestampUltimaModificaAdmin;
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
                dialogRef.close();
            },
            error: (err) => {
                if (err?.status == 400 && err?.error?.code == 1033) {
                    openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                } else {
                    this.showSnackbarErrorMessage(err);
                }
            }
        });

    }

    showSnackbarErrorMessage(error?: any): void {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.openSnackBar({
            message: get(translation, 'error.generic_error', null),
            type: SnackbarTypes.Error,
            error: error
        });
    }


    showModifyAdmins(): boolean {
        return this.currentRuolo === AuthorityType.SUPERADMIN;
    }

    confirmModifyAdmins(): void {
        const utentiAdminTable: Array<TableDataRows> = this.listaAdmin.map(utenteAdmin => (
            {
                'key': utenteAdmin.user?.codiceFiscale,
                'data': {
                    codiceFiscale: utenteAdmin.user?.codiceFiscale,
                    nomeCognome: (utenteAdmin?.user?.cognome ? utenteAdmin?.user?.cognome : '') + ' ' + (utenteAdmin?.user?.nome ? utenteAdmin?.user?.nome : ''),
                }
            }));

        const data: DialogTableMultiSelectConfiguration = {
            title: 'cycle_doctorate.admins',
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.sendAdminGroup(tableData, dialogRef, loading$)

                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'cycle_doctorate.admins',
            },
            righeSelezionate: this.sortRigheSelezionate(utentiAdminTable),
            isSingleChoiceSelection: false,
            rolesToFetch: [RuoloEnum.DOCENTE]
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }

    openInfoMember(member: CollegioDocentiInfoView | RuoloUtenteCorsoDiStudiInfoView): void {
        let userResponse: UserRoleBasedInfoView;
        let imageResponse: SafeUrl;
        if (member?.user?.codiceFiscale) {
            this.fuseConfirmationService.showLoader();
            this.usersService.getUserInfoRoleBased(member.user.codiceFiscale).pipe(
                tap((value: UserRoleBasedInfoView) => userResponse = value),
                switchMap((user: UserRoleBasedInfoView) => {
                        if (!!user.urlImmagineProfiloThumb && !!user.codiceFiscale) {
                            return this.mediaService.getImmagineDocenteOrPtaForm(user.urlImmagineProfiloThumb, user.codiceFiscale).pipe(
                                tap((image) => {
                                    const objectURL = URL.createObjectURL(image);
                                    imageResponse = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                                })
                            );
                        } else {
                            return of(user);
                        }
                    }
                ),
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: () => {
                    const data: DialogInfoI = this.buildDataForDIalog(userResponse, imageResponse);
                    this.dialog.open(DialogInfoComponent, {
                        data,
                        panelClass: 'dialog-responsive-full-screen',
                    });
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this._translocoService,
                        () => {
                        },
                        () => this.openInfoMember(member),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        }
    }

    openInfoEsterno(esterno: Esterno): void {
        let userResponse: UserRoleBasedInfoView;
        let imageResponse: SafeUrl;
        const codiceFiscaleOrEmail = esterno?.codiceFiscale || esterno.mail;
        if (!!codiceFiscaleOrEmail) {
            this.fuseConfirmationService.showLoader();
            this.usersService.getUserInfoRoleBased(codiceFiscaleOrEmail).pipe(
                tap((value: UserRoleBasedInfoView) => userResponse = value),
                switchMap((user: UserRoleBasedInfoView) => {
                        if (!!user.urlImmagineProfiloThumb && !!user?.codiceFiscale) {
                            return this.mediaService.getImmagineDocenteOrPtaForm(user.urlImmagineProfiloThumb, user.codiceFiscale).pipe(
                                tap((image) => {
                                    const objectURL = URL.createObjectURL(image);
                                    imageResponse = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                                })
                            );
                        } else {
                            return of(user);
                        }
                    }
                ),
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: () => {
                    const data: DialogInfoI = this.buildDataForDIalog(userResponse, imageResponse);
                    this.dialog.open(DialogInfoComponent, {
                        data,
                        panelClass: 'dialog-responsive-full-screen',
                    });
                },
                error: (error) => {
                    this.fuseConfirmationService.openErrorDialog({error: error},
                        this._translocoService,
                        () => {
                        },
                        () => this.openInfoEsterno(esterno),
                        'dialog.close',
                        error?.error?.message);
                }
            });
        }
    }

    buildDataForDIalog(user?: UserRoleBasedInfoView, image?: SafeUrl): DialogInfoI {
        return {
            imageSafeUrl: image,
            showImage: true,
            title: (user.cognome || '') + ' ' + (user.nome || ''),
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            ...docente(user)
        };
    }

    sortList(list): any[] {
        return sortBy(list, [function (o) {
            return o.cognome ? o.cognome : o.user?.cognome;
        }]);
    }

    sortRigheSelezionate(list: TableDataRows[]): TableDataRows[] {
        return sortBy(list, [function (o) {
            return o.data?.nomeCognome;
        }]);
    }


    confirmModifyCoollegioDocenti(collegioDocentiSubTabData: CollegioDocentiSubTabData): void {
        const coordinatore = this.listaMembers.filter(member => member?.ruolo === CollegioDocentiRuolo.COORDINATORE);
        const membriCollegioOfSubgroup = this.listaMembers?.filter(m =>
            (m.tipoPersonale === collegioDocentiSubTabData.tipoCollegio) || (collegioDocentiSubTabData.tipoCollegio === CollegioDocentiTipoPersonale.PERSONALEACCADEMICO && !m.tipoPersonale));
        const collegioDocentiCensitiTable: Array<TableDataRows> = membriCollegioOfSubgroup
            ?.filter(docente => !docente.docenteNonTrovato)
            ?.map(docente => (
            {
                'key': docente?.user?.codiceFiscale ? docente?.user?.codiceFiscale : docente?.codiceFiscale,
                'data': {
                    codiceFiscale: docente?.user?.codiceFiscale ? docente?.user?.codiceFiscale : docente?.codiceFiscale,
                    nomeCognome: (docente?.user?.cognome ? docente?.user?.cognome : docente?.cognome ? docente?.cognome : '') + ' ' + (docente?.user?.nome ? docente?.user?.nome : docente?.nome ? docente?.nome : ''),
                    id: docente?.user?.codiceFiscale ? docente?.user?.codiceFiscale : docente?.codiceFiscale,
                }
            }));
        const collegioDocentiNonCensitiTable: Array<TableDataRows> = membriCollegioOfSubgroup
            ?.filter(docente => docente.docenteNonTrovato)
            ?.map((docente, index) => ({
                'key': docente.nome + '_' + docente.cognome + '_' + index,
                'data': {
                    ...docente,
                    id: docente.nome + '_' + docente.cognome + '_' + index,
                    nomeCognome: (docente.cognome || '') + ' ' + (docente.nome || '')
                }
            }));
        const docentiFromOtherCollegioSubgroups = this.listaMembers
            ?.filter(m => m.tipoPersonale !== collegioDocentiSubTabData.tipoCollegio)
            ?.map(m => m?.user?.codiceFiscale) || [];
        const data: DialogTableMultiSelectConfiguration = {
            title: 'cycle_doctorate.college_college_member_' + collegioDocentiSubTabData.historyLabel,
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$, customRows) =>
                        this.sendCollegioDocentiMembers(tableData, dialogRef, loading$, collegioDocentiSubTabData.tipoCollegio, customRows)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'cycle_doctorate.college_member',
            },
            righeSelezionate: this.sortRigheSelezionate(collegioDocentiCensitiTable),
            idRigheSolaLettura: [head(coordinatore)?.user?.codiceFiscale, ...docentiFromOtherCollegioSubgroups],
            showInfoRigheSolaLettura: {iconName: 'star', tooltip: 'cycle_doctorate.coordinator'},
            isSingleChoiceSelection: false,
            righeConConfermaDiRimozioneParent: this.membriCommissioneDiAutovalutazione?.map(v =>  ({key: v.user?.codiceFiscale})),
            messageConfirmRemove: 'cycle_doctorate.message_confirm_remove_table',
            rolesToFetch: [RuoloEnum.DOCENTE, RuoloEnum.ESTERNO],
            righeSelezionateCustom: collegioDocentiNonCensitiTable,
            showInvitaEsterni: true,
            showAggiungiDocenteManualmente: true,
            codiceFiscaleCoordinatore: head(coordinatore)?.user?.codiceFiscale,
            showRigheSolaLetturaBecauseAlreadyInCollegio: true,
            showRigheSolaLetturaBecauseCoordinatore: true
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }

    openHistoryDialog(storico: Array<StoricoMembriStudenteInfoView | StoricoMembriCorsoDiStudiInfoView>, isAccordion: boolean, subject: string, tipoMembriSottogruppi?: string) {
        let storiciFiltered: Array<StoricoMembriStudenteInfoView | StoricoMembriCorsoDiStudiInfoView> = storico;
        if(tipoMembriSottogruppi){
            storiciFiltered = cloneDeep(storico as StoricoMembriCorsoDiStudiInfoView[])
                ?.map(storicoFiltered => ({
                    ...storicoFiltered,
                    membri: storicoFiltered.membri.filter(m =>
                        (m.tipoPersonale === tipoMembriSottogruppi)
                        || (m.tipoMembroCommissioneDiAutovalutazione === tipoMembriSottogruppi)
                        || (tipoMembriSottogruppi === CollegioDocentiTipoPersonale.PERSONALEACCADEMICO && !m.tipoPersonale)
                    )
                }));
        }
        const data: DialogStoricoI = {
            membriStorici: storiciFiltered,
            isAccordion: isAccordion,
            title: 'cycle_doctorate.history_' + subject,
            currentRuolo: this.currentRuolo,
            icon: {
                name: 'mat_outline:history',
                color: 'info'
            },
        };
        this.dialog.open(DialogStoricoComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false,
        });
    }

    protected readonly CycleInfoFragments = CycleInfoFragments;

    showHistoryShowButton(): boolean {
        return ((this.coordinator && this.coordinator?.user) ? this.coordinator?.user?.id === this.userID : false)
            || !!this.listaCoordinatori?.find(c => c.user?.id === this.userID)
            || !!this.listaMembers?.find(c => c.user?.id === this.userID)
            || !!this.listaPTA?.find(c => c.user?.id === this.userID)
            || !!this.listaDirettoriDipartimento?.find(c => c.user?.id === this.userID)
            || !!this.listaSAD?.find(c => c.user?.id === this.userID)
            || this.currentRuolo === AuthorityType.SUPERADMIN
            || this.currentSottoruolo === AuthorityType.ADMIN;
    }

    private esterniRequest() {
        this.fuseConfirmationService.showLoader();
        const selectedInService$ = this.appInitService.isAreaAdministrator ? this.appInitService.isCicloAmmSelectedInService : this.appInitService.isDipartimentoRuoloCicloSelectedInService;

        selectedInService$.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.usersService.getEsterniCiclo()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (esterniResp: Esterno[]) => {
                // esterni
                this.listaEsterni = esterniResp;
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this._translocoService,
                    () => this.logOutService.goToHome(),
                    () => this.esterniRequest(),
                    err?.error?.message);
            }
        });
    }

    setCurrentCorso(codiceCorsoStudiAmm: string): void {
        this.appInitService.setCodiceCorsoStudiAmm(codiceCorsoStudiAmm)
        this.getInfoRequest();
    }

    openModififySADDialog() {
        const sadTable: Array<TableDataRows> = this.listaSAD.map(sad => (
            {
                'key': sad?.user?.codiceFiscale,
                'data': {
                    codiceFiscale: sad?.user?.codiceFiscale,
                    nomeCognome: (sad?.user?.cognome ? sad?.user?.cognome : '') + ' ' + (sad?.user?.nome ? sad?.user?.nome : ''),
                    id: sad?.user?.codiceFiscale,
                }
            }));
        const data: DialogTableMultiSelectConfiguration = {
            title: 'cycle_doctorate.department_administrative_secretary',
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.editSADRequest(tableData, dialogRef, loading$)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'cycle_doctorate.department_administrative_secretary',
            },
            righeSelezionate: this.sortRigheSelezionate(sadTable),
            isSingleChoiceSelection: true,
            rolesToFetch: [RuoloEnum.PERSONALEPTA]
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }

    showModifySAD() {
        return this.currentRuolo === AuthorityType.SUPERADMIN || this.currentRuolo === AuthorityType.ADMIN;
    }

    openModififyDirettoreDipartimentoDialog() {
        const dirTable: Array<TableDataRows> = this.listaDirettoriDipartimento.map(dir => (
            {
                'key': dir?.user?.codiceFiscale,
                'data': {
                    codiceFiscale: dir?.user?.codiceFiscale,
                    nomeCognome: (dir?.user?.cognome ? dir?.user?.cognome : '') + ' ' + (dir?.user?.nome ? dir?.user?.nome : ''),
                    id: dir?.user?.codiceFiscale,
                }
            }));
        const data: DialogTableMultiSelectConfiguration = {
            title: 'cycle_doctorate.department_director',
            icon: {
                name: 'mat_outline:assignment',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.editDirettoreDipartimentoRequest(tableData, dialogRef, loading$)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'cycle_doctorate.department_director',
            },
            righeSelezionate: this.sortRigheSelezionate(dirTable),
            isSingleChoiceSelection: true,
            rolesToFetch: [RuoloEnum.DOCENTE]
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }

    showModifyDirettoreDipartimento() {
        return this.currentRuolo === AuthorityType.SUPERADMIN || this.currentRuolo === AuthorityType.ADMIN;
    }


    private editSADRequest(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>, loading$: BehaviorSubject<boolean>) {
        const sad: string[] = tableData?.selectedRows.map(row => row.key);
        const body: UpdateSegretariAmministrativiDiDipartimentoDTO = {
            membriSegretarioAmministrativoDiDipartimento: sad,
            timestamp: this.SADTimestamp
        };
        loading$.next(true);
        this.cicliCorsiDiStudiService.updateSegretariAmministrativiDiDipartimento(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
                next: (value: UpdateSegretariAmministrativiDiDipartimentoResponseDTO) => {
                    this.listaSAD = value?.membriSegretariAmministrativiDiDipartimento?.sort();
                    this.storiciSAD = value?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO);
                    this.SADTimestamp = this.storiciSAD?.find(s => !s.dataFine)?.dataInizio;
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close();
                },
                error: (err) => {
                    if (err?.status == 400 && err?.error?.code == 1033) {
                        openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                    } else {
                        this.showSnackbarErrorMessage(err);
                    }
                }
            }
        );
    }

    private editDirettoreDipartimentoRequest(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>, loading$: BehaviorSubject<boolean>) {
        const direttoriDipartimento: string[] = tableData?.selectedRows.map(row => row.key);
        const body: UpdateDirettoriDiDipartimentoDTO = {
            membriDirettoriDiDipartimento: direttoriDipartimento,
            timestamp: this.direttoriDipartimentoTimestamp
        };
        loading$.next(true);
        this.cicliCorsiDiStudiService.updateDirettoriDiDipartimento(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
                next: (value: UpdateDirettoriDiDipartimentoResponseDTO) => {
                    this.listaDirettoriDipartimento = value?.membriDirettoriDiDipartimento?.sort();
                    this.storiciDirettoriDipartimento = value?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.DIRETTOREDIDIPARTIMENTO);
                    this.direttoriDipartimentoTimestamp = this.storiciDirettoriDipartimento?.find(s => !s.dataFine)?.dataInizio;
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close();
                },
                error: (err) => {
                    if (err?.status == 400 && err?.error?.code == 1033) {
                        openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                    } else {
                        this.showSnackbarErrorMessage(err);
                    }
                }
            }
        );
    }

    replaceNanuallyAddedTeacherInCollegio(docente: CollegioDocentiInfoView) {
        const rigaSolaLettura = this.listaMembers.filter(member => member?.ruolo === CollegioDocentiRuolo.COORDINATORE);
        const docentiInCollegioSubgroups = this.listaMembers
            ?.map(m => (m?.user?.codiceFiscale)) || [];
        const data: DialogTableMultiSelectConfiguration = {
            title: 'training_offer.replace_manually_added_teacher',
            icon: {
                name: 'mat_outline:person_add',
                color: 'primary'
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'dialog.confirm',
                    color: 'primary',
                    function: (tableData, dialogRef, loading$) => this.replaceManuallyAddedTeacherInCollegioRequest(tableData, dialogRef, docente, loading$)
                },
                cancel: {
                    show: true,
                    label: 'dialog.close',
                }
            },
            dismissible: true,
            table: {
                title: 'common.professor'
            },
            idRigheSolaLettura: [head(rigaSolaLettura)?.user?.codiceFiscale, ...docentiInCollegioSubgroups],
            showInfoRigheSolaLettura: {iconName: 'star', tooltip: 'cycle_doctorate.coordinator'},
            isSingleChoiceSelection: true,
            righeConConfermaDiRimozioneParent: this.membriCommissioneDiAutovalutazione?.map(v =>  ({key: v.user?.codiceFiscale})),
            messageConfirmRemove: 'cycle_doctorate.message_confirm_remove_table',
            rolesToFetch: [RuoloEnum.DOCENTE, RuoloEnum.ESTERNO],
            showInvitaEsterni: true,
            righeSelezionate: undefined
        };
        this.dialog.open(DialogSelectDocentiComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen-docenti',
            hasBackdrop: data.dismissible,
            autoFocus: false,
            minWidth: '95vw'
        });
    }

    private replaceManuallyAddedTeacherInCollegioRequest(tableData: TableData, dialogRef: MatDialogRef<DialogTableMultiSelectComponent>, docenteToReplace: CollegioDocentiInfoView, loading$: BehaviorSubject<boolean>) {
        const membriCollegioDocentiUpdated = this.listaMembers
            ?.filter(m => m.tipoPersonale === docenteToReplace.tipoPersonale)
            ?.filter(m => !m.docenteNonTrovato || (m.nome !== docenteToReplace.nome || m.cognome !== docenteToReplace.cognome))
            ?.map(m => ({
                codiceFiscale: m?.user?.codiceFiscale,
                nome: m.nome,
                cognome: m.cognome,
            }));
        const docenteToAdd = tableData?.selectedRows.map(row => ({
            codiceFiscale: row.key
        }));
        const body: UpdateMembriCollegioDocentiDTO = {
            membriCollegioDocenti: [...membriCollegioDocentiUpdated, ...docenteToAdd],
            tipoPersonale: docenteToReplace.tipoPersonale,
            timestamp: this.collegioDocentiTimestamp
        };
        loading$.next(true);
        this.cicliCorsiDiStudiService.updateMembriCollegioDocenti(body).pipe(
            takeUntil(this.destroy$),
            finalize(() => {
                loading$.next(false);
            })
        ).subscribe({
                next: (value: UpdateMembriCollegioDocentiResponseDTO) => {
                    this.listaMembers = value?.membriCollegioDocenti;
                    this.storiciCollegio = value?.storiciMembriCorsoDiStudi?.filter(storico => storico.tipo === TipoMembroCorsoDiStudi.COLLEGIODOCENTI);
                    this.collegioDocentiTimestamp = this.storiciCollegio?.find(s => !s.dataFine)?.dataInizio;
                    const activeLang = this._translocoService.getActiveLang();
                    const translation = this._translocoService.getTranslation().get(activeLang);
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'common.operation_success', null),
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close();
                },
                error: (err) => {
                    if (err?.status == 400 && err?.error?.code == 1033) {
                        openMustRefreshDialog(this.fuseConfirmationService, this._translocoService);
                    } else {
                        this.showSnackbarErrorMessage(err);
                    }
                }
            }
        );

    }


}

export const docente = (user: UserRoleBasedInfoView) => ({
    subTextImg: user?.docente?.afferenzaOrganizzativa,
    imageIniziali: (user?.cognome?.charAt(0) || '') + (user?.nome.charAt(0) || ''),
    readonlyData: [
        {
            key: 'user_list.surname',
            value: user?.cognome,
            pipe: UpperCasePipe
        },
        {
            key: 'user_list.name',
            value: user?.nome,
            pipe: UpperCasePipe
        },
        { //mail
            key: 'sign-up.email',
            value: user?.mail?.toLowerCase(),
        },
/*        { //telefono
            key: 'sign-up.phone_number',
            value: user?.telefono
        },*/
        { //cf
            key: 'sign-up.fiscal_code',
            value: user?.codiceFiscale
        },
        { //matricola
            key: 'students_list.enrollment_number',
            value: user?.docente?.matricola,
            hide: !user?.docente?.matricola
        },
        { //ssd
            key: 'cycle_doctorate.ssd',
            value: user?.docente?.ssd,
            hide: !user?.docente?.ssd
        },
    ]
});

export enum CycleInfoFragments {
    COLLEGIODOCENTI = "collegio-docenti",
    GRUPPOCOORDINAMENTO = "gruppo-coordinamento",
    GRUPPOPTA = "gruppo-PTA",
    ADMINS = "admin",
    EXTERN = "esterni",
    COMMISSIONE_DI_AUTOVALUTAZIONE = 'commissione-di-autovalutazione',
    DIRETTOREDIPERATIMENTO = 'direttore-di-dipartimento',
    SAD = 'segretario-amministrativo-dipartimento',
}
