import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";
import {AngularEditorComponent, AngularEditorConfig} from "@kolkov/angular-editor";
import {CustomValidators} from "../../validators/custom-validators";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";

export interface DialoEditGuidaCoordinatoreI extends DialogWrapI {
    onEditPressed: (form: FormGroup, dialogRef: MatDialogRef<any>) => void,
    currentGuidaCoordinatore?: string,
    isRequired: boolean
}

@Component({
    selector: 'app-dialog-edit-guida-coordinatore',
    templateUrl: './dialog-edit-guida-coordinatore.component.html',
    styleUrls: ['./dialog-edit-guida-coordinatore.component.scss']
})
export class DialogEditGuidaCoordinatoreComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit {

    form: FormGroup;
    loading: boolean = false;
    editorConfig: AngularEditorConfig;
    @ViewChild(AngularEditorComponent) private guidaCoordinatoreEditor: AngularEditorComponent;
    formSubmitted: boolean;
    formNotValidMessage: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialoEditGuidaCoordinatoreI,
                public dialogRef: MatDialogRef<DialogEditGuidaCoordinatoreComponent>,
                private fb: FormBuilder,
                private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,) {
        super();
        this.translocoService.selectTranslate('form.not_valid').subscribe(value => this.formNotValidMessage = value);
        this.editorConfig = {
            editable: true,
            spellcheck: true,
            height: '250px',
            minHeight: '0',
            maxHeight: 'auto',
            width: 'auto',
            minWidth: '0',
            translate: 'yes',
            enableToolbar: true,
            showToolbar: true,
            placeholder: '',//this.testoMessaggioPlaceholder,
            defaultParagraphSeparator: '',
            defaultFontName: '',
            defaultFontSize: '',
            fonts: [
                {class: 'arial', name: 'Arial'},
            ],
            customClasses: [],
            uploadWithCredentials: false,
            sanitize: true,
            toolbarPosition: 'top',
            toolbarHiddenButtons: [
                [
                    'undo',
                    'redo',
                    'strikeThrough',
                    'subscript',
                    'superscript',
                    // 'justifyLeft',
                    // 'justifyCenter',
                    // 'justifyRight',
                    // 'justifyFull',
                    'indent',
                    'outdent',
                    // 'insertUnorderedList',
                    // 'insertOrderedList',
                    'heading',
                    'fontName'
                ],
                [
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'customClasses',
                    // 'link',
                    // 'unlink',
                    'insertImage',
                    'insertVideo',
                    'insertHorizontalRule',
                    'removeFormat',
                    'toggleEditorMode'
                ]
            ]
        };
        this.createForm();

    }

    ngOnInit(): void {
    }

    ngAfterViewInit(){
        this.guidaCoordinatoreEditor.focus();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    editPressed() {
        this.formSubmitted = true;
        if(this.form.valid) {
            this.data.onEditPressed(this.form, this.dialogRef);
        } else {
            this.fuseConfirmationService.openSnackBar({
                message: this.formNotValidMessage,
                type: SnackbarTypes.Warning,
            });
        }
    }

    private createForm() {
        this.form = this.fb.group({
            guidaCoordinatore: this.data?.currentGuidaCoordinatore,
        });
        if(this.data.isRequired) {
            this.getFormControlByName('guidaCoordinatore').addValidators([Validators.required, CustomValidators.noEmptyInnerHTMLValidator()]);
        }

    }

    getFormControlByName(formControlName: string) {
        return this.form?.get(formControlName);
    }

    protected readonly Object = Object;

    isFormValueChanged() {
        return this.form?.getRawValue()?.guidaCoordinatore !== this.data?.currentGuidaCoordinatore;
    }
}
