<div class="w-full" *transloco="let t">

    <div class="w-full flex flex-col flex-auto overflow-hidden items-center gap-4"
         *ngIf="!!curriculumList && curriculumList.length > 0; else noCurriculum">
            <!-- CURRICULUM -->
            <mat-accordion class="w-full">

                <ng-container *ngFor="let curriculum of curriculumList;">
                    <mat-expansion-panel class="w-full mb-3">
                        <mat-expansion-panel-header class="bg-white">
                            <mat-panel-title>
                                <div class="mt-2 text-sm lg:text-lg font-small line-clamp-2">
                                    <strong>{{curriculum.idPercorsoDiStudi}} - {{curriculum.descrizionePercorsoDiStudi}}</strong>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <!-- DESCRIZIONE -->
                        <div style="margin: 5px !important; padding-top: 20px;"
                             class="flex flex-col flex-auto overflow-hidden w-full justify-center items-center">
                            <div class="text-lg font-medium text-justify" style="padding: 10px"
                                 *ngIf="curriculum.descrizioneEstesaPercorsoDiStudi; else noDescription">
                                <p>
                                    {{curriculum.descrizioneEstesaPercorsoDiStudi}}
                                </p>
                            </div>
                            <ng-template #noDescription>
                                <div class="text-base font-small w-full text-left">
                                    {{t('cycle_doctorate.no_curriculum_desc')}}
                                </div>
                            </ng-template>
                        </div>
                    </mat-expansion-panel>
                </ng-container>

            </mat-accordion>
    </div>

    <ng-template #noCurriculum>
        <div class="mx-8 mt-4 text-lg font-medium">
            <fuse-alert
                [showIcon]="true"
                [appearance]="'outline'"
                [type]="'warning'"
            >{{t('cycle_doctorate.no_curriculum')}}</fuse-alert>

        </div>
    </ng-template>

</div>


