<ng-container *transloco="let t">

    <app-dialog-wrap [data]="data">

        <div class="w-full flex flex-col gap-1 mt-4">

            <span *ngFor="let error of data.errors">- {{error}}</span>

        </div>

    </app-dialog-wrap>

    <div class="w-full px-6 py-4 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <div fxLayout="row" fxLayoutAlign="end center" class="mt-0.5 mb-0.5 gap-4">
            <button [color]="'accent'" mat-raised-button type="button" (click)="closeDialog()">
                <mat-icon class="mr-2">close</mat-icon>
                {{t('common.close')}}
            </button>
        </div>
    </div>

</ng-container>

