import {showSuperamentoCorso, unifyDocentiAndExtra} from '../../study-plan-utils';
import {
    CategoriaInAggiornamentoPianoDiStudi,
    CategoriaInPianoDiStudiInfoView, DatiCorsoOffertaFormativa
} from '../../../../../../../api-clients/generated/services';
import {
    BooleanStatus, BooleanStatusNotNull,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from '../../../../../../shared/components/table/model/generic-table-model';
import {get, sortBy} from "lodash";
import {getPresumedNumbersForDraft} from "../nuova-presentazione/configurazione-corsi-nuova-presentazione";
import {Translation} from "@ngneat/transloco";

export function buildConfigurationForCoursesPlanInPianoApprovato(translation: Translation,
                                                                 category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoPianoDiStudi,
                                                                 idUserLogged?: string,
                                                                 isDocenteCorso?: boolean,
                                                                 isSupervisore?: boolean,): GenericTableConfigurationModel {
    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    const tableData = coursesPlan?.map(element => ({...(element?.datiCorsoOffertaFormativa ?? element),}));

    let myData =
        tableData?.map(item => ({
            ...item,
            cfuRatio: item.cfuOttenuti + '/' + item.cfuPrevisti,
            // unione di DocenteExtraPianoDiStudiInfoView[] e UsersView[]
            docentiTutti: unifyDocentiAndExtra(item),
            denominazione: item.denominazione ?? item.denominazioneAttivitaPresunta,
            denominazioneFormatted: (item.idAttivitaPresunta && !item.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : item.denominazione,
            idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
            isArchiviato: item.isArchiviato || item?.isAssociazioneArchiviata,
            superato: item.superato ? BooleanStatusNotNull.BOOLEAN_TRUE : BooleanStatusNotNull.BOOLEAN_FALSE,
        }));
    myData = sortBy(myData, [
        attivita => !attivita.idAttivitaProposta,
        attivita => !attivita.idAttivitaPresunta,
        attivita => attivita.denominazioneFormatted
    ], ['asc', 'asc', 'asc']);
    return {
        configuration: {
            data: myData,
            totalElements: myData?.length,
            isPaginatedBE: false,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.course_title',
                    colonnaKey: 'denominazioneFormatted',
                    flex: 40,
                    alsoShowChipIf: (activity) => {
                        if(activity.idAttivitaProposta){
                            return {
                                chipText: 'study_plan_status.proposed_activity',
                                iconName: 'school',
                                classes: 'text-blue-600'
                            };
                        } else if (activity.idAttivitaPresunta){
                            return {
                                chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                iconName: 'question_mark',
                                classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                            };
                        } else {
                            return undefined;
                        }
                    },
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'student.executed',
                    colonnaKey: 'superato',
                    statusType: BooleanStatusNotNull,
                    show: (_, activity) => !activity.idAttivitaPresunta,
                    flex: 8
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.final_check',
                    colonnaKey: 'verificaFinale',
                    flex: 10,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no',
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'cycle_doctorate.professor',
                    colonnaKey: 'docentiTutti',
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    flex: 40,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    show: (_, activity) => !activity.idAttivitaProposta && !activity.idAttivitaPresunta,
                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    tipo: TipoColonnaEnum.ACTION,
                    nomeColonna: 'common.additional_information',
                    colonnaKey: 'descrizione',
                    flex: 10,
                    button: [{
                        nameIconButton: 'info',
                        click: TipoClickEnum.SHOW,
                        tooltip: 'common.additional_information',
                        color: 'accent'
                    }]
                },
                {
                    nomeColonna: 'user_list.action',
                    colonnaKey: 'superamentoCorso',
                    flex: 10,
                    hideColonna: (!isSupervisore && !isDocenteCorso),
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    show: (value, element) => !element?.idAttivitaPresunta && showSuperamentoCorso(value, element, idUserLogged, isDocenteCorso, isSupervisore),
                    valueCheck: 'superato',
                    button: [{
                        nameIconButton: 'done',
                        click: TipoClickEnum.SUPERAMENTO_CORSO,
                        tooltip: 'common.superamento_corso',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: true,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
        }
    };
}
