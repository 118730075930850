import {Injectable} from '@angular/core';
import {LocalStorageService} from '../../../shared/service/local-storage.service';
import {GetInfoStudenteResponse, StudenteCicloInfoView, UsersService} from '../../../../api-clients/generated/services';
import {Router} from '@angular/router';
import {LogoutService} from '../../../shared/service/logout.service';
import {FuseConfirmationService} from '../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
import {SafeUrl} from "@angular/platform-browser";
import {BehaviorSubject, Observable} from "rxjs";
import {StudyPlanDraftCreationMode} from "./study-plan/tab-creation-study-plan/tab-creation-study-plan.component";


@Injectable({
    providedIn: 'root'
})
export class StudentDetailsService {

    studentId: string;
    studentDetails: StudenteCicloInfoView;
    currentStudentImage: SafeUrl;
    studentDetails$: BehaviorSubject<StudenteCicloInfoView> = new BehaviorSubject(undefined);
    studentInfo$: BehaviorSubject<GetInfoStudenteResponse> = new BehaviorSubject(undefined);
    hasAPresentedPiano: boolean;
    studyPlanDraftCreationMode: StudyPlanDraftCreationMode = StudyPlanDraftCreationMode.READ_ONLY;

    constructor() {

    }

    get currentStudentInfo(): Observable<GetInfoStudenteResponse>{
        return this.studentInfo$.asObservable();
    }

    get currentStudent(): Observable<StudenteCicloInfoView>{
        return this.studentDetails$.asObservable();
    }

    cleanServiceVariables(): void {
        this.studentId= undefined;
        this.studentDetails= undefined;
        this.currentStudentImage= undefined;
        this.studentDetails$.next(undefined);
        this.studentInfo$.next(undefined);
    }
}
