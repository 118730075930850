import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslocoService} from '@ngneat/transloco';
import {get} from 'lodash';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent implements OnInit {
    @Input() label: string;
    @Input() labelNoTranslate: string;
    @Input() form: FormControl;
    @Input() verificaId: boolean;
    @Input() idCampo: string;

    private fileSelected: File;
    @Output() uploadImgEmitter = new EventEmitter<File>();
    @Output() downloadEmitter = new EventEmitter<string>();
    @Output() deleteEmitter = new EventEmitter<string>();
    @Output() saveBeforeUpload = new EventEmitter<void>();
    @ViewChild('inputFile') inputRef: ElementRef;
    @Input() sizeMB = 15;
    // files showed in the window
    @Input() listFileAccepted = ['pdf', 'gz', 'rar', 'tar', 'gz', 'tgz', 'zip', '7z']
    // files excluded
    @Input() listFileExcluded = ['exe', 'bat', 'sh', 'com', 'wsh', 'run', 'osx']
    nameFile: string;
    acceptedFileList: string;

    constructor(private _translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService) {
        this.acceptedFileList = this.listFileAccepted.map(extension => '.' + extension).join(', ');
    }

    ngOnInit(): void {
    }


    onFileSelected(event: any) {
        if (event?.target?.files?.length === 0) {
            return;
        }
        const fileInput = event?.target?.files[0];
        const fileInputType = fileInput.name.substring(fileInput.name.lastIndexOf('.'));

        if (!this.listFileExcluded.some(file => fileInputType.includes(file))) {
            if (this.checkSizeMB(fileInput.size) > this.sizeMB) {
                const activeLang = this._translocoService.getActiveLang();
                const translation = this._translocoService.getTranslation().get(activeLang);
                this.fuseConfirmationService.open({
                    title: get(translation, 'dialog.attention', null),
                    message: get(translation, 'upload.size_exceeded_file_number', null) + ' ' + this.sizeMB + 'MB',
                    icon: {
                        name: 'mat_solid:error_outline',
                        color: 'error'
                    },
                    onBackdrop: {
                        show: true,
                        backdrop: false
                    }
                });
            } else {
                this.fileSelected = fileInput;
                this.nameFile=this.fileSelected.name;
                this.uploadImgEmitter.emit(this.fileSelected);
            }
        } else {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'upload.not_supported_file', null),
                icon: {
                    name: 'mat_solid:error_outline',
                    color: 'error'
                },
                onBackdrop: {
                    show: true,
                    backdrop: false
                }
            });
        }

        this.inputRef.nativeElement.value = '';
    }

    download() {
        this.downloadEmitter.emit(this.form.value)
    }

    open(element: HTMLInputElement) {
        if (this.verificaId) {
            if (this.idCampo) {
                element.click()
            } else {
                this.saveBeforeUpload.emit();
            }
        } else {
            element.click()
        }

    }

    checkSizeMB(bytes: number): number {
        return bytes / (1024 * 1024);
    }

    delete() {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: this.form.value,
                message: get(translation, 'common.delete_message', null),
                icon: {
                    name: 'mat_outline:delete',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.delete', null),
                        function: () => this.deleteEmitter.emit(this.form.value)
                    }]
            }
        );
    }
}
