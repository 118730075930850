<ng-container *transloco="let t">

    <form class="w-full" [formGroup]="form" (ngSubmit)="addTicketMessage()">

    <app-dialog-wrap [data]="data">

                <div class="flex flex-col py-2 gap-2 w-full">

                    <mat-form-field appearance="outline" *ngIf="data.alsoAddTicket" class="add-ticket-mat-form-field">
                        <mat-label><strong>{{t('support.subject')}}</strong></mat-label>
                        <mat-icon
                            class="icon-size-6 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                            [matTooltip]="t('support.subject_placeholder')"
                            matTooltipClass="text-base"
                            matTooltipPosition="before"
                            matPrefix>info</mat-icon>
                        <input
                            matInput
                            required
                            [autofocus]="data.alsoAddTicket"
                            [formControlName]="'oggetto'">
                        <mat-hint align="start" class="add-ticket-mat-hint flex lg:hidden">{{t('support.subject_placeholder')}}</mat-hint>
                        <mat-error *ngIf="!form?.get('oggetto')?.hasError('required') && form?.get('oggetto')?.hasError('whitespace')">
                            {{t('form.whitespace')}}
                        </mat-error>
                        <mat-error *ngIf="form?.get('oggetto')?.hasError('required')">
                            {{t('form.required')}}
                        </mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline" class="w-full add-ticket-mat-form-field" *ngIf="data.alsoAddTicket">
                        <mat-label><strong>{{t('support.category')}}</strong></mat-label>
                        <mat-icon
                            class="icon-size-6 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                            [matTooltip]="t('support.category_placeholder')"
                            matTooltipClass="text-base"
                            matTooltipPosition="before"
                            matPrefix>info</mat-icon>
                        <mat-select [formControlName]="'categoria'" required>
                            <mat-option *ngFor="let category of categories" [value]="category.id">
                                {{category.name | removeUnderscores}}
                            </mat-option>
                        </mat-select>
                        <mat-hint align="start" class="add-ticket-mat-hint flex lg:hidden">{{t('support.category_placeholder')}}</mat-hint>
                        <mat-error  *ngIf="form?.get('categoria')?.hasError('required') && (form?.dirty || form?.touched)" class="pb-2">{{t('form.required')}}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="w-full add-ticket-mat-form-field"  *ngIf="data.alsoAddTicket">
                        <mat-label><strong>{{t('support.tipology')}}</strong></mat-label>
                        <mat-icon
                            class="icon-size-6 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                            [matTooltip]="t('support.tipology_placeholder')"
                            matTooltipClass="text-base"
                            matTooltipPosition="before"
                            matPrefix>info</mat-icon>
                        <mat-select [formControlName]="'tipologia'" required>
                            <mat-select-trigger>
                                <div class="w-full flex flex-row gap-3 ">
                                    <div class="flex flex-row justify-start items-center">
                                        <mat-chip-list>
                                            <mat-chip [class]="form?.get('tipologia')?.value | statusEnumToColorTheme: TicketTipologia"
                                                      class="chip-width user-chip flex flex-row justify-center">
                                                <span>{{t(form?.get('tipologia')?.value | statusEnumToLanguagePath: TicketTipologia)}}</span>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                    <div class="w-full text-base text-left break-words flex flex-col justify-center items-start">
                                        <mat-label class="align-middle">{{t(this.typologyDesc[form?.get('tipologia')?.value])}}</mat-label>
                                    </div>
                                </div>
                            </mat-select-trigger>
                            <mat-option *ngFor="let tipology of typologies" [value]="tipology" class="ticket-typology-mat-option">
                                <div class="w-full flex flex-row gap-3 ">
                                    <div class="flex flex-row justify-center w-[7rem] items-center">
                                        <mat-chip [class]="tipology | statusEnumToColorTheme: TicketTipologia"
                                                  class="chip-width user-chip flex flex-row justify-center">
                                            <span>{{t(tipology | statusEnumToLanguagePath: TicketTipologia)}}</span>
                                        </mat-chip>
                                    </div>
                                    <div class="w-full text-base text-left break-words flex flex-col justify-center items-start">
                                        <mat-label class="align-middle">{{t(this.typologyDesc[tipology])}}</mat-label>
                                    </div>
                                </div>
                            </mat-option>
                        </mat-select>
                        <mat-hint align="start" class="add-ticket-mat-hint flex lg:hidden">{{t('support.tipology_placeholder')}}</mat-hint>
                        <mat-error  *ngIf="form?.get('tipologia')?.hasError('required') && (form?.dirty || form?.touched)" class="pb-2">{{t('form.required')}}</mat-error>
                    </mat-form-field>

                    <div class="flex flex-col w-full pb-6 p-6 triple-selection-div mt-2 mb-5" *ngIf="data.alsoAddTicket && !fuseConfirmationService.errorDuringAppInit">

                        <ng-container *ngIf="data?.isAreaAdministrator">
                            <mat-form-field class="w-full add-ticket-mat-form-field" appearance="outline">
                                <mat-label>{{t('cycle_doctorate.number_cycle')}}</mat-label>
                                <mat-icon
                                    class="icon-size-6 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                    [matTooltip]="t('support.ciclo_placeholder')"
                                    matTooltipClass="text-base"
                                    matTooltipPosition="before"
                                    matPrefix>info</mat-icon>
                                <mat-select [formControlName]="'ciclo'" [placeholder]="t('cycle_doctorate.number_cycle')">
                                    <mat-option *ngFor="let ciclo of cicli"
                                                [value]="ciclo">
                                        {{ciclo}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint align="start" class="add-ticket-mat-hint flex lg:hidden">{{t('support.ciclo_placeholder')}}</mat-hint>
                                <mat-error *ngIf="form?.get('ciclo').hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                            </mat-form-field>

                        </ng-container>

                        <div class="items-center pt-2 flex flex-col gap-2" *ngIf="listaCicliCorsiRuoliGroupByCiclo && !data?.isAreaAdministrator">

                            <mat-form-field class="w-full add-ticket-mat-form-field" appearance="outline">
                                <mat-label>{{t('cycle_doctorate.number_cycle')}}</mat-label>
                                <mat-icon
                                    class="icon-size-6 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                    [matTooltip]="t('support.ciclo_placeholder')"
                                    matTooltipClass="text-base"
                                    matTooltipPosition="before"
                                    matPrefix>info</mat-icon>
                                <mat-select [formControlName]="'ciclo'" [placeholder]="t('cycle_doctorate.number_cycle')">
                                    <mat-option *ngFor="let ciclo of listaCicliCorsiRuoliGroupByCiclo | keyvalue"
                                                [value]="ciclo.key">
                                        {{ciclo.key}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint align="start" class="add-ticket-mat-hint flex lg:hidden">{{t('support.ciclo_placeholder')}}</mat-hint>
                                <mat-error *ngIf="form?.get('ciclo').hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="w-full add-ticket-mat-form-field" appearance="outline" *ngIf="!data?.isAreaAdministrator">
                                <mat-label>{{t('common.course')}}</mat-label>
                                <mat-icon
                                    class="icon-size-6 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                    [matTooltip]="t('support.codiceCorsoStudiEsse3_placeholder')"
                                    matTooltipClass="text-base"
                                    matTooltipPosition="before"
                                    matPrefix>info</mat-icon>
                                <mat-select [formControlName]="'codiceCorsoStudiEsse3'" [placeholder]="t('common.course')">
                                    <mat-option *ngFor="let corso of corsiStudi" [value]="corso.codiceEsse3">
                                        {{(corso.codiceEsse3 || '')+ ( (corso.cicliCorsiDiStudi[0]?.denominazione)? ' - ' : '')+( corso.cicliCorsiDiStudi[0]?.denominazione || '')}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint align="start" class="add-ticket-mat-hint flex lg:hidden">{{t('support.codiceCorsoStudiEsse3_placeholder')}}</mat-hint>
                                <mat-error *ngIf="form?.get('codiceCorsoStudiEsse3').hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="w-full add-ticket-mat-form-field" appearance="outline" *ngIf="!data?.isAreaAdministrator">
                                <mat-label>{{t('auth.role')}}</mat-label>
                                <mat-icon
                                    class="icon-size-6 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                                    [matTooltip]="t('support.ruolo_placeholder')"
                                    matTooltipClass="text-base"
                                    matTooltipPosition="before"
                                    matPrefix>info</mat-icon>
                                <mat-select [formControlName]="'ruolo'" [placeholder]="t('auth.role')">
                                    <mat-option *ngFor="let ruolo of ruoli" [value]="ruolo">
                                        {{ruolo | removeUnderscores}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint align="start" class="add-ticket-mat-hint flex lg:hidden">{{t('support.ruolo_placeholder')}}</mat-hint>
                                <mat-error *ngIf="form?.get('ruolo').hasError('required')">
                                    {{t('form.required')}}
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>

                    <mat-form-field appearance="outline" *ngIf="data.alsoAddTicket" class="add-ticket-mat-form-field">
                        <mat-label><strong>{{t('support.telephone')}}</strong></mat-label>
                        <mat-icon
                            class="icon-size-6 mb-3 mr-2 cursor-pointer hidden lg:flex" style="color: #A5AFBB"
                            [matTooltip]="t('support.telephone_placeholder')"
                            matTooltipClass="text-base"
                            matTooltipPosition="before"
                            matPrefix>info</mat-icon>
                        <input
                            matInput
                            [formControlName]="'telefono'">
                        <mat-hint align="start"  class="add-ticket-mat-hint flex lg:hidden">{{t('support.telephone_placeholder')}}</mat-hint>
                        <mat-error *ngIf="form?.get('telefono')?.hasError('pattern')">
                            {{t('form.not_valid_number')}}
                        </mat-error>
                    </mat-form-field>

                    <div class="w-full flex flex-col gap-2 mb-6 mt-2">
                        <mat-label><strong>{{t(data.alsoAddTicket ? 'support.problem_description' : 'support.text_message')}} *</strong></mat-label>
                        <angular-editor formControlName="testoMessaggio"
                                        required
                                        [config]="editorConfig">
                            <ng-template #customButtons let-executeCommandFn="executeCommandFn">
                                <ae-toolbar-set>
                                    <ae-button iconClass="fa fa-clipboard"
                                               title="Incolla dagli appunti"
                                               (buttonClick)="pasteFromClipboard()">
                                    </ae-button>
                                </ae-toolbar-set>
                            </ng-template>
                        </angular-editor>
                        <mat-error *ngIf="formSubmitted && !form?.get('testoMessaggio')?.hasError('required') && form?.get('testoMessaggio')?.hasError('whitespace')"
                                    class="text-xs">
                            {{t('form.whitespace')}}
                        </mat-error>
                        <mat-error *ngIf="formSubmitted && form?.get('testoMessaggio')?.hasError('required')"
                                   class="text-xs">
                            {{t('form.required')}}
                        </mat-error>
                    </div>

                    <div class="w-full flex flex-col gap-2">
                        <mat-label><strong>{{t('support.attachments')}}</strong></mat-label>
                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <input-file-with-preview fileLimit="5"
                                                             [addLabel]="t('support.attach')"
                                                             formControlName="allegati"
                                                             (rejectedFile)="fileRejected($event)"
                                                             placeholder="">
                                    </input-file-with-preview>
                                </div>
                            </div>
                        </div>
                    </div>



        </div>


    </app-dialog-wrap>

    <div class="w-full px-6 py-4 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <div fxLayout="row" fxLayoutAlign="end center" class="mt-0.5 mb-0.5 gap-4">
            <button [color]="'accent'" mat-raised-button type="button" (click)="closeDialog()">
                <mat-icon class="mr-2">close</mat-icon>
                {{t('common.close')}}
            </button>
            <div class="flex flex-row items-center gap-2">
                <button [color]="'primary'" mat-raised-button type="submit" [disabled]="loading">
                    <mat-icon class="mr-2" *ngIf="!loading">save</mat-icon>
                    <mat-spinner *ngIf="loading" diameter="20" class="mr-2"></mat-spinner>
                    {{t('support.add')}}
                </button>

            </div>
        </div>
    </div>

    </form>


</ng-container>

