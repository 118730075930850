import moment, {Moment} from "moment";
import {
    PeriodoRegistroAttivitaInfoView,
    PeriodoRegistroAttivitaStatus,
    SospensioneStudenteCiclo
} from "../../../api-clients/generated/services";

export function italianDate(dataUTC: Date | string | undefined): Date {
    return !!dataUTC ? moment(moment.utc(dataUTC).local().format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm').toDate() : null;
}

export function italianDateWithFormat(dataUTC: Date | string | undefined, format = 'DD/MM/YYYY HH:mm'): string {
    return !!dataUTC ? moment(moment.utc(dataUTC).local().format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm').format(format) : null;
}


const formatDay = (date: string): string =>  {
    return moment(new Date(date)).format('DD/MM/YYYY');
};

export function rangeOfYears(start, end) {
    return Array(end - start + 1)
        .fill(start)
        .map((year, index) => year + index)

}

export const stringaToDataDDMMYY = (dataUTC: Date | string | undefined): string => {
    return !!dataUTC ? moment(dataUTC, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY') : null;

}

export function dataStringToData(data: string | undefined, inputFormat = 'DD/MM/YYYY'): Date {
    return !!data ? moment(data, inputFormat).utc().toDate() : null;
}

export function dataToDataString(data: Date | string | undefined, outuputFormat = 'DD/MM/YYYY', inputFromat?: string): string {
    return !!data ? moment(data, inputFromat).format(outuputFormat) : null;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function dateToUtc(data: Date | string | undefined): Date {
    return !!data ? moment(data).utc().toDate() : null;
}

export function differenceBetweenDateString(start: string, end: string): number {
    const startMoment = moment(start);
    const endMoment = moment(end);
    const durata = moment.duration(endMoment.diff(startMoment));
    return durata.asMinutes()
}


export function getMonthDateRange(year: number, month: number): { startDate: string; endDate: string } {
    console.log(year, month)
    const startDate = moment([year, month]);
    const endDate = moment(startDate).endOf('month');
    return {startDate: startDate.format('DD/MM/YYYY'), endDate: endDate.format('DD/MM/YYYY')};
}


/**
 * Se gli viene passato 12:00 avremo 12:00+02:00 (deve essere passata anche la data, altrimenti viene inserito un offsett errato
 * @param data
 * @param ora
 */
export const orarioInTimezone = (data: string, ora: string | Date | undefined) => {
    return data && ora ?  moment( data + ' ' + ora, 'YYYY-MM-DD HH:mm').format('HH:mmZ') : ora ?  moment( ora, 'HH:mm').format('HH:mmZ'): ora
}

export const convertiOrarioUtcInLocale = (data: string, ora: string | Date | undefined) => {
    return  ora ?  moment( data + ' ' + ora, 'YYYY-MM-DD HH:mm').format('HH:mm') : ora
}


/**
 * Utilizzato nella formGroupConfig, per gli input di tipo TypeDialogFormEnum.DATA, affinchè a partire da un array di giorni della settimana siano disabilitati tali giorni nel date picker.
 * @param d
 * @param giorniDaDisabilitare
 * @param periodiSospensione
 */
export const dateFilter = (d: Date | null, giorniDaDisabilitare: string[], periodiSospensione: Array<SospensioneStudenteCiclo>, periodiDiValidazione: Array<PeriodoRegistroAttivitaInfoView>): boolean => {
    const filterDateToNumberDate: number[]= giorniDaDisabilitare.map(date => DayNumberFromDayName.find(dayNum => dayNum?.key === date)?.value);
    const day = (d || new Date()).getDay();
    const matchingSuspensionPeriods = periodiSospensione?.
        filter(s => (!s.dataInizio || moment(s.dataInizio, 'DD/MM/YYYY').isSameOrBefore(d))
            && (!s.dataFine || moment(s.dataFine, 'DD/MM/YYYY').isSameOrAfter(d)))?.
            length || 0;
    const matchingPendingValidationPeriods = periodiDiValidazione?.
        filter(vr => vr.stato !== PeriodoRegistroAttivitaStatus.RIFIUTATO)?.
        filter(vr => moment(vr.dataInizio).isSameOrBefore(d) && moment(vr.dataFine).isSameOrAfter(d))?.
            length || 0;
    return !filterDateToNumberDate.includes(day) && matchingSuspensionPeriods == 0 && matchingPendingValidationPeriods == 0;
};

export function isTodayInDateRange(dataInizio, dataFine): boolean{
    return (moment().isSameOrAfter(moment(dataInizio)) && moment().isSameOrBefore(moment(dataFine)));
}

export const DayNumberFromDayName= [{key: 'lunedì', value:1}, {key: 'martedì', value:2}, {key: 'mercoledì', value:3},{key: 'giovedì', value:4},{key: 'venerdì', value:5}, {key: 'sabato', value:6},{key: 'domenica', value:0}, ]

