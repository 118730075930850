import {Injectable} from '@angular/core';
import moment from "moment/moment";
import {catchError, map, Observable, of, tap} from "rxjs";
import {DateTimeDTO, UtilitiesService} from "../../../api-clients/generated/services";
import {cloneDeep} from "lodash-es";

@Injectable({
    providedIn: 'root'
})
export class TimeService {


    private _currentDateTime: moment.Moment = moment();

    constructor(private utilitiesService: UtilitiesService) {}

    get currentDateTime(): moment.Moment {
        return cloneDeep(this._currentDateTime);
    }

    public updateCurrentTime(): Observable<moment.Moment>{
        return this.utilitiesService.getTimestampNow().pipe(
            map((dateTimeDTO: DateTimeDTO) => moment.utc(dateTimeDTO?.timestamp).local()),
            // set frontend time if cannot reach be
            catchError(error => {
                this._currentDateTime = moment();
                console.log('TimeService: cannot reach BE for current date time. Setting FE date time', this._currentDateTime);
                return of(this.currentDateTime);
            }),
            tap((dateTime: moment.Moment) => {
                this._currentDateTime = dateTime;
                console.log('TimeService: retrieved BE date time', this._currentDateTime);
            })
        );
    }

}
