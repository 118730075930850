import { Pipe, PipeTransform } from '@angular/core';
import {ConfigurazioneColonna} from "../components/table/model/generic-table-model";


@Pipe({name: 'valueForStatusPipe'})
export class ValueForStatusPipe implements PipeTransform {

    transform(element: any, colonna: ConfigurazioneColonna, colonnaKey: string): any {
        return !!colonna.valueCheck ?
            !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck])
                : element[colonnaKey] : element[colonnaKey];
    }
}
