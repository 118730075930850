<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">

        <mat-dialog-content>

            <fuse-alert class="items-start mt-2 mb-3"
                        [appearance]="'outline'"
                        [type]="'info'">
                {{ t('study_plan_status.define_presumed_message') }}
            </fuse-alert>

            <app-generic-table class="w-full"
                               *ngIf="data.activitiesConf"
                               [configuration]="data.activitiesConf"
                               (clickAction)="data.onTableClickAction?.($event)"
                               [idRigheSolaLettura]="data.activitiesToDisableIds"
                               [disabledRowTooltipText]="t('study_plan_status.activity_already_chosen')"
                               (tableDataEmitter)="onAttivitaSelezionateChanged($event)">
            </app-generic-table>

        </mat-dialog-content>

    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <div class=" flex flex-col lg:flex-row items-center justify-center gap-2">
            <fuse-alert
                *ngIf="(!tableData || tableData?.selectedRows?.length != 1)"
                class="items-start"
                [appearance]="'outline'"
                [type]="'error'"
                [pxPaddingInput]="'6px'"
                [pxPaddingRightInput]="'10px'">
                {{ t('study_plan_status.must_select_one_activity') }}
            </fuse-alert>
        </div>

        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
        <div class="flex flex-row items-center gap-2">
            <button [color]="'primary'"
                    [disabled]="(!tableData || tableData?.selectedRows?.length != 1)"
                    mat-raised-button
                    (click)="confirmAction()"
                    type="button">
                <mat-icon class="mr-2">check</mat-icon>
                {{t('dialog.confirm')}}
            </button>
        </div>
    </div>
</ng-container>
