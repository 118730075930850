<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">

        <mat-dialog-content>

            <mat-tab-group class="w-full mt-4 max-h-[60vh]" appScrollRetainer
                           *ngIf="!!data.planSummaryByYears">
                <ng-container *ngFor="let planSummaryOfYear of data.planSummaryByYears; trackBy: trackByIndex">
                    <mat-tab [disabled]="!planSummaryOfYear.canEditActivitiesForYear">
                        <ng-template matTabLabel>
                            <div [matTooltip]="!planSummaryOfYear.canEditActivitiesForYear ? t('study_plan_status.cannot_edit_year') : undefined"
                                 class="flex flex-row items-center gap-1">
                                <mat-icon *ngIf="!planSummaryOfYear.canEditActivitiesForYear"
                                          class="icon-size-4">
                                    info
                                </mat-icon>
                                <span>{{annoRiferimentoToRomanNumeral(planSummaryOfYear.year) + ' ' + t('common.year')}}</span>
                            </div>
                        </ng-template>
                        <ng-template matTabContent>
                            <ul *ngIf="planSummaryOfYear.activities.length > 0; else noActTmpl">
                                <li *ngFor="let activity of planSummaryOfYear.activities"
                                     class="list-disc mb-2" >
                                    <div class="flex flex-col">
                                        <span class="font-semibold line-clamp-1 max-w-[70vw] text-left">{{activity.activityDenomination}}</span>
                                        <div class="text-sm flex flex-row w-full gap-1">
                                            <div class="bg-slate-400 text-white px-1 rounded min-w-fit">{{activity.categoryType  | titlecase}}</div>
                                            <span class="font-medium line-clamp-1 max-w-[60vw]">{{activity.categoryDenomination }}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <ng-template #noActTmpl>
                                <span class="italic">{{t('study_plan_status.no_activity_in_draft')}}</span>
                            </ng-template>
                        </ng-template>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>

        </mat-dialog-content>

    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
        <div class="flex flex-row items-center gap-2">
            <button [color]="'primary'"
                    mat-raised-button
                    (click)="confirmAction()"
                    type="button">
                <mat-icon class="mr-2">check</mat-icon>
                {{t('dialog.confirm')}}
            </button>
        </div>
    </div>
</ng-container>
