/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class MediaService {

    protected basePath = 'https://api-dev-1-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce il byte array dell&#x27;immagine profilo di un Docente/Personale-PTA.
     * 
     * @param nomeFile 
     * @param codiceFiscaleUserTarget 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImmagineDocenteOrPtaForm(nomeFile?: string, codiceFiscaleUserTarget?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getImmagineDocenteOrPtaForm(nomeFile?: string, codiceFiscaleUserTarget?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getImmagineDocenteOrPtaForm(nomeFile?: string, codiceFiscaleUserTarget?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getImmagineDocenteOrPtaForm(nomeFile?: string, codiceFiscaleUserTarget?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nomeFile !== undefined) {
            formParams = formParams.append('nomeFile', <any>nomeFile) as any || formParams;
        }
        if (codiceFiscaleUserTarget !== undefined) {
            formParams = formParams.append('codiceFiscaleUserTarget', <any>codiceFiscaleUserTarget) as any || formParams;
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/media/immagine-docente-or-pta`,
            {
             responseType: "blob" as "json",
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;immagine profilo dell&#x27;utente cercato nel bucket privato.
     * 
     * @param nomeFile 
     * @param codiceFiscaleUserTarget 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImmagineProfiloForm(nomeFile?: string, codiceFiscaleUserTarget?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getImmagineProfiloForm(nomeFile?: string, codiceFiscaleUserTarget?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getImmagineProfiloForm(nomeFile?: string, codiceFiscaleUserTarget?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getImmagineProfiloForm(nomeFile?: string, codiceFiscaleUserTarget?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nomeFile !== undefined) {
            formParams = formParams.append('nomeFile', <any>nomeFile) as any || formParams;
        }
        if (codiceFiscaleUserTarget !== undefined) {
            formParams = formParams.append('codiceFiscaleUserTarget', <any>codiceFiscaleUserTarget) as any || formParams;
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/media/immagine-profilo`,
            {
             responseType: "blob" as "json",
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
