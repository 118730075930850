<ng-container *transloco="let t">

    <form class="w-full" [formGroup]="form" (ngSubmit)="save()">

    <app-dialog-wrap [data]="data">

        <div class="flex flex-col pt-2 pb-1 gap-2 w-full">

            <!-- DELIBERA -->
            <div class="w-full flex flex-col md:flex-row gap-3 items-start"
                *ngIf="!data?.corso?.deliberaCoordinatoreAttivitaTrasversale">

                <mat-form-field appearance="outline"
                                class="w-full add-mobilita-mat-form-field"
                                *ngIf="form?.get('deliberaCollegio') as fieldCtrl">
                    <mat-label><strong>{{t('mobility.collegio_approval')}}</strong></mat-label>
                    <mat-select formControlName='deliberaCollegio'
                                required>
                        <mat-option *ngFor="let delibera of delibere" [value]="delibera.id">
                            {{t('mobility.delibera_name')}} {{delibera.numero}} - {{formatDeliberaDate(delibera?.data)}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="fieldCtrl.hasError('required')">
                        {{t('form.required')}}
                    </mat-error>

                </mat-form-field>

                <button [color]="'primary'"
                        mat-raised-button
                        type="button"
                        class="add-delibera-button"
                        [disabled]="loading"
                        (click)="this.openAddNewDeliberaDialog()">
                    <mat-icon class="mr-2">add</mat-icon>
                    {{t('common.add_delibera')}}
                </button>

            </div>

            <!-- DELIBERA GIA' CARICATA -->
            <app-info-delibera-caricata *ngIf="data?.corso?.deliberaCoordinatoreAttivitaTrasversale"
                                        [utenteCaricamento]="data?.corso?.utenteCaricamentoDelibera"
                                        [dataCaricamento]="data?.corso?.dataCaricamentoDelibera"
                                        [delibera]="data?.corso?.deliberaCoordinatoreAttivitaTrasversale">
            </app-info-delibera-caricata>

        </div>

    </app-dialog-wrap>

    <div class="w-full px-6 py-4 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <div fxLayout="row" fxLayoutAlign="end center" class="mt-0.5 mb-0.5 gap-4">
            <button [color]="'accent'" mat-raised-button type="button"
                    [disabled]="loading"
                    (click)="closeDialog()">
                <mat-icon class="mr-2">close</mat-icon>
                {{t('common.close')}}
            </button>
            <div class="flex flex-row items-center gap-2">
                <button [color]="'primary'" mat-raised-button type="submit" [disabled]="loading">
                    <mat-icon class="mr-2" *ngIf="!loading">save</mat-icon>
                    <mat-spinner *ngIf="loading" diameter="20" class="mr-2"></mat-spinner>
                    {{t('common.save')}}
                </button>

            </div>
        </div>
    </div>

    </form>


</ng-container>

