import { Pipe, PipeTransform } from '@angular/core';
import {ConfigurazioneColonna, TipoClickEnum} from '../components/table/model/generic-table-model';


@Pipe({name: 'getButtonPipe'})
export class GetButtonPipe implements PipeTransform {

    transform(colonna: ConfigurazioneColonna, readOnly: boolean): any {
        return !!colonna.button ? readOnly ? colonna.button.filter(value => value.click === TipoClickEnum.INFO) : colonna.button : null;
    }
}
