import {
    getCfuOttentutiFromApprovedPlan,
    getCfuPrevistiFromApprovedPlan, getDataSuperamentoValueFromApprovedPlan,
    getNotaSuperamentoValueFromApprovedPlan,
    getSuperatoValueFromApprovedPlan,
    unifyDocentiAndExtra
} from '../../study-plan-utils';
import {
    CategoriaInAggiornamentoPianoDiStudi,
    CategoriaInPianoDiStudiInfoView, DatiCorsoOffertaFormativa, DatiPercorsoDiStudi,
    PercorsoDiStudiView, PianoDiStudiInfoViewImpl,
    TipoModificaCorsoPianoDiStudi
} from '../../../../../../../api-clients/generated/services';
import {
    BooleanStatusNotNull,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from '../../../../../../shared/components/table/model/generic-table-model';
import {get, sortBy} from "lodash";
import {getPresumedNumbersForDraft} from "../nuova-presentazione/configurazione-corsi-nuova-presentazione";
import {Translation} from "@ngneat/transloco";

export function createPercorsoDiStudiListString(percorsiDiStudi: Array<DatiPercorsoDiStudi> | undefined) {
    return percorsiDiStudi?.map(c => c.descrizionePercorsoDiStudi).join(', ').toUpperCase();
}

export function buildConfigurationForCoursesPlanToApprove(translation: Translation,
                                                          category: CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoPianoDiStudi,
                                                          approvedPlan?: PianoDiStudiInfoViewImpl,): GenericTableConfigurationModel {

    const coursesPlan = category.corsi;

    //count get presumed numbers
    const presumedNumbers = getPresumedNumbersForDraft(category);

    let tableData = coursesPlan?.map(element => ({...(element?.datiCorsoOffertaFormativa ?? element), tipoModifica: element.tipoModifica}));

    let myData =
        tableData?.map(item => ({
            ...item,
            cfuRatio: getCfuOttentutiFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa) + '/' + getCfuPrevistiFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            superato: getSuperatoValueFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa) ? BooleanStatusNotNull.BOOLEAN_TRUE : BooleanStatusNotNull.BOOLEAN_FALSE,
            cfuPrevisti: getCfuPrevistiFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            cfuOttenuti: getCfuOttentutiFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            notaSuperamento: getNotaSuperamentoValueFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            dataSuperamento: getDataSuperamentoValueFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            docentiTutti: unifyDocentiAndExtra(item),
            curriculumRiferimento: createPercorsoDiStudiListString(item?.percorsiDiStudi),
            cfuString: getCfuOttentutiFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa) + '/' + getCfuPrevistiFromApprovedPlan(item, approvedPlan, category.idCategoriaOffertaFormativa),
            denominazione: item.denominazione ?? item.denominazioneAttivitaPresunta,
            denominazioneFormatted: (item.idAttivitaPresunta && !item.denominazione) ? (get(translation, 'study_plan_status.presumed_activity') + ' ' + presumedNumbers.pop()) : item.denominazione,
            idCategoriaOffertaFormativa: category.idCategoriaOffertaFormativa,
            isArchiviato: item.isArchiviato || item?.isAssociazioneArchiviata,
        }));

    myData = sortBy(myData, [
        attivita => !attivita.idAttivitaProposta,
        attivita => !attivita.idAttivitaPresunta,
        attivita => attivita.denominazioneFormatted
    ], ['asc', 'asc', 'asc']);

    return {
            configuration: {
                data: myData,
                totalElements: myData?.length,
                isPaginatedBE: false,
                sticky: true,

                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.course_title',
                        colonnaKey: 'denominazioneFormatted',
                        flex: 25,
                        alsoShowChipIf: (activity) => {
                            if(activity.idAttivitaProposta){
                                return {
                                    chipText: 'study_plan_status.proposed_activity',
                                    iconName: 'school',
                                    classes: 'text-blue-600'
                                };
                            } else if (activity.idAttivitaPresunta){
                                return {
                                    chipText: activity.denominazione ? 'study_plan_status.presumed_activity_defined' : 'study_plan_status.presumed_activity_not_defined',
                                    iconName: 'question_mark',
                                    classes: activity.denominazione ? 'text-green-600' : 'text-orange-600'
                                };
                            } else {
                                return undefined;
                            }
                        },
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'common.edit_type',
                        colonnaKey: 'tipoModifica',
                        tooltip: tipoModifica => 'study_plan_status.edit_types.' + tipoModifica.toLowerCase(),
                        flex: 15,
                        statusType: TipoModificaCorsoPianoDiStudi
                    },
                    /*{
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'cycle_doctorate.final_check',
                        colonnaKey: 'verificaFinale',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'student.executed',
                        colonnaKey: 'superato',
                        flex: 10,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },*/
                    {
                        tipo: TipoColonnaEnum.CHIP_STATUS,
                        nomeColonna: 'student.executed',
                        colonnaKey: 'superato',
                        statusType: BooleanStatusNotNull,
                        show: (_, activity) => !activity.idAttivitaPresunta,
                        flex: 8
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'cycle_doctorate.professor',
                        colonnaKey: 'docentiTutti',
                        show: (_, activity) => !activity.idAttivitaProposta && (!activity.idAttivitaPresunta || activity.denominazione),
                        flex: 25,
                    },
                   /* {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.reference_curriculum',
                        colonnaKey: 'curriculumRiferimento',
                        flex: 20,
                    },*/
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'common.cfu',
                        colonnaKey: 'cfuString',
                        flex: 10,
                    },
                    {
                        tipo: TipoColonnaEnum.BOOLEAN,
                        nomeColonna: 'study_plan_status.archived_activity',
                        colonnaKey: 'isArchiviato',
                        show: (_, activity) => !activity.idAttivitaProposta && (!activity.idAttivitaPresunta || activity.denominazione),
                        flex: 8,
                        convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                    },
                    {
                        nomeColonna: 'common.additional_information',
                        colonnaKey: 'descrizione',
                        flex: 10,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [{
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.additional_information',
                            color: 'accent'
                        }]
                    },
                ],
                hidePaginator: true,
                css: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'min-width': 'unset'
                },
                messaggioDatiAssenti: 'custom_table.no_data_activities_for_year'
            }
        };
}
