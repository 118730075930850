import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {finalize, switchMap, take, takeUntil} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DialogWrapI} from "../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    AddAttivitaACategoriaOffertaFormativaRequest,
    AddAttivitaExtraOffertaFormativaDTO,
    AddAttivitaTrasversaleOffertaFormativaDTO,
    AddCorsoOffertaFormativaDTO,
    AttivitaCategoriaAssociationInfoView,
    AttivitaOffertaFormativaInfoView,
    CategoriaOffertaFormativaInfoView,
    CicliCorsiDiStudiService,
    ElementoOffertaFormativaType,
    OffertaFormativaInfoViewImpl,
    OfferteFormativeService,
    PercorsoDiStudiView, UpdateAnniDisponibilitaAttivitaPerCategoriaRequest,
    UpdateAttivitaExtraOffertaFormativaDTO,
    UpdateAttivitaTrasversaleOffertaFormativaDTO,
    UpdateCorsoOffertaFormativaDTO
} from "../../../../api-clients/generated/services";
import {AppInitService} from "../../service/app-init.service";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {filter} from "rxjs/operators";
import {CustomValidators} from "../../validators/custom-validators";
import {DialogManageActivityService} from "./dialog-manage-activity.service";
import {ButtonType} from "../custom-button/custom-button.component";
import {
    DialogInvalidStudyPlansComponent,
    DialogInvalidStudyPlansComponentI
} from "../../../modules/landing/cycle/training-offer/dialog-invalid-study-plans/dialog-invalid-study-plans.component";

export interface DialogManageActivityI extends DialogWrapI {
    annoInsegnamento?: string;
    activityToEdit?: AttivitaCategoriaAssociationInfoView;
    isOffertaPubblica?: boolean;
    editGlobalTopAlertTextLabel?: string;
    editYearsTopAlertTextLabel?: string;
    type: ElementoOffertaFormativaType;
    idCategoria: string;
    category: CategoriaOffertaFormativaInfoView;
    filteredCourses: Array<AttivitaOffertaFormativaInfoView>;
    trainingOffer: OffertaFormativaInfoViewImpl;
}

enum TypeModification {
    YEARS = 'YEARS',
    ACTIVITY = 'ACTIVITY',
    NEW = 'NEW',
    SELECT = 'SELECT'
}

@Component({
    selector: 'app-dialog-manage-activity',
    templateUrl: './dialog-manage-activity.component.html',
    styleUrls: ['./dialog-manage-activity.component.scss']
})
export class DialogManageActivityComponent extends AbstractDefaultComponent implements OnInit {


    loading: boolean;


    typeModification = TypeModification;
    firstFormGroup: FormGroup;
    formGroupYears: FormGroup;
    formCourse: FormGroup;
    formExtraActivity: FormGroup;
    formTransversalActivity: FormGroup;
    selectActivityForm: FormGroup;
    showMiniLoader = false;
    type = ElementoOffertaFormativaType;
    showTerzoAnno: boolean;
    showPrimoAnno: boolean;
    showSecondoAnno: boolean;
    activityInSameCategory: AttivitaCategoriaAssociationInfoView | undefined;


    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogManageActivityI,
                public dialogRef: MatDialogRef<DialogManageActivityComponent>,
                private fb: FormBuilder,
                private appInitService: AppInitService,
                protected dialogAddCourseService: DialogManageActivityService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private cicliCorsiDiStudiService: CicliCorsiDiStudiService,
                private offerteFormativeService: OfferteFormativeService,
                private dialog: MatDialog,
    ) {
        super();
        this.createForm();
        this.subscribeToGetCurriculums();
    }

    ngOnInit(): void {
        this.showYears();
        console.log(this.data.filteredCourses, 'allCourses')
    }

    private showYears() {
        this.data.category.configurazione.configurazioni.forEach(({terzo_anno, primo_anno, secondo_anno}) => {
            if (terzo_anno) {
                this.showTerzoAnno = terzo_anno;
            }
            if (primo_anno) {
                this.showPrimoAnno = primo_anno;
            }
            if (secondo_anno) {
                this.showSecondoAnno = secondo_anno;
            }
        });
    }

    createForm(): void {
        const activityToEdit = this.data?.activityToEdit;

        this.firstFormGroup = this.fb.group({
            type: [null, Validators.required]
        });

        this.formGroupYears = this.fb.group({
            primoAnno: activityToEdit?.primoAnno || this.data.annoInsegnamento === 'I',
            secondoAnno: activityToEdit?.secondoAnno || this.data.annoInsegnamento === 'II',
            terzoAnno: activityToEdit?.terzoAnno || this.data.annoInsegnamento === 'III',
        }, {validators: CustomValidators.AtLeastOneYearValidator()});


        switch (this.data.type) {
            case this.type.CORSO:
                this.buildFormCourse(activityToEdit?.attivitaOffertaFormativa);
                break;
            case this.type.ATTIVITAEXTRA:
                this.buildFormCourseExtraActivity(activityToEdit?.attivitaOffertaFormativa);
                break;
            case this.type.ATTIVITATRASVERSALE:
                this.buildFormCourseTransversalActivity(activityToEdit?.attivitaOffertaFormativa);
                break;
        }

        this.selectActivityForm = this.fb.group({
            array: this.fb.array([], {validators: CustomValidators.minArrayLength(1)})
        });

        this.selectActivityForm.get('array').valueChanges.subscribe(value => {
            const categoryInOffer = this.data?.trainingOffer?.categorie?.find(c => c.id === this.data?.idCategoria);
            const activityInSameCategory = categoryInOffer?.attivitaCategoriaAssociations
                ?.find(a =>
                    a.attivitaOffertaFormativa?.id === value?.[0]?.key
                    && !a.attivitaOffertaFormativa?.isArchiviato
                    && !a?.isArchiviato);
            if(activityInSameCategory) {
                this.activityInSameCategory = activityInSameCategory;
                this.formGroupYears.patchValue({
                    primoAnno: this.activityInSameCategory.primoAnno || this.data.annoInsegnamento === 'I',
                    secondoAnno: this.activityInSameCategory.secondoAnno || this.data.annoInsegnamento === 'II',
                    terzoAnno: this.activityInSameCategory.terzoAnno || this.data.annoInsegnamento === 'III',
                });
            };
        });


    }

    get denomination() {
        return (this.selectActivityForm.get('array').value as {key: string, cheked: boolean}[])?.map(value => this.data.filteredCourses?.find(c => c.id === value?.key)?.denominazione).join(', ');
    }


    closeDialog() {
        this.dialogRef.close();
    }

    get form(): FormGroup {
        switch (this.data.type) {
            case this.type.CORSO:
                return this.formCourse;
            case this.type.ATTIVITAEXTRA:
                return this.formExtraActivity;
            case this.type.ATTIVITATRASVERSALE:
                return this.formTransversalActivity;
        }
    }

    save(forced = undefined) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!this.form.valid || this.formGroupYears.invalid) {
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
            this.form.markAllAsTouched();
        } else {
            this.showMiniLoader = true;
            const formRawValue = this.form.getRawValue();
            const body = this.getBody(formRawValue);
            this.formGroupYears.disable();
            this.form.disable();
            const successMessage = 'training_offer.activity_added';
            const errorMessage = 'training_offer.error_adding_activity';

            this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => this.offerteFormativeService.addAttivitaACategoriaOffertaFormativa(
                    body as unknown as AddAttivitaACategoriaOffertaFormativaRequest, this.data.idCategoria, forced)),
                takeUntil(this.destroy$),
                finalize(() => {
                    this.formEnable();
                    this.showMiniLoader = false;
                })
            ).subscribe({
                next: (offertaFormativaInfo: OffertaFormativaInfoViewImpl) => {
                    console.log(offertaFormativaInfo)
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, successMessage, null),
                        type: SnackbarTypes.Success,
                    });
                    this.dialogRef.close(offertaFormativaInfo);
                },
                error: (err) => {
                    if(err.status === 409 && err?.error?.code == 1034){
                        this.openInvalidStudyPlansDialog(err?.error?.utentiConPianoNonPiuValido, () => this.save(true));
                    } else {
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, errorMessage, null),
                            type: SnackbarTypes.Error,
                        });
                    }
                }
            });



        }
    }

    updateActivity(forced = undefined) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!this.form.valid || this.formGroupYears.invalid) {
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
            this.form.markAllAsTouched();
        } else {
            this.showMiniLoader = true;
            const formRawValue = this.form.getRawValue();
            const body = this.getBodyUpdate(formRawValue);
            this.formGroupYears.disable();
            this.form.disable();
            const request = this.getRequest(body, forced);
            const successMessage = 'training_offer.activity_edited';
            const errorMessage = 'training_offer.error_editing_activity';
            this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => request),
                takeUntil(this.destroy$),
                finalize(() => {
                    this.formEnable();
                    this.showMiniLoader = false;
                })
            ).subscribe({
                next: (offertaFormativaInfo: OffertaFormativaInfoViewImpl) => {
                    console.log(offertaFormativaInfo)
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, successMessage, null),
                        type: SnackbarTypes.Success,
                    });
                    this.dialogRef.close(offertaFormativaInfo);
                },
                error: (err) => {
                    console.log(err);
                    if(err.status === 409 && err?.error?.code == 1034){
                        this.openInvalidStudyPlansDialog(err?.error?.utentiConPianoNonPiuValido, () => this.updateActivity(true));
                    } else if(err.status === 409 && err?.error?.code == 1023){
                        this.fuseConfirmationService.openErrorDialog(
                            {error: err},
                            this.translocoService,
                            () => {},
                            () => this.updateActivity(),
                            'dialog.cancel',
                            err?.error?.message
                        );
                    } else {
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, errorMessage, null),
                            type: SnackbarTypes.Error,
                        });
                    }
                }
            });
        }
    }

    private getBody(formRawValue): AddAttivitaACategoriaOffertaFormativaRequest {
        switch (this.data.type) {
            case this.type.CORSO:
                return {
                    tipo_attivita: this.data.type,
                    primo_anno: this.formGroupYears.get('primoAnno').value,
                    secondo_anno: this.formGroupYears.get('secondoAnno').value,
                    terzo_anno: this.formGroupYears.get('terzoAnno').value,
                    add_corso_offerta_formativa_dto: this.getAddCorsoOffertaFormativaDto(formRawValue)
                };
            case this.type.ATTIVITAEXTRA:
                return {
                    tipo_attivita: this.data.type,
                    primo_anno: this.formGroupYears.get('primoAnno').value,
                    secondo_anno: this.formGroupYears.get('secondoAnno').value,
                    terzo_anno: this.formGroupYears.get('terzoAnno').value,
                    add_attivita_extra_offerta_formativa_dto: this.getAddAttivitaExtraOffertaFormativaDto(formRawValue)
                };
            case this.type.ATTIVITATRASVERSALE:
                return {
                    tipo_attivita: this.data.type,
                    primo_anno: this.formGroupYears.get('primoAnno').value,
                    secondo_anno: this.formGroupYears.get('secondoAnno').value,
                    terzo_anno: this.formGroupYears.get('terzoAnno').value,
                    add_attivita_trasversale_offerta_formativa_dto: this.getAddAttivitaTrasversaleOffertaFormativaDto(formRawValue)
                }
        }

    }

    private getAddAttivitaTrasversaleOffertaFormativaDto(formRawValue): AddAttivitaTrasversaleOffertaFormativaDTO {
        return {
            denominazione: formRawValue.denominazione,
            ore: formRawValue.ore,
            descrizione_cfu: formRawValue.descrizioneCfu,
            descrizione: formRawValue.descrizione,
            presenza_delibera_coordinatore: !!formRawValue.presenzaDeliberaCoordinatore,
            presenza_documento_dottorando: !!formRawValue.documentoDottorando.presenza,
            tipo_documento_dottorando: formRawValue.documentoDottorando.presenza ? formRawValue.documentoDottorando.tipo : undefined,
            presenza_documento_supervisore: !!formRawValue.documentoSupervisore.presenza,
            tipo_documento_supervisore: formRawValue.documentoSupervisore.presenza ? formRawValue.documentoSupervisore.tipo : undefined,
            is_obbligatorio: formRawValue.isObbligatorio ? !!formRawValue.isObbligatorio : false,
            is_obbligatorio_per_ogni_anno: false
        };
    }

    private getAddAttivitaExtraOffertaFormativaDto(formRawValue): AddAttivitaExtraOffertaFormativaDTO {
        return {
            denominazione: formRawValue.denominazione,
            ore: formRawValue.ore,
            cfu: formRawValue.cfu,
            descrizione: formRawValue.descrizione,
            eventuali_curriculum_di_riferimento_ids: formRawValue.curriculum,
            organizzatori: formRawValue.organizzatori,
            is_obbligatorio: formRawValue.isObbligatorio ? !!formRawValue.isObbligatorio : false,
        };
    }

    private getAddCorsoOffertaFormativaDto(formRawValue): AddCorsoOffertaFormativaDTO {
        return {
            denominazione: formRawValue.denominazione,
            ore: formRawValue.ore,
            cfu: formRawValue.cfu,
            descrizione: formRawValue.descrizione,
            eventuali_curriculum_di_riferimento_ids: formRawValue.curriculum,
            verifica_finale: formRawValue.verificaFinale ? !!formRawValue.verificaFinale : false,
            ssd: formRawValue.ssd,
            periodo_erogazione: formRawValue.periodoErogazione,
            codici_fiscali_docenti: formRawValue.docentiAll.docenti?.map(docente => docente.id),
            docenti_extra: formRawValue.docentiAll.docentiExtra.map(d => ({
                nome: d.nome,
                cognome: d.cognome
            })),
            is_obbligatorio: formRawValue.isObbligatorio ? !!formRawValue.isObbligatorio : false,
        };
    }

    private subscribeToGetCurriculums() {
        if(!this.dialogAddCourseService.curriculums?.length){
            this.fuseConfirmationService.showLoader()
            this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => this.cicliCorsiDiStudiService.getCicloCorsoDiStudiCurriculum()),
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: (value: Array<PercorsoDiStudiView>) => {
                        this.fuseConfirmationService.hideLoader();
                        this.dialogAddCourseService.curriculums = value;
                    },
                    error: (err) => {
                        this.dialogAddCourseService.curriculums = [];
                        this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                            () => this.dialogRef.close(),
                            () => this.subscribeToGetCurriculums(),
                            'dialog.cancel',
                            err?.error?.message);
                    }
                }
            );
        }

    }


    private formEnable() {
        this.form.enable({emitEvent: false});
        this.formGroupYears.enable({emitEvent: false});
    }

    checkFormInvalid(form: FormGroup) {
        if (form.invalid) {
            form.markAllAsTouched();
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'training_offer.select_the_type_of_modification', null),
                type: SnackbarTypes.Warning,
            });
        }
    }

    buildFormCourseExtraActivity(activityToEdit?: AttivitaOffertaFormativaInfoView): void {
        this.formExtraActivity = this.fb.group({
            denominazione: activityToEdit?.denominazione,
            ore: activityToEdit?.ore,
            cfu: activityToEdit?.cfu,
            descrizione: activityToEdit?.descrizione,
            curriculum: this.fb.control(activityToEdit?.percorsiDiStudi?.map(c => c.idPercorsoDiStudi) || []),
            organizzatori: this.fb.control(activityToEdit?.organizzatori || []/*, CustomValidators.OrganizatorValidator()*/),
            isObbligatorio: activityToEdit?.isObbligatorio
        });
        this.formExtraActivity.get('descrizione').addValidators(CustomValidators.noWhitespaceValidator());
        this.formExtraActivity.get('denominazione').addValidators(CustomValidators.noWhitespaceValidator());
    }

    buildFormCourseTransversalActivity(activityToEdit?: AttivitaOffertaFormativaInfoView): void {
        this.formTransversalActivity = this.fb.group({
            denominazione: activityToEdit?.denominazione,
            ore: activityToEdit?.ore,
            descrizioneCfu: activityToEdit?.descrizioneCfu,
            descrizione: activityToEdit?.descrizione,
            documentoDottorando: this.fb.group({
                presenza: activityToEdit?.presenzaDocumentoDottorando,
                tipo: activityToEdit?.tipoDocumentoDottorando,
            }, {validators: CustomValidators.PresenzaDocumentoValidator()}),
            documentoSupervisore: this.fb.group({
                presenza: activityToEdit?.presenzaDocumentoSupervisore,
                tipo: activityToEdit?.tipoDocumentoSupervisore,
            }, {validators: CustomValidators.PresenzaDocumentoValidator()}),
            presenzaDeliberaCoordinatore: activityToEdit?.presenzaDeliberaCoordinatore,
            isObbligatorio: activityToEdit?.isObbligatorio,
            //isObbligatorioPerOgniAnno: activityToEdit?.isObbligatorioPerOgniAnno,
        });
        this.formTransversalActivity.get('descrizione').addValidators(CustomValidators.noWhitespaceValidator());
        this.formTransversalActivity.get('denominazione').addValidators(CustomValidators.noWhitespaceValidator());
        this.formTransversalActivity.get('descrizioneCfu').addValidators(CustomValidators.noWhitespaceValidator());
    }


    private buildFormCourse(activityToEdit?: AttivitaOffertaFormativaInfoView) {
        this.formCourse = this.fb.group({
            denominazione: activityToEdit?.denominazione,
            ore: activityToEdit?.ore,
            cfu: activityToEdit?.cfu,
            descrizione: activityToEdit?.descrizione,
            curriculum: this.fb.control(activityToEdit?.percorsiDiStudi?.map(c => c.idPercorsoDiStudi) || []),
            ssd: activityToEdit?.ssd,
            periodoErogazione: activityToEdit?.periodoErogazione,
            verificaFinale: activityToEdit?.verificaFinale,
            docentiAll: this.fb.group({
                docenti: this.fb.control(activityToEdit?.docenti?.map(
                    d => ({...d, nomeCognome: (d.cognome || '') + ' ' + (d.nome || ''), id: d.codiceFiscale})
                ) || []),
                docentiExtra: this.fb.control(activityToEdit?.docentiExtraOffertaFormativa.map(
                    d => ({...d, nomeCognome: (d.cognome || '') + ' ' + (d.nome || '')})
                ) || []),
            }, {validators: CustomValidators.DocentiValidator()}), //this.data?.isOffertaPubblica ?
            isObbligatorio: activityToEdit?.isObbligatorio,
        });

        this.formCourse.get('denominazione').addValidators(CustomValidators.noWhitespaceValidator());

        this.formEnable();
    }

    saveChangeYears(forced: boolean = undefined) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (this.formGroupYears.invalid) {
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
        } else {
            this.showMiniLoader = true;
            const body: UpdateAnniDisponibilitaAttivitaPerCategoriaRequest = {
                primo_anno: this.formGroupYears.get('primoAnno').value,
                secondo_anno: this.formGroupYears.get('secondoAnno').value,
                terzo_anno: this.formGroupYears.get('terzoAnno').value,
                forced: forced
            };
            this.formGroupYears.disable();
            const successMessage = 'training_offer.edited_change_years';
            const errorMessage = 'training_offer.error_change_years'
            this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                filter(Boolean),
                take(1),
                switchMap(() => this.offerteFormativeService.updateAnniDisponibilitaAttivitaPerCategoria(body, this.data.idCategoria, this.data.activityToEdit?.attivitaOffertaFormativa.id)),
                takeUntil(this.destroy$),
                finalize(() => {
                    this.formEnable();
                    this.showMiniLoader = false;
                })
            ).subscribe({
                next: (offertaFormativaInfo: OffertaFormativaInfoViewImpl) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, successMessage, null),
                        type: SnackbarTypes.Success,
                    });
                    this.dialogRef.close(offertaFormativaInfo);
                },
                error: (err) => {
                    console.log(err);
                    if(err.status === 409 && err?.error?.code == 1034){
                        this.openInvalidStudyPlansDialog(err?.error?.utentiConPianoNonPiuValido, () => this.saveChangeYears(true));
                    } else if(err.status === 409 && err?.error?.code == 1023){
                        this.fuseConfirmationService.openErrorDialog(
                            {error: err},
                            this.translocoService,
                            () => {},
                            () => this.saveChangeYears(),
                            'dialog.cancel',
                            err?.error?.message
                        );
                    } else {
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, errorMessage, null),
                            type: SnackbarTypes.Error,
                        });
                    }
                }
            });
        }
    }

    private getRequest(body: UpdateCorsoOffertaFormativaDTO | UpdateAttivitaExtraOffertaFormativaDTO | UpdateAttivitaTrasversaleOffertaFormativaDTO, force = undefined) {
        switch (this.data.type) {
            case this.type.CORSO:
                return this.offerteFormativeService.updateCorsoOffertaFormativa(body as unknown as UpdateCorsoOffertaFormativaDTO, this.data.activityToEdit?.attivitaOffertaFormativa.id, force);
            case this.type.ATTIVITAEXTRA:
                return this.offerteFormativeService.updateAttivitaExtraOffertaFormativa(body as unknown as UpdateAttivitaExtraOffertaFormativaDTO, this.data.activityToEdit?.attivitaOffertaFormativa.id, force);
            case this.type.ATTIVITATRASVERSALE:
                return this.offerteFormativeService.updateAttivitaTrasversaleOffertaFormativa(body as unknown as UpdateAttivitaTrasversaleOffertaFormativaDTO, this.data.activityToEdit?.attivitaOffertaFormativa.id, force);
        }
    }

    private getBodyUpdate(formRawValue): UpdateCorsoOffertaFormativaDTO | UpdateAttivitaExtraOffertaFormativaDTO | UpdateAttivitaTrasversaleOffertaFormativaDTO {
        switch (this.data.type) {
            case this.type.CORSO:
                return this.getAddCorsoOffertaFormativaDto(formRawValue)
            case this.type.ATTIVITAEXTRA:
                return this.getAddAttivitaExtraOffertaFormativaDto(formRawValue)
            case this.type.ATTIVITATRASVERSALE:
                return this.getAddAttivitaTrasversaleOffertaFormativaDto(formRawValue)
        }

    }

    get typeCtrl(): FormControl{
        return this.firstFormGroup.get('type') as FormControl;
    }

    saveBySelect(forced = undefined) {

        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!this.selectActivityForm.valid || this.formGroupYears.invalid) {
            this.fuseConfirmationService.openSnackBar({
                message: get(translation, 'form.not_valid', null),
                type: SnackbarTypes.Warning,
            });
        } else {
            this.showMiniLoader = true;
            const formRawValue = this.selectActivityForm.value;
            console.log(formRawValue)
            const body = this.getBodySelect(formRawValue, forced);
            this.formGroupYears.disable();
            this.selectActivityForm.disable({emitEvent: false});
            const successMessage = 'training_offer.activity_added';
            const errorMessage = 'training_offer.error_adding_activity';

            if(this.typeCtrl.value === TypeModification.SELECT && this.activityInSameCategory){
                // call api to edit years if activity is already in this category
                this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                    filter(Boolean),
                    take(1),
                    switchMap(() => this.offerteFormativeService.updateAnniDisponibilitaAttivitaPerCategoria(body, this.data.idCategoria,this.selectActivityForm.get('array').value?.[0]?.key)),
                    takeUntil(this.destroy$),
                    finalize(() => {
                        this.formEnable();
                        this.showMiniLoader = false;
                    })
                ).subscribe({
                    next: (offertaFormativaInfo: OffertaFormativaInfoViewImpl) => {
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, successMessage, null),
                            type: SnackbarTypes.Success,
                        });
                        this.dialogRef.close(offertaFormativaInfo);
                    },
                    error: (err) => {
                        console.log(err)
                        if(err.status === 409 && err?.error?.code == 1034){
                            this.openInvalidStudyPlansDialog(err?.error?.utentiConPianoNonPiuValido, () => this.saveBySelect(true));
                        } else if(err.status === 409 && err?.error?.code == 1023){
                            this.fuseConfirmationService.openErrorDialog(
                                {error: err},
                                this.translocoService,
                                () => {},
                                () => this.saveBySelect(),
                                'dialog.cancel',
                                err?.error?.message
                            );
                        } else {
                            this.fuseConfirmationService.openSnackBar({
                                message: get(translation, errorMessage, null),
                                type: SnackbarTypes.Error,
                            });
                        }
                    }
                });
            } else {
                this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
                    filter(Boolean),
                    take(1),
                    switchMap(() => this.offerteFormativeService.addAttivitaACategoriaOffertaFormativa(body as unknown as AddAttivitaACategoriaOffertaFormativaRequest, this.data.idCategoria)),
                    takeUntil(this.destroy$),
                    finalize(() => {
                        this.selectActivityForm.enable();
                        this.showMiniLoader = false;
                    })
                ).subscribe({
                    next: (offertaFormativaInfo: OffertaFormativaInfoViewImpl) => {
                        console.log(offertaFormativaInfo)
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, successMessage, null),
                            type: SnackbarTypes.Success,
                        });
                        this.dialogRef.close(offertaFormativaInfo);
                    },
                    error: (err) => {
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, errorMessage, null),
                            type: SnackbarTypes.Error,
                        });
                    }
                });
            }
        }
    }

    private getBodySelect(formRawValue, forced = undefined) {
        switch (this.data.type) {
            case this.type.CORSO:
                return {
                    tipo_attivita: this.data.type,
                    primo_anno: this.formGroupYears.get('primoAnno').value,
                    secondo_anno: this.formGroupYears.get('secondoAnno').value,
                    terzo_anno: this.formGroupYears.get('terzoAnno').value,
                    add_corso_offerta_formativa_dto: {
                        id: formRawValue.array[0].key
                    },
                    forced: forced
                };
            case this.type.ATTIVITAEXTRA:
                return {
                    tipo_attivita: this.data.type,
                    primo_anno: this.formGroupYears.get('primoAnno').value,
                    secondo_anno: this.formGroupYears.get('secondoAnno').value,
                    terzo_anno: this.formGroupYears.get('terzoAnno').value,
                    add_attivita_extra_offerta_formativa_dto:  {
                        id: formRawValue.array[0].key
                    },
                    forced: forced
                };
            case this.type.ATTIVITATRASVERSALE:
                return {
                    tipo_attivita: this.data.type,
                    primo_anno: this.formGroupYears.get('primoAnno').value,
                    secondo_anno: this.formGroupYears.get('secondoAnno').value,
                    terzo_anno: this.formGroupYears.get('terzoAnno').value,
                    add_attivita_trasversale_offerta_formativa_dto:  {
                        id: formRawValue.array[0].key,

                    },
                    forced: forced
                }
        }

    }

    protected readonly ButtonType = ButtonType;

    private openInvalidStudyPlansDialog(utentiConPianoNonPiuValido: any[],
                                        confirmCallback: () => void) {
        const data: DialogInvalidStudyPlansComponentI = {
            utentiConPianoNonPiuValido: utentiConPianoNonPiuValido,
            icon: {
                name: 'warning_amber',
                color: 'warning'
            },
            onConfirm: (dialogRef2) =>{
                dialogRef2?.close();
                confirmCallback();
            }
        };
        this.dialog.open(DialogInvalidStudyPlansComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            autoFocus: false,
            disableClose: true,
            width: '80vw',
        });
    }
}
