<ng-container *transloco="let t">
    <div class="container justify-content_center" [style]="_configuration?.css">
        <div *ngIf="isSelectable"
             class="py-2 px-2 rounded-lg bg-gray-500 dark:bg-gray-800 ng-star-inserted w-full">
            <mat-chip-list>
                <div *ngIf="(righeSelezionate !== undefined && righeSelezionate.length > 0); else noSelezione"
                     class="table-selection grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full">
                    <ng-container *ngFor="let riga of righeSelezionate">
                        <div class="flex flex-col w-full items-center">
                            <mat-chip color="primary" class="mat-chip-multiselection flex flex-row justify-between">
                                <span *ngIf="selectedColumnKeyToShow"
                                      class="line-clamp-1">{{riga.data[selectedColumnKeyToShow] | uppercase}}</span>
                                <button [style]="'padding-left: 5px'" [disabled]="readOnly"
                                        (click)="toggleSelectedRowData(riga.data)">
                                    <mat-icon [style]="'margin-top: 5px'">delete</mat-icon>
                                </button>
                            </mat-chip>
                        </div>
                    </ng-container>
                </div>
                <ng-template #noSelezione>
                    <div class="w-full table-no-selection" fxLayout="column" fxLayoutAlign="center center"
                         fxLayoutGap="10px">
                        <span>{{t("common.no_elements")}}</span>
                    </div>
                </ng-template>
            </mat-chip-list>
        </div>

        <mat-table #table [dataSource]="dataSourcePaginated" matSort
                   (matSortChange)="onSortChange($event)"
                   [style]="!!_configuration?.cssMatTable ? _configuration?.cssMatTable : _configuration?.css">

            <ng-container *ngFor="let colonna of _configuration.configurazioneTabella"
                          matColumnDef="{{colonna.colonnaKey}}">
                <mat-header-cell *matHeaderCellDef [fxFlex]="colonna?.flex" mat-sort-header
                                 [disabled]="!colonna.enableSort"
                                 fxHide.md class="justify-content_center" [ngStyle]="_configuration?.css"> {{
                    t((colonna.nomeColonna || colonna.colonnaKey)) |  customTitleCase: colonna.uppercase | removeUnderscores}} </mat-header-cell>

                <mat-cell *matCellDef="let element; let idx = index" class="justify-content_center "
                          [fxFlex]="colonna?.flex"
                          [ngStyle]="_configuration?.css">

                    <ng-container [ngSwitch]="colonna.tipo">
                    <span [ngStyle]="_configuration?.css"
                          class="mobile-label"> {{t((colonna.nomeColonna || colonna.colonnaKey)) |  customTitleCase: colonna.uppercase | removeUnderscores}}</span>

                        <ng-container *ngSwitchCase="tipoColonna.SELECTION">
                            <ng-container>
                                <mat-checkbox class="m-1" color="primary" (change)="toggleSelectedRowData(element)"
                                              [checked]="checkIfSelected(element[selectedColumnKeyPrimaryKey])"
                                              [disabled]="readOnly || checkIfRigaReadOnly(element)"></mat-checkbox>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ICON">
                            <ng-container *ngFor="let icon of colonna.icon">
                                <mat-icon
                                        *ngIf="!icon.showIf || icon.showIf(element[colonna.colonnaKey])"
                                        [color]="icon.colorIcon"
                                        [matTooltip]="(icon.tootlip || '') | customTitleCase: colonna.uppercase  | removeUnderscores">{{icon.nameIcon}}</mat-icon>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="tipoColonna.STRING">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                <div style="  text-align: center;   white-space: pre-line;"
                                     *ngIf="(colonna.colonnaKey === 'email' || colonna.colonnaKey === 'username'); else showValue">
                                    <span class="mobile-text" style="word-break: break-word;">{{value}}  </span>
                                </div>
                                <ng-template #showValue>
                            <span style="  text-align: center;   white-space: pre-line; word-break: break-word;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value  | customTitleCase: colonna.uppercase | removeUnderscores}}</span>
                                </ng-template>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.NUMBER">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="  text-align: center;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value}}</span>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.HREF">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="text-align: center;"
                                  (click)="goTo(colonna, element, colonna.colonnaKey)"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text cursor-pointer">
                                {{ value}}</span>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.AMOUNT">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="  text-align: center;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value | currencyEuro}}</span>
                            </ng-container>
                        </ng-container>


                        <ng-container *ngSwitchCase="tipoColonna.TEXT_BUTTON">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div><span style="  text-align: center;   white-space: pre-line"
                                               *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                               class="mobile-text"
                                    >
                                {{ value}}</span>
                                    </div>

                                    <mat-icon matTooltip="Visualizza in tabella" *ngIf="!!value"
                                              style="margin-left: 5px"
                                              class="cursor-pointer icon-size-4"
                                              (click)="colonna.dialog.openDialog(element[colonna.dialog.fieldValueDialog])">
                                        open_in_new
                                    </mat-icon>
                                </div>


                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="tipoColonna.BOOLEAN">
                            <ng-container *ngIf="!!colonna.convertiValoreBooleanInIcon">
                                <mat-icon
                                        *ngIf="convertiValoreInBo(colonna, element[colonna.colonnaKey]) as iconConfig"
                                        [color]="iconConfig.colorIcon"
                                        [matTooltip]="(iconConfig.tootlip || '') | customTitleCase: colonna.uppercase | removeUnderscores">{{iconConfig.nameIcon}}</mat-icon>
                            </ng-container>
                            <ng-container *ngIf="!!colonna.convertiValoreBoolean">
                                <span
                                        class="mobile-text">{{ t(getValue(colonna, element[colonna.colonnaKey]))| customTitleCase: colonna.uppercase | removeUnderscores}}</span>
                            </ng-container>


                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ACTION">
                            <div fxLayout="column" fxLayoutAlign="center center" style="width: 100%"
                                 [class.custom-button]="_configuration.customButton">
                                <div fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%">
                                    <ng-container
                                            *ngFor="let button of getButton(colonna, element[colonna.valueKeyForFuction || colonna.colonnaKey])">
                                        <ng-container *ngIf="!button.hide">
                                            <button *ngIf="show(button, element, button?.valueShow) && !button.isIcon &&
                                            checkForFormButtons(button, element)"
                                                    style="margin: 2px; box-shadow: none"
                                                    [color]="button.color" mat-mini-fab
                                                    [matTooltip]="t(button.tooltip) || ''"
                                                    (click)="checkFormAction(element, {tipoClick: button.click, index: idx, value: element});
                                                    clickAction.emit({tipoClick: button.click, index: idx, value: element})">
                                                <mat-icon>{{button.nameIconButton}}</mat-icon>
                                            </button>

                                            <mat-icon *ngIf="button.isIcon && show(button, element, button?.valueShow)"
                                                      [color]="button.color"
                                                      [matTooltip]="button?.iconTooltip || button?.tooltip">{{button.nameIconButton}}</mat-icon>
                                        </ng-container>

                                    </ng-container>
                                </div>

                            </div>

                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.ACTION_MENU">
                            <button style="box-shadow: unset !important;"
                                    *ngIf="!colonna?.show || colonna?.show(element[colonna.valueCheck || colonna.colonnaKey], element)"
                                    color="accent"
                                    mat-mini-fab
                                    [matMenuTriggerFor]="action"
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu class="custom-mat-menu-action" [xPosition]="'after'" #action="matMenu">

                                <ng-container
                                        *ngFor="let button of getButton(colonna, element[colonna.valueKeyForFuction || colonna.colonnaKey])">
                                    <ng-container *ngIf="!button.hide">
                                        <button *ngIf="show(button, element, button?.valueShow) && !button.isIcon &&
                                                        checkForFormButtons(button, element)"
                                                style="margin: 2px"
                                                mat-menu-item
                                                (click)="checkFormAction(element, {tipoClick: button.click, index: idx, value: element});
                                                clickAction.emit({tipoClick: button.click, index: idx, value: element}); ; closeMenus();">
                                            <mat-icon>{{button.nameIconButton}}</mat-icon>
                                            <span class="ml-2">{{t(button.tooltip) || ''}}</span>
                                        </button>

                                        <mat-icon *ngIf="button.isIcon && show(button, element, button?.valueShow)"
                                                  [color]="button.color"
                                                  [matTooltip]="button?.iconTooltip || button?.tooltip">{{button.nameIconButton}}</mat-icon>
                                    </ng-container>

                                </ng-container>

                            </mat-menu>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.CHIP_USER">
                            <ng-container *ngIf="getValueForChip(colonna, element, colonna.colonnaKey) as chipList">

                                <mat-chip-list [style]="'width:93%'">
                                    <div class="chips-container w-full justify-center items-center">
                                        <ng-container *ngFor="let chipItem of chipList;">
                                            <mat-chip
                                                    [ngClass]="colonna.isChipDisabled || !chipItem.codiceFiscale ? 'pointer-events-none' : 'cursor-pointer'"
                                                    (click)="clickAction.emit({tipoClick: chipClickType, index: idx, value: chipItem})"
                                                    class="chip-width user-chip justify-between"
                                                    color="primary">
                                                <span>{{buildNomeCompleto(chipItem)}}</span>
                                                <mat-icon *ngIf="!colonna.isChipDisabled && !!chipItem.codiceFiscale">
                                                    info
                                                </mat-icon>
                                            </mat-chip>
                                        </ng-container>
                                    </div>
                                </mat-chip-list>

                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.CHIP_STATUS">
                            <ng-container *ngIf="getValueForStatus(colonna, element, colonna.colonnaKey) as chipStatus">
                                <mat-chip-list [style]="'width:93%'">
                                    <div class="chips-container w-full justify-center items-center">
                                        <mat-chip [class]="chipStatus | statusEnumToColorTheme: colonna.statusType"
                                                  [matTooltip]="t(chipStatus | statusEnumToLanguagePath: colonna.statusType)"
                                                  class="chip-width flex flex-row justify-center">
                                            <span
                                                    class="line-clamp-1">{{t(chipStatus | statusEnumToLanguagePath: colonna.statusType)}}</span>
                                        </mat-chip>
                                    </div>
                                </mat-chip-list>
                            </ng-container>
                        </ng-container>

                        <!----------- MARK: PARTE CON FORM CONTROL -------------->
                        <ng-container *ngSwitchCase="tipoColonna.CHECKBOX">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                <mat-checkbox class="m-1" color="primary"
                                              [checked]="!!element[colonna?.colonnaKey]"
                                              [disabled]="checkReadOnlyValue(element)"
                                              [formControl]="getFormControlByColonnaKey(element, colonna.colonnaKey)"></mat-checkbox>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.INPUT_NUMBER">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                <ng-container *ngIf="!checkReadOnlyValue(element); else numberTemplate">
                                    <mat-form-field appearance="fill" class="input-amount-mat-form-field">
                                        <ng-container *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])">
                                            <input matInput type="number" style="text-align: center;"
                                                   *ngIf="!colonna?.onlyPositiveWithOneDecimal; else positiveWithOneDecimalTemplate"
                                                   class="mobile-text"
                                                   [value]="value"
                                                   min="1"
                                                   oninput="value = !!value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                                                   [ngClass]="checkReadOnlyValue(element) ? 'disabled' : ''"
                                                   [formControl]="getFormControlByColonnaKey(element, colonna.colonnaKey)">
                                            <ng-template #positiveWithOneDecimalTemplate>
                                                <input matInput
                                                       type="number"
                                                       style="text-align: center;"
                                                       appOnlyDecimalNumbers
                                                       [numOfDecimal]="1"
                                                       class="mobile-text"
                                                       [value]="value"
                                                       [min]="0.1"
                                                       [ngClass]="checkReadOnlyValue(element) ? 'disabled' : ''"
                                                       [formControl]="getFormControlByColonnaKey(element, colonna.colonnaKey)">
                                            </ng-template>
                                        </ng-container>
                                    </mat-form-field>
                                </ng-container>
                                <ng-template #numberTemplate>
                                      <span style="  text-align: center;"
                                            *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                            class="mobile-text">
                                {{getFormControlByColonnaKey(element, colonna.colonnaKey)?.value}}</span>
                                </ng-template>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.INPUT_TEXT">
                            <ng-container> <!--SHOW EVEN IF THE VALUE IS EMPTY-->
                                <ng-container *ngIf="!checkReadOnlyValue(element); else readonlyTemplate">
                                    <mat-form-field appearance="fill" class="w-full mt-[0.9rem]">
                                        <ng-container *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])">
                                            <input matInput style="text-align: center;"
                                                   [value]="getValueShow(colonna, element, colonna.colonnaKey)"
                                                   [ngClass]="checkReadOnlyValue(element) ? 'disabled' : ''"
                                                   [formControl]="getFormControlByColonnaKey(element, colonna.colonnaKey)">
                                        </ng-container>
                                    </mat-form-field>
                                </ng-container>
                                <ng-template #readonlyTemplate>
                                      <span style="  text-align: center;"
                                            *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])">
                                {{getFormControlByColonnaKey(element, colonna.colonnaKey)?.value}}</span>
                                </ng-template>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.INPUT_AMOUNT">
                            <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                <ng-container *ngIf="!checkReadOnlyValue(element); else numberTemplate">
                                    <mat-form-field appearance="fill" class="input-amount-mat-form-field">
                                        <ng-container *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])">
                                            <input matInput
                                                   style="text-align: center;"
                                                   class="mobile-text"
                                                   [value]="value"
                                                   currencyMask
                                                   [options]="{ prefix: '',  suffix: ' €',
                                                   allowNegative: false,
                                                   thousands: '.', decimal: ',', align: 'left' }"
                                                   [ngClass]="checkReadOnlyValue(element) ? 'disabled' : ''"
                                                   [formControl]="getFormControlByColonnaKey(element, colonna.colonnaKey)">
                                        </ng-container>
                                    </mat-form-field>
                                </ng-container>
                                <ng-template #numberTemplate>
                                      <span style="  text-align: center;"
                                            currencyMask
                                            [options]="{ prefix: '',  suffix: ' €',
                                            allowNegative: false,
                                            thousands: '.', decimal: ',', align: 'left' }"
                                            *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                            class="mobile-text">
                                {{getFormControlByColonnaKey(element, colonna.colonnaKey)?.value}}</span>
                                </ng-template>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngSwitchCase="tipoColonna.INPUT_DATE">
                            <ng-container *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])">
                                <ng-container *ngIf="getFormControlByColonnaKey(element, colonna.colonnaKey) as formCtrl">

                                    <ng-container *ngIf="!checkReadOnlyValue(element); else dateReadOnly">
                                        <mat-form-field class="w-full mat-button-datapicker add-mobilita-mat-form-field mt-1" appearance="outline">
                                            <input matInput
                                                   [value]="dateValue(formCtrl)"
                                                   readonly
                                                   [formControl]="formCtrl"
                                                   (dateInput)="dateEvent($event, formCtrl, colonna.formControl?.required)"
                                                   [matDatepicker]="idx">
                                            <mat-datepicker-toggle matSuffix [for]="idx"></mat-datepicker-toggle>
                                            <mat-datepicker #idx></mat-datepicker>
                                            <mat-icon matSuffix class="cursor-pointer pr-1"
                                                      (click)="clearDateField(formCtrl)"
                                                      *ngIf="formCtrl.value !== '' && !!formCtrl.value"
                                                      color="warn"
                                                      svgIcon="mat_outline:cancel"></mat-icon>
                                            <mat-error *ngIf="formCtrl.hasError('required')">
                                                {{t('form.required')}}
                                            </mat-error>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-template #dateReadOnly>
                                        <mat-form-field class="w-full mat-button-datapicker" appearance="outline">
                                            <input matInput
                                                   [value]="getValueShow(colonna, element, colonna.colonnaKey)"
                                                   readonly
                                                   [disabled]="true"
                                                   [formControl]="formCtrl">
                                        </mat-form-field>

                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </ng-container>


                        <!----------- ENDMARK: PARTE CON FORM CONTROL -------------->
                    </ng-container>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: _configuration.sticky"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
                     [ngClass]="{'bg-slate-100': i % 2 == 0}"></mat-row>
            <tr class="mat-row margin" *matNoDataRow fxLayout="row" fxLayoutAlign="center center">
                <td class="mat-cell justify-content_center" [attr.colspan]=displayedColumns.length>
                    <br>{{t(_configuration?.messaggioDatiAssenti) || messaggioDatiAssenti}}<br>
                </td>
            </tr>


        </mat-table>

        <div *ngIf="!_configuration?.hidePaginator && this.dataSourcePaginated.data.length > 0" class="paginator"
             fxLayout="row" fxLayoutAlign="end end"
             [ngStyle]="!!_configuration?.cssMatPaginator ? _configuration?.cssMatPaginator : _configuration?.css">
            <mat-paginator [length]="totalElements" [pageSize]="_configuration?.pageSize || 25"
                           [ngStyle]="_configuration?.css"
                           [pageSizeOptions]="_configuration?.pageSizeOptions || [25, 50, 100]" showFirstLastButtons
                           (page)="onPageChange($event)">
            </mat-paginator>
        </div>
    </div>
</ng-container>




