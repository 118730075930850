<ng-container *transloco="let t">


    <app-dialog-wrap [data]="data"
                     [class.readonly-all]="showMiniLoader"
                     (close$)="closeDialog()"
    >
        <form class="w-full" [formGroup]="formCourse"
              (ngSubmit)="save()">

            <div class="flex flex-col py-2 gap-2 w-full">


                <mat-stepper class="w-full sticky-stepper" [linear]="true" #stepper>

                    <mat-step [stepControl]="firstFormGroup">
                        <form [formGroup]="firstFormGroup">
                            <ng-template matStepLabel>{{ t('training_offer.select_the_type_of_modification') }}
                            </ng-template>

                            <ng-container *ngIf="data.activityToEdit">
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full min-w-0">

                                    <div
                                        class="flex flex-col flex-auto p-10 m-2  shadow rounded-2xl overflow-hidden cursor-pointer click bg-gray-50"
                                        [class.bg-indigo-50]="typeCtrl.value === typeModification.YEARS"
                                        [class.text-indigo-800]="typeCtrl.value === typeModification.YEARS"
                                        (click)="typeCtrl.setValue(typeModification.YEARS)"
                                    >
                                        <div class="flex flex-col items-center text-center mt-2">
                                            <div class="font-bold tracking-tight leading-none ">
                                                <mat-icon class="icon-size-12">pin</mat-icon>
                                            </div>
                                            <div
                                                class="text-lg font-medium ">{{ t('training_offer.edit_year_of_activity') }}
                                            </div>
                                            <div
                                                class="text-md mt-2"> {{ t('training_offer.edit_year_of_activity_message') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="flex flex-col flex-auto p-10 m-2  shadow rounded-2xl overflow-hidden cursor-pointer click bg-gray-50"
                                        [class.bg-indigo-50]="typeCtrl.value === typeModification.ACTIVITY"
                                        [class.text-indigo-800]="typeCtrl.value === typeModification.ACTIVITY"
                                        (click)="typeCtrl.setValue(typeModification.ACTIVITY)"
                                    >
                                        <div class="flex flex-col items-center  text-center mt-2">
                                            <div class="font-bold tracking-tight leading-none ">
                                                <mat-icon class="icon-size-12">edit_note</mat-icon>
                                            </div>
                                            <div
                                                class="text-lg font-medium ">{{ t('training_offer.edit_global_activity_information') }}
                                            </div>
                                            <div
                                                class="text-md mt-2"> {{ t('training_offer.edit_global_activity_information_message') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>
                            <ng-container *ngIf="!data.activityToEdit">
                                <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full min-w-0">

                                    <div
                                        class="flex flex-col flex-auto p-10 m-2 shadow rounded-2xl overflow-hidden cursor-pointer click bg-gray-50"
                                        [class.bg-indigo-50]="typeCtrl.value === typeModification.NEW"
                                        [class.text-indigo-800]="typeCtrl.value === typeModification.NEW"
                                        (click)="typeCtrl.setValue(typeModification.NEW)"
                                    >
                                        <div class="flex flex-col items-center text-center
                                             mt-2">
                                            <div class="font-bold tracking-tight leading-none ">
                                                <mat-icon class="icon-size-12">add_circle</mat-icon>
                                            </div>
                                            <div
                                                class="text-lg font-medium ">{{ t('training_offer.creating_and_adding_a_new_activity') }}
                                            </div>
                                            <div
                                                class="text-md mt-2"> {{ t('training_offer.creating_and_adding_a_new_activity_message') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="flex flex-col flex-auto p-10 m-2 shadow rounded-2xl overflow-hidden cursor-pointer click bg-gray-50"
                                        [class.bg-indigo-50]="typeCtrl.value === typeModification.SELECT"
                                        [class.text-indigo-800]="typeCtrl.value === typeModification.SELECT"
                                        (click)="typeCtrl.setValue(typeModification.SELECT)"
                                    >
                                        <div class="flex flex-col items-center text-center mt-2">
                                            <div class="font-bold tracking-tight leading-none ">
                                                <mat-icon class="icon-size-12">check_box</mat-icon>
                                            </div>
                                            <div
                                                class="text-lg font-medium "> {{ t('training_offer.selecting_and_adding_an_existing_activity') }}
                                            </div>
                                            <div
                                                class="text-md mt-2"> {{ t('training_offer.selecting_and_adding_an_existing_activity_message') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-container>

                            <div class="flex flex-row justify-end w-full div-button-sticky">
                                <app-custom-button
                                    [type]="ButtonType.NEXT"
                                    (clickHandler)="checkFormInvalid(firstFormGroup)"
                                >
                                </app-custom-button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="formGroupYears"
                              *ngIf="firstFormGroup.get('type').value === typeModification.YEARS">
                        <form [formGroup]="formGroupYears">
                            <ng-template matStepLabel>{{ t('training_offer.edit_year_of_activity') }}</ng-template>
                            <ng-container *ngIf="data.editYearsTopAlertTextLabel">
                                <fuse-alert
                                    class="mb-3"
                                    [appearance]="'outline'"
                                    [type]="'warning'">
                                    {{ t(data.editYearsTopAlertTextLabel) }}
                                </fuse-alert>
                            </ng-container>
                            <div class="flex flex-col gap-3 items-center mb-2 years-div">
                                <div class="w-full flex flex-col break-words gap-1"
                                     style="word-break: break-word;">
                                    <div class="font-semibold w-full text-center">
                                        {{ t('dialog_add_edit_categories.denomination') }}
                                    </div>
                                    <div class="text-center w-full">{{form.get('denominazione').value}}</div>
                                </div>
                                <fuse-alert
                                    class="my-3 w-full"
                                    [appearance]="'outline'"
                                    [type]="'info'">
                                    {{ t('training_offer.years_displayed_depends_on_category_config') }}
                                </fuse-alert>

                                <div class="text-center text-sm">
                                    {{ t('training_offer.select_activity_years') }}
                                </div>

                                <div class="flex flex-row gap-6 items-center">

                                    <ng-container *ngIf="showPrimoAnno">
                                        <div class="flex flex-row items-center"
                                             *ngIf="formGroupYears.get('primoAnno') as fieldCtrl">
                                            <mat-checkbox [formControlName]="'primoAnno'"
                                                          color="primary">
                                            </mat-checkbox>
                                            <mat-label><strong>{{ t('study_plan_status.first_year') }}</strong>
                                            </mat-label>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="showSecondoAnno">
                                        <div class="flex flex-row items-center"
                                             *ngIf="formGroupYears.get('secondoAnno') as fieldCtrl">
                                            <mat-checkbox [formControlName]="'secondoAnno'"
                                                          color="primary">
                                            </mat-checkbox>
                                            <mat-label><strong>{{ t('study_plan_status.second_year') }}</strong>
                                            </mat-label>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="showTerzoAnno">
                                        <div class="flex flex-row items-center"
                                             *ngIf="formGroupYears.get('terzoAnno') as fieldCtrl">
                                            <mat-checkbox [formControlName]="'terzoAnno'"
                                                          color="primary">
                                            </mat-checkbox>
                                            <mat-label><strong>{{ t('study_plan_status.third_year') }}</strong>
                                            </mat-label>
                                        </div>
                                    </ng-container>

                                </div>

                                <mat-error
                                    *ngIf="formGroupYears.errors?.yearsRequired && (formGroupYears?.dirty || formGroupYears?.touched)">
                                    {{ t('training_offer.year_required') }}
                                </mat-error>

                            </div>
                            <div class="flex flex-row justify-between w-full div-button-sticky">
                                <app-custom-button
                                    [disabled]="showMiniLoader"
                                    [type]="ButtonType.PREVIOUS"
                                >
                                </app-custom-button>
                                <app-custom-button
                                    [loading]="showMiniLoader"
                                    (clickHandler)="saveChangeYears()"
                                    [type]="ButtonType.CONFIRM"
                                >
                                </app-custom-button>
                            </div>
                        </form>
                    </mat-step>
                    <ng-container *ngIf="firstFormGroup.get('type').value === typeModification.NEW">
                        <mat-step [stepControl]="form">
                            <form [formGroup]="form">
                                <div class="w-full flex flex-col max-h-[60vh]">
                                    <ng-template matStepLabel>{{ t('training_offer.enter_activity_information') }}
                                    </ng-template>
                                    <ng-container *ngIf="data.editGlobalTopAlertTextLabel">
                                        <fuse-alert
                                            class="mb-3"
                                            [appearance]="'outline'"
                                            [type]="'warning'">
                                            {{ t(data.editGlobalTopAlertTextLabel) }}
                                        </fuse-alert>
                                    </ng-container>
                                    <fuse-alert
                                        class="my-3"
                                        [appearance]="'outline'"
                                        [type]="'info'">
                                        {{ t('training_offer.edit_global_activity_information_message') }}
                                    </fuse-alert>

                                    <ng-container [ngSwitch]="data.type">

                                        <ng-container *ngSwitchCase="type.CORSO">
                                            <app-course-form [form]="formCourse"
                                                             [data]="data"
                                                             [curriculums]="dialogAddCourseService.curriculums"
                                            ></app-course-form>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="type.ATTIVITAEXTRA">
                                            <app-extra-activity-form
                                                *ngIf="formExtraActivity"
                                                [form]="formExtraActivity"
                                                [data]="data"
                                                [curriculums]="dialogAddCourseService.curriculums"
                                            ></app-extra-activity-form>
                                        </ng-container>

                                        <ng-container *ngSwitchCase="type.ATTIVITATRASVERSALE">
                                            <app-transversal-activity-form
                                                *ngIf="formTransversalActivity"
                                                [form]="formTransversalActivity"
                                                [data]="data"
                                                [curriculums]="dialogAddCourseService.curriculums"
                                            ></app-transversal-activity-form>
                                        </ng-container>


                                    </ng-container>
                                    <div class="flex flex-row justify-between w-full div-button-sticky">
                                        <app-custom-button
                                            [type]="ButtonType.PREVIOUS"
                                        >
                                        </app-custom-button>
                                        <app-custom-button
                                            (clickHandler)="checkFormInvalid(form)"
                                            [type]="ButtonType.NEXT"
                                        >
                                        </app-custom-button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="formGroupYears">
                            <form [formGroup]="formGroupYears">
                                <ng-template matStepLabel>{{ t('training_offer.year_of_activity') }}</ng-template>
                                <div class="flex flex-col gap-3 items-center mb-2 years-div">
                                    <div class="w-full flex flex-col break-words gap-1"
                                         style="word-break: break-word;">
                                        <div class="font-semibold w-full text-center">
                                            {{ t('dialog_add_edit_categories.denomination') }}
                                        </div>
                                        <div class="text-center w-full">{{form.get('denominazione').value}}</div>
                                    </div>
                                    <div class="text-center text-sm">
                                        {{ t('training_offer.select_activity_years') }}
                                    </div>

                                    <div class="flex flex-row gap-6 items-center">
                                        <ng-container *ngIf="showPrimoAnno">
                                            <div class="flex flex-row items-center"
                                                 *ngIf="formGroupYears.get('primoAnno') as fieldCtrl">
                                                <mat-checkbox [formControlName]="'primoAnno'"
                                                              color="primary">
                                                </mat-checkbox>
                                                <mat-label><strong>{{ t('study_plan_status.first_year') }}</strong>
                                                </mat-label>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="showSecondoAnno">
                                            <div class="flex flex-row items-center"
                                                 *ngIf="formGroupYears.get('secondoAnno') as fieldCtrl">
                                                <mat-checkbox [formControlName]="'secondoAnno'"
                                                              color="primary">
                                                </mat-checkbox>
                                                <mat-label><strong>{{ t('study_plan_status.second_year') }}</strong>
                                                </mat-label>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="showTerzoAnno">
                                            <div class="flex flex-row items-center"
                                                 *ngIf="formGroupYears.get('terzoAnno') as fieldCtrl">
                                                <mat-checkbox [formControlName]="'terzoAnno'"
                                                              color="primary">
                                                </mat-checkbox>
                                                <mat-label><strong>{{ t('study_plan_status.third_year') }}</strong>
                                                </mat-label>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <mat-error
                                        *ngIf="formGroupYears.errors?.yearsRequired && (formCourse?.dirty || formGroupYears?.touched)">
                                        {{ t('training_offer.year_required') }}
                                    </mat-error>

                                </div>
                                <div class="flex flex-row justify-between w-full div-button-sticky">
                                    <app-custom-button
                                        [disabled]="showMiniLoader"
                                        [type]="ButtonType.PREVIOUS"
                                    >
                                    </app-custom-button>
                                    <app-custom-button
                                        [loading]="showMiniLoader"
                                        (clickHandler)="save()"
                                        [type]="ButtonType.CONFIRM"
                                    >
                                    </app-custom-button>
                                </div>
                            </form>
                        </mat-step>
                    </ng-container>
                    <ng-container *ngIf="firstFormGroup.get('type').value === typeModification.SELECT">
                        <mat-step [stepControl]="selectActivityForm">
                            <form [formGroup]="selectActivityForm">
                                <ng-template matStepLabel>{{ t('training_offer.select_activity') }}
                                </ng-template>
                                <app-select-activity
                                    [form]="selectActivityForm"
                                    [type]="data.type"
                                    [filteredCourses]="data.filteredCourses"
                                ></app-select-activity>

                                <div class="flex flex-row justify-between w-full div-button-sticky">
                                    <app-custom-button
                                        [type]="ButtonType.PREVIOUS"
                                    >
                                    </app-custom-button>
                                    <app-custom-button
                                        [type]="ButtonType.NEXT"
                                    >
                                    </app-custom-button>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="formGroupYears">
                            <form [formGroup]="formGroupYears">
                                <ng-template matStepLabel>{{ t('training_offer.year_of_activity') }}</ng-template>
                                <div class="flex flex-col gap-3 items-center mb-2 years-div">

                                    <div class="w-full flex flex-col break-words gap-1"
                                         style="word-break: break-word;">
                                        <div class="font-semibold w-full text-center">
                                            {{ t('dialog_add_edit_categories.denomination') }}
                                        </div>
                                        <div class="text-center w-full">{{denomination}}</div>
                                    </div>

                                    <fuse-alert
                                        class="my-3 w-full"
                                        [appearance]="'outline'"
                                        [type]="'info'">
                                        {{ t('training_offer.years_displayed_depends_on_category_config') }}
                                    </fuse-alert>

                                    <div class="text-center text-sm">
                                        {{ t('training_offer.select_activity_years') }}
                                    </div>


                                    <div class="flex flex-row gap-6 items-center">
                                        <ng-container *ngIf="showPrimoAnno">
                                            <div class="flex flex-row items-center"
                                                 *ngIf="formGroupYears.get('primoAnno') as fieldCtrl">
                                                <mat-checkbox [formControlName]="'primoAnno'"
                                                              color="primary">
                                                </mat-checkbox>
                                                <mat-label><strong>{{ t('study_plan_status.first_year') }}</strong>
                                                </mat-label>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="showSecondoAnno">
                                            <div class="flex flex-row items-center"
                                                 *ngIf="formGroupYears.get('secondoAnno') as fieldCtrl">
                                                <mat-checkbox [formControlName]="'secondoAnno'"
                                                              color="primary">
                                                </mat-checkbox>
                                                <mat-label><strong>{{ t('study_plan_status.second_year') }}</strong>
                                                </mat-label>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="showTerzoAnno">
                                            <div class="flex flex-row items-center"
                                                 *ngIf="formGroupYears.get('terzoAnno') as fieldCtrl">
                                                <mat-checkbox [formControlName]="'terzoAnno'"
                                                              color="primary">
                                                </mat-checkbox>
                                                <mat-label><strong>{{ t('study_plan_status.third_year') }}</strong>
                                                </mat-label>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <mat-error
                                        *ngIf="formGroupYears.errors?.yearsRequired && (formCourse?.dirty || formGroupYears?.touched)">
                                        {{ t('training_offer.year_required') }}
                                    </mat-error>

                                </div>
                                <div class="flex flex-row justify-between w-full div-button-sticky">
                                    <app-custom-button
                                        [disabled]="showMiniLoader"
                                        [type]="ButtonType.PREVIOUS"
                                    >
                                    </app-custom-button>
                                    <app-custom-button
                                        [loading]="showMiniLoader"
                                        (clickHandler)="saveBySelect()"
                                        [type]="ButtonType.CONFIRM"
                                    >
                                    </app-custom-button>
                                </div>
                            </form>
                        </mat-step>
                    </ng-container>
                    <mat-step [stepControl]="form"
                              *ngIf="firstFormGroup.get('type').value === typeModification.ACTIVITY">
                        <form [formGroup]="form">
                            <div class="w-full flex flex-col max-h-[60vh]">

                                <ng-template matStepLabel>{{ t('training_offer.edit_global_activity_information') }}
                                </ng-template>
                                <ng-container *ngIf="data.editGlobalTopAlertTextLabel">
                                    <fuse-alert
                                        class="mb-3"
                                        [appearance]="'outline'"
                                        [type]="'warning'">
                                        {{ t(data.editGlobalTopAlertTextLabel) }}
                                    </fuse-alert>
                                </ng-container>
                                <fuse-alert
                                    class="my-3"
                                    [appearance]="'outline'"
                                    [type]="'info'">
                                    {{ t('training_offer.edit_global_activity_information_message') }}
                                </fuse-alert>

                                <ng-container [ngSwitch]="data.type">

                                    <ng-container *ngSwitchCase="type.CORSO">
                                        <app-course-form [form]="formCourse"
                                                         [data]="data"
                                                         [curriculums]="dialogAddCourseService.curriculums"
                                        ></app-course-form>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="type.ATTIVITAEXTRA">
                                        <app-extra-activity-form
                                            *ngIf="formExtraActivity"
                                            [form]="formExtraActivity"
                                            [data]="data"
                                            [curriculums]="dialogAddCourseService.curriculums"
                                        ></app-extra-activity-form>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="type.ATTIVITATRASVERSALE">
                                        <app-transversal-activity-form
                                            *ngIf="formTransversalActivity"
                                            [form]="formTransversalActivity"
                                            [data]="data"
                                            [curriculums]="dialogAddCourseService.curriculums"
                                        ></app-transversal-activity-form>
                                    </ng-container>


                                </ng-container>
                                <div class="flex flex-row justify-between w-full div-button-sticky">
                                    <app-custom-button
                                        [disabled]="showMiniLoader"
                                        [type]="ButtonType.PREVIOUS"
                                    >
                                    </app-custom-button>
                                    <app-custom-button
                                        [loading]="showMiniLoader"
                                        (clickHandler)="updateActivity()"
                                        [type]="ButtonType.CONFIRM"
                                    >
                                    </app-custom-button>
                                </div>

                            </div>
                        </form>
                    </mat-step>

                </mat-stepper>

            </div>


        </form>
    </app-dialog-wrap>


</ng-container>

