import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogWrapI} from "../../../../../layout/common/dialog-wrap/dialog-wrap.component";
import moment from "moment";
import {AnnoRiferimentoValues} from "../../../../../../api-clients/generated/services";
import {annoRiferimentoToRomanNumeral} from "../../../../../shared/utils/utils";

export interface PlanSummaryByYearsI{
    year: AnnoRiferimentoValues;
    canEditActivitiesForYear?: boolean;
    activities: {
        categoryDenomination: string;
        activityDenomination: string;
        year: AnnoRiferimentoValues;
        categoryType: string;
    }[]
}

export interface DialogPlanSummaryI extends DialogWrapI {
    onConfirm: () => void;
    planSummaryByYears: PlanSummaryByYearsI[];
}

@Component({
    selector: 'app-dialog-plan-summary',
    templateUrl: './dialog-plan-summary.component.html',
    styleUrls: ['./dialog-plan-summary.component.scss']
})
export class DialogPlanSummaryComponent implements OnInit {


    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogPlanSummaryI,
                public dialogRef: MatDialogRef<DialogPlanSummaryComponent>) {

    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }

    confirmAction() {
        this.data?.onConfirm();
        this.closeDialog();
    }


    protected readonly stop = stop;
    protected readonly moment = moment;
    protected readonly confirm = confirm;
    trackByIndex = (index: number, obj: any) => index;
    protected readonly annoRiferimentoToRomanNumeral = annoRiferimentoToRomanNumeral;
}
