import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {get} from 'lodash';
import {FormControl, FormGroup} from '@angular/forms';
import {SafeUrl} from '@angular/platform-browser';
import {FuseConfirmationService} from '../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';

@Component({
    selector: 'app-image-header',
    templateUrl: './image-header.component.html',
    styleUrls: ['./image-header.component.scss']
})
export class ImageHeaderComponent implements OnInit {

    @Input() image: SafeUrl;
    fileSelected: File;
    @Input() nome: string;
    @Input() cognome: string;
    @Input() form: FormGroup;
    @Input() urlFoto: FormControl;
    @ViewChild('inputFile') inputRef: ElementRef;
    @Output() uploadImgEmitter = new EventEmitter<File>();
    @Input() enable: boolean;
    @Input() iniziali: string;
    @Input() wImg = 'w-20';
    @Input() hImg = 'h-20';

    @Input() showCaricaFoto = false;
    constructor(
        public fuseConfirmationService: FuseConfirmationService,
        private _translocoService: TranslocoService,
    ) {
    }

    ngOnInit(): void {

    }

  /*  get urlFoto(): FormControl {
        return this.form.get('url_foto') as FormControl;
    }*/



    onFileSelected(event: any): void {
        if (event?.target?.files?.length === 0) {
            return;
        }
        const fileInput = event?.target?.files[0];
        if (fileInput?.type?.includes('/jpg') || fileInput?.type?.includes('/jpeg') || fileInput?.type?.includes('/png')) {
            this.fileSelected = fileInput;
        } else {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'upload.not_supported_image', null),
                icon: {
                    name: 'mat_solid:error_outline',
                    color: 'error'
                },
                onBackdrop: {
                    show: true,
                    backdrop: false
                }
            });
        }
        if (this.fromBytesToMegaBytes(fileInput.size) > 1) {
            const activeLang = this._translocoService.getActiveLang();
            const translation = this._translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'upload.size_exceeded_image', null),
                icon: {
                    name: 'mat_solid:error_outline',
                    color: 'error'
                },
                onBackdrop: {
                    show: true,
                    backdrop: false
                }
            });
        } else {
            this.fileSelected = fileInput;
            this.uploadImgEmitter.emit(this.fileSelected);
        }
        this.inputRef.nativeElement.value = '';
    }

    fromBytesToMegaBytes(bytes: number): number {
        return bytes / (1024 * 1024);
    }


}
