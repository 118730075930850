import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DialogWrapI} from '../../../layout/common/dialog-wrap/dialog-wrap.component';
import {SafeUrl} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {
    AuthorityType,
    CollegioDocentiInfoView,
    StoricoMembriCorsoDiStudiInfoView,
    StoricoMembriStudenteInfoView
} from "../../../../api-clients/generated/services";
import {italianDateWithFormat} from "../../utils/utils-date";
import {trim} from "lodash";
import moment from "moment/moment";
import {upperCase} from "lodash-es";



export interface DialogStoricoI extends DialogWrapI {
    isAccordion?: boolean;
    membriStorici: Array<StoricoMembriStudenteInfoView | StoricoMembriCorsoDiStudiInfoView>;
    currentRuolo: AuthorityType;
}

@Component({
    selector: 'app-dialog-storico',
    templateUrl: './dialog-storico.component.html',
    styleUrls: ['./dialog-storico.component.scss']
})
export class DialogStoricoComponent implements OnInit {

    protected readonly italianDateWithFormat = italianDateWithFormat;
    membriStorici: (StoricoMembriStudenteInfoView | StoricoMembriCorsoDiStudiInfoView)[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogStoricoI,
                public dialogRef: MatDialogRef<DialogStoricoComponent>) {

    }

    ngOnInit(): void {
/*
        this.membriStoriciWithoutCurrent = this.data.membriStorici.filter(elementoStorico => elementoStorico.dataFine);
*/
        this.membriStorici = this.data?.membriStorici;
        // sorting by data inizio
        this.membriStorici.sort((a, b) => new Date(b.dataInizio).getTime() - new Date(a.dataInizio).getTime());
    }

    closeDialog() {
        this.dialogRef.close();
    }


    sortList(list): [] {
        return list.sort((a, b) => {
            if (a.cognome && b.cognome) {
                return a.cognome.localeCompare(b.cognome);
            } else {
                return list;
            }
        });
    }

    buildNomeCompleto(member: CollegioDocentiInfoView): string {
        return trim((member?.cognome || '') + ' ' + (member?.nome || ''));
    }

    protected readonly moment = moment;
    protected readonly upperCase = upperCase;
    protected readonly AuthorityType = AuthorityType;
}
