<ng-container *transloco="let t ">
    <div class="relative flex flex-col w-full max-h-[70vh]">

        <!-- Dismiss button -->
        <ng-container *ngIf="data?.onBackdrop?.show">
            <div class="absolute top-0 right-0 pt-4 pr-4">
                <button
                    mat-icon-button
                    (click)="onBackdropClose()">
                    <mat-icon
                        class="text-secondary"
                        [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>
        </ng-container>

        <!-- Content -->
        <div style="background-color: white;"
             class="flex flex-col sm:flex-row flex-auto items-center sm:items-start p-8 pb-6 sm:pb-8">

            <!-- Icon -->
            <ng-container *ngIf="data.icon.show">
                <div
                    class="flex flex-0 items-center justify-center w-10 h-10 sm:mr-4 rounded-full"
                    [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data.icon.color === 'primary',
                            'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon.color === 'accent',
                            'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon.color === 'warn',
                            'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon.color === 'basic',
                            'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon.color === 'info',
                            'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon.color === 'success',
                            'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon.color === 'warning',
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon.color === 'error',
                            'mt-2': data.subtitle
                            }">
                    <mat-icon
                        class="text-current"
                        [svgIcon]="data.icon.name"></mat-icon>
                </div>
            </ng-container>

            <ng-container>
                <div
                    class="flex flex-col items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left gap-2"
                    style="align-self: center">

                    <!-- Title -->
                    <!-- Title and subtitle -->
                    <ng-container>
                        <div
                            class="flex flex-col items-start lg:mt-2 lg:mb-2 lg:mr-4">
                            <div
                                class="text-xl leading-6 font-medium line-clamp-2"
                                [innerHTML]="(t(data.title) || t('dialog.attention')) | uppercase"></div>
                            <div *ngIf="data.subtitle"
                                 class="text-sm leading-6 font-medium line-clamp-1"
                                 [innerHTML]="t(data.subtitle) | uppercase"></div>
                        </div>
                    </ng-container>

                    <!-- Message -->
                    <ng-container *ngIf="data.message">
                        <div
                            class="text-secondary" style="word-break: break-word"
                            [innerHTML]="data.message"></div>
                    </ng-container>
                    <ng-container *ngIf="data?.alert">
                        <fuse-alert
                            class="w-full"
                            [type]="data?.alert?.type"
                            [appearance]="'outline'"
                        >{{data?.alert?.message}}</fuse-alert>
                    </ng-container>
                    <ng-container *ngIf="data.listData">
                        <ul class="mt-3">
                            <!--<li *ngFor="let item of data.listData" class="mb-2">
                                <span class="text-secondary font-semibold">{{item.mainText}}</span>
                                <div *ngIf="item.chipText" class="bg-slate-400 text-white px-1 rounded text-sm">{{item.chipText}}</div>
                                <span class="text-secondary ml-2 text-sm" *ngIf="item.secondaryText">{{item.secondaryText}}</span>
                            </li>-->
                            <li *ngFor="let item of data.listData"
                                class="list-disc mb-3" >
                                <div class="flex flex-col">
                                    <span class="font-semibold max-w-[70vw]">{{item.mainText}}</span>
                                    <div class="text-sm flex flex-col w-full gap-1" *ngIf="item.secondaryText || item.chipText">
                                        <span class="font-medium max-w-[60vw]" *ngIf="item.secondaryText">{{item.secondaryText}}</span>
                                        <div class="bg-slate-400 text-white px-1 rounded min-w-fit w-fit" *ngIf="item.chipText">{{item.chipText | titlecase}}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </ng-container>
                </div>
            </ng-container>

        </div>

        <!-- Actions -->
        <ng-container *ngIf="data.actions?.length > 0">
            <div style="padding: 10px"
                 class=" bg-gray-50 flex flex-col items-center gap-3"
                        [ngClass]="{'lg:flex-row lg:items-end lg:justify-end': !data.actionsFlexCol}">


                <ng-container *ngFor="let action of data.actions">
                    <button
                        [style]="!action.color ? ('background-color:'+action.hexColor) : ''"
                        [style.color]="!action.color ? action.hexColorText : ''"
                        mat-flat-button
                        [ngClass]="action.class ? action.class : ''"
                        [color]="action.color"
                        (click)="confirm(action?.function, action?.closeValue)">
                        <mat-icon class="mr-2" *ngIf="action.icon">{{action.icon}}</mat-icon>
                        {{action.label || t('dialog.confirm')}}
                    </button>
                </ng-container>

            </div>
        </ng-container>

    </div>

</ng-container>
