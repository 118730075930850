import { Pipe, PipeTransform } from '@angular/core';
import {ConfigurazioneColonna} from "../components/table/model/generic-table-model";


@Pipe({name: 'valueShowPipe'})
export class ValueShowPipe implements PipeTransform {

    transform(element: any, colonna: ConfigurazioneColonna, colonnaKey: string): string {
        if (colonnaKey.includes(' ')) {
            return colonnaKey.split(' ').map(aElem => element[aElem]).join(' ')
        }
        return !!colonna.valueCheck ? !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck]) : element[colonnaKey] : element[colonnaKey]?.toString();
    }
}
