import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {FlexModule} from "@angular/flex-layout";
import {TranslocoModule} from "@ngneat/transloco";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatChipsModule} from "@angular/material/chips";
import {SharedModule} from "../../shared.module";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
    declarations: [HeaderComponent],
    imports: [
        CommonModule,
        FlexModule,
        TranslocoModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatChipsModule,
        SharedModule,
        MatTooltipModule
    ],
    exports: [HeaderComponent]
})
export class HeaderModule {
}
