import {Component, Input, OnInit} from '@angular/core';
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {Delibera, UsersView} from "../../../../api-clients/generated/services";
import {StudentDetailsService} from "../../../modules/landing/student-details/student-details.service";
import moment from "moment";

@Component({
    selector: 'app-info-delibera-caricata',
    templateUrl: './info-delibera-caricata.component.html',
    styleUrls: ['./info-delibera-caricata.component.scss']
})
export class InfoDeliberaCaricataComponent implements OnInit {

    @Input() utenteCaricamento: UsersView;
    @Input() dataCaricamento: Date;
    @Input() delibera: Delibera;

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private studentDetailsService: StudentDetailsService) {
    }

    ngOnInit(): void {


    }


    downloadDelibera(deliberaApprovazione: Delibera) {
        this.fuseConfirmationService.openDialogDelibera({
            delibera: deliberaApprovazione,
            codiceCorsoDiStudiEsse3: this.studentDetailsService.studentDetails?.codiceCorsoDiStudiEsse3 ,
        });
    }

    protected readonly moment = moment;
}
