import {Component, Inject, InjectionToken, Input, OnChanges, OnInit, Optional, SimpleChanges} from '@angular/core';
import {PercorsoDiStudiView} from "../../../../api-clients/generated/services";
import {INFO_BOTTOM_COMPONENT_DATA} from "../../../layout/common/info-dialog/info-wrap.component";

@Component({
  selector: 'app-curriculum-accordion',
  templateUrl: './curriculum-accordion.component.html',
  styleUrls: ['./curriculum-accordion.component.scss']
})
export class CurriculumAccordionComponent implements OnInit, OnChanges {
    @Input() curriculumList: PercorsoDiStudiView[];

    constructor(@Optional() @Inject(INFO_BOTTOM_COMPONENT_DATA) public injectedCurriculumList: PercorsoDiStudiView[]) {
        this.curriculumList = (this.curriculumList || this.injectedCurriculumList)?.sort((a,b) =>
            a.idPercorsoDiStudi.localeCompare(b.idPercorsoDiStudi));
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.curriculumList = this.curriculumList?.sort((a,b) =>
            a.idPercorsoDiStudi.localeCompare(b.idPercorsoDiStudi));
    }

}
