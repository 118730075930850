import { Pipe, PipeTransform } from '@angular/core';
import {ConfigurazioneColonna} from "../components/table/model/generic-table-model";


@Pipe({name: 'getValuePipe'})
export class GetValuePipe implements PipeTransform {

    transform(element: any, colonna: ConfigurazioneColonna): string {
        return !!colonna.convertiValoreBoolean ? colonna.convertiValoreBoolean(element) : '';
    }
}
