<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">

        <mat-dialog-content>

            <ng-container *ngIf="data?.utentiConPianoNonPiuValido?.length">
                <div class="flex flex-col gap-2 w-full mt-2">
                    <fuse-alert
                        class="w-full text-lg font-medium"
                        [showIcon]="true"
                        [appearance]="'outline'"
                        [type]="'warning'">
                        {{t('status_study_plans.operation_invalid_plans_warn_message')}}
                    </fuse-alert>
                    <div class="w-full p-y-2.5" *ngIf="statusPlansTableConfiguration">
                        <app-generic-table [configuration]="statusPlansTableConfiguration"
                                           (clickAction)="tableClickAction($event)">
                        </app-generic-table>
                    </div>
                </div>
            </ng-container>

        </mat-dialog-content>

    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('dialog.cancel')}}
        </button>
        <button mat-flat-button
                class="max-w-60"
                color="primary"
                (click)="confirmOperation()">
            <mat-icon class="mr-2">done</mat-icon>
            {{t('dialog.confirm')}}
        </button>
    </div>
</ng-container>
