import {Component, InjectionToken, Injector, Input, OnInit, Pipe} from '@angular/core';
import {DialogInfoI} from '../../../shared/components/dialog-info/dialog-info.component';
import {FormControl} from '@angular/forms';
import {ComponentPortal} from "@angular/cdk/portal";

export const INFO_BOTTOM_COMPONENT_DATA = new InjectionToken<{}>('INFO_BOTTOM_COMPONENT_DATA');


@Component({
    selector: 'app-info-wrap',
    templateUrl: './info-wrap.component.html',
    styleUrls: ['./info-wrap.component.scss']
})
export class InfoWrapComponent implements OnInit {
    @Input() info: { key: string; value: any; style?: string ; hide?: boolean; pipe?: Pipe, showVisualizzaButton?: boolean, buttonAction?: () => void}[];
    @Input() colGridClass: string;
    @Input() data: DialogInfoI = {};
    @Input() ctrlUrlFoto: FormControl = new FormControl(null);

    portal: ComponentPortal<any>;

    constructor(private injector: Injector,) {
    }

    ngOnInit() {
        if(this.data.bottomComponent) {
            let containerPortal = new ComponentPortal(this.data.bottomComponent, null, this.createInjector(this.data.bottomComponentData));
            this.portal = containerPortal;
        }
    }

    createInjector(dataToPass): Injector {
        // DEPRECATED
        //const injectorTokens = new WeakMap();
        //injectorTokens.set(INFO_BOTTOM_COMPONENT_DATA, dataToPass);
        //return new PortalInjector(this.injector, injectorTokens);
        console.log(dataToPass)
        return Injector.create({
            parent: this.injector,
            providers: [
                { provide: INFO_BOTTOM_COMPONENT_DATA, useValue: dataToPass }
            ]
        });
    }

}
