import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogPrivacyComponent} from './dialog-privacy.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {TranslocoModule} from '@ngneat/transloco';
import {TestoPrivacyModule} from '../../components/testo-privacy/testo-privacy.module';
import {
    TestoTerminiECondizioniModule
} from '../../components/testo-termini-e-condizioni/testo-termini-e-condizioni.module';
import {DialogWrapModule} from '../../../layout/common/dialog-wrap/dialog-wrap.module';
import {MatIconModule} from "@angular/material/icon";



@NgModule({
    declarations: [DialogPrivacyComponent],
    imports: [
        CommonModule,
        DialogWrapModule,
        TestoPrivacyModule,
        MatDialogModule,
        TestoTerminiECondizioniModule,
        FlexModule,
        MatButtonModule,
        TranslocoModule,
        MatIconModule
    ]
})
export class DialogPrivacyModule {
}
