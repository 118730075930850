import { Injectable } from '@angular/core';
import {PercorsoDiStudiView} from "../../../../api-clients/generated/services";

@Injectable({
  providedIn: 'root'
})
export class DialogManageActivityService {

    curriculums: Array<PercorsoDiStudiView> = [];
    constructor() { }
}
