<ng-container *transloco="let t">
    <app-loading-screen *ngIf="loading; else contentTmpl" class="w-full h-full"></app-loading-screen>
    <ng-template #contentTmpl>
        <div class="h-full w-full" fxLayout="column">
            <div class="flex flex-col flex-auto bg-card shadow w-full h-full overflow-auto pt-5">

                <div class="w-full px-5 pb-5" *ngIf="currentPianoInfoView">
                    <app-unlock-plan-presentation [currentPianoInfoView]="currentPianoInfoView"
                                                  [configuration]="offertaFormativa?.configurazione"
                                                  [currentSottoruolo]="currentSottoruolo"
                                                  (onSbloccaBloccaModificaPiano)="openSbloccaBloccaPresentazionePianoDialog($event)"
                                                  (onSbloccaBloccaPianoNonValido)="openSbloccaBloccaPresentazionePianoNonValidoDialog($event)">
                    </app-unlock-plan-presentation>
                </div>

                <mat-tab-group #tabsGroup (selectedTabChange)="onActivityTabChange($event)" appScrollRetainer>

                    <!--PIANO APPROVATO -->
                    <mat-tab #approvedTab
                             [aria-label]="StudyPlanFragments.APPROVATO"
                             [label]="t('student.approved_plan') | titlecase" class="overflow-x-hidden">
                        <ng-container *ngIf="approvedTab.isActive">
                            <app-tab-approved-study-plan
                                [showBoxMessageForPlanCreation]="showBoxMessageForPlanCreation"
                                [isBozzaAccordionPresent]="isBozzaAccordionPresent"
                                [studyPlanYearData]="pianoDiStudiApproved"
                                [currentPianoInfoView]="currentPianoInfoView"
                                [myIdStudente]="myIdStudente"
                                (tableClickAction)="tableClickAction($event)"
                                (updatePianoDiStudiConfigurations)="updatePianoDiStudiConfigurations($event)">
                            </app-tab-approved-study-plan>
                        </ng-container>
                    </mat-tab>

                    <!-- AGGIORNAMENTI - PIANI PRESENTATI-->
                    <mat-tab [label]="t('student.presented_plans') | titlecase" class="overflow-x-hidden"
                             [aria-label]="StudyPlanFragments.PIANI_PRESENTATI"
                             #presentedTab>
                        <ng-container *ngIf="presentedTab.isActive">
                            <app-tab-presented-plans-study-plan
                                [aggiornamentiData]="pianoAggiornamenti"
                                [dataAggiornamentoChip]="dataAggiornamentoChip"
                                [sottoruoloPrimoApprovatore]="sottoruoloPrimoApprovatore"
                                [currentPianoInfoView]="currentPianoInfoView"
                                [myIdStudente]="myIdStudente"
                                [initialPageSize]="initialAggiornamentiPageSize"
                                [startSlicePaginator]="startSlicePaginator"
                                [endSlicePaginator]="endSlicePaginator"
                                (updatePianoDiStudiConfigurations)="updatePianoDiStudiConfigurations($event)"
                                (onPageChange)="onAggiornamentiPageChange($event)"
                                (tableClickAction)="tableClickAction($event, true)"
                            ></app-tab-presented-plans-study-plan>
                        </ng-container>
                    </mat-tab>

                    <!--PIANO DI STUDI DA APPROVARE SUPERVISORE-->
                    <mat-tab *ngIf="isSupervisoreOrApprovingCosupervisore && !!pianoDaApprovare"
                             #approveSupTab
                             [label]="t('study_plan_status.study_plan_to_approve') | titlecase"
                             [aria-label]="StudyPlanFragments.DA_APPROVARE_PARZIALMENTE"
                             class="overflow-x-hidden">
                        <div class="flex flex-col flex-auto overflow-x-hidden w-full justify-start items-center gap-5">
                            <ng-container *ngIf="approveSupTab.isActive">
                                <ng-container *ngIf="!!pianoDaApprovare">
                                    <app-tab-approve-study-plan
                                        class="w-full"
                                        [pianoDaApprovare]="pianoDaApprovare"
                                        [myIdStudente]="myIdStudente"
                                        [currentPianoInfoView]="currentPianoInfoView"
                                        (openModaleRifiuto)="openModaleRifiuto(pianoDaApprovare, currentSottoruolo)"
                                        (openModaleApprovaPiano)="openModaleApprovaPiano(pianoDaApprovareDaModificare)"
                                        (updatePianoDiStudiConfigurations)="updatePianoDiStudiConfigurations($event)"
                                        (tableClickAction)="tableClickAction($event)"
                                    ></app-tab-approve-study-plan>
                                </ng-container>
                            </ng-container>
                        </div>
                    </mat-tab>

                    <!--PIANO DI STUDI DA APPROVARE COORDINATORE-->
                    <mat-tab *ngIf="isCoordinatore && !!pianoApprovatoSupervisoreOrCoSupervisore;"
                             #approveCoordTab
                             [label]="t('study_plan_status.study_plan_approved_by_supervisor')"
                             [aria-label]="StudyPlanFragments.DA_APPROVARE_COORDINATORE"
                             class="overflow-x-hidden">
                        <div class="flex flex-col flex-auto overflow-x-hidden w-full justify-start items-center gap-5">
                            <ng-container *ngIf="approveCoordTab.isActive">
                                <ng-container *ngIf="!!pianoApprovatoSupervisoreOrCoSupervisore">
                                    <app-tab-approve-study-plan
                                        class="w-full"
                                        [pianoDaApprovare]="pianoApprovatoSupervisoreOrCoSupervisore"
                                        [myIdStudente]="myIdStudente"
                                        [currentPianoInfoView]="currentPianoInfoView"
                                        (openModaleRifiuto)="openModaleRifiuto(pianoApprovatoSupervisoreOrCoSupervisore, authorityType.COORDINATORE)"
                                        (openModaleApprovaPiano)="openModaleApprovaPiano(pianoApprovatoSupervisoreOrCoSupervisoreDaModificare)"
                                        (updatePianoDiStudiConfigurations)="updatePianoDiStudiConfigurations($event)"
                                        (tableClickAction)="tableClickAction($event)"
                                    ></app-tab-approve-study-plan>
<!--
                                    &lt;!&ndash; MARK: BOTTONI DI ACCETTA E RIFIUTA BY COORDINATORE &ndash;&gt;
                                    <div class="w-full flex flex-col gap-1">
                                        <div class="flex flex-row w-full justify-end items-center gap-2">
                                            <button mat-flat-button
                                                    class="multi-line-button"
                                                    (click)="openModaleRifiuto(pianoApprovatoSupervisoreOrCoSupervisore, authorityType.COORDINATORE)"
                                                    color="accent">
                                                <mat-icon class="mr-2">close</mat-icon>
                                                <span>{{t('student.not_approves_your_study_plan')}}</span>
                                            </button>
                                            <button mat-flat-button
                                                    class="multi-line-button"
                                                    (click)="openModaleApprovaPiano(pianoApprovatoSupervisoreOrCoSupervisoreDaModificare)"
                                                    color="primary">
                                                <mat-icon class="mr-2">check</mat-icon>
                                                <span>{{t('student.add_study_plan')}}</span>
                                            </button>
                                        </div>
                                    </div>
                                    &lt;!&ndash; ENDMARK: BOTTONI DI ACCETTA E RIFIUTA BY COORDINATORE &ndash;&gt;

                                    &lt;!&ndash; TABELLE &ndash;&gt;
                                    <div class="flex flex-col w-full">
                                        <div class="flex flex-col w-full">
                                            <mat-label>
                                                <strong>{{t(pianoApprovatoSupervisoreOrCoSupervisore.title | statusEnumToLanguagePath: PianoDiStudiStatus)}}</strong>
                                            </mat-label>
                                            <span>{{t('study_plan_status.last_update')}}: {{pianoApprovatoSupervisoreOrCoSupervisore.data}}</span>

                                        </div>
                                        <div style="padding: 20px 10px 10px 10px"
                                             class="flex flex-col flex-auto overflow-x-hidden w-full justify-start items-center gap-5">
                                            <ng-container
                                                *ngFor="let pianoStudi of pianoApprovatoSupervisoreOrCoSupervisore.pianoDiStudiOrderedByAnno; trackBy:trackByIdentity;">
                                                <mat-accordion class="w-full p-1">
                                                    <mat-expansion-panel #yearPanel>
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>
                                                                <strong>{{pianoStudi.year}} {{t('common.year')}}</strong>
                                                            </mat-panel-title>
                                                            <mat-panel-description>
                                                                <div>
                                                                    {{t('study_plan_status.total_cfu_selected') + ': ' + pianoStudi.totaleCfu?.toFixed(1)}}<span style="vertical-align: super" class="text-xs">1</span>
                                                                </div>
                                                            </mat-panel-description>
                                                        </mat-expansion-panel-header>

                                                        <div class="grid-cols-4 min-h-8 w-full pl-6 pr-6 text-secondary mb-2 hidden gap-1" *ngIf="yearPanel.expanded"
                                                             [ngClass]="{'custom-breakpoint-1:grid' : !isMenuOpen(), 'custom-breakpoint-2:grid' : isMenuOpen()}">
                                                            <div class="font-medium col-span-2 rounded-md bg-gray-200 h-full flex flex-row items-center justify-center px-1 gap-1">
                                                                <div class="py-1 text-center">{{t('common.FR_label')}}</div>
                                                            </div>
                                                            <div class="font-medium col-span-2 rounded-md bg-gray-200 h-full flex flex-row items-center justify-center px-1 gap-1">
                                                                <div class="py-1 text-center">{{t('common.FAR_label')}}</div>
                                                            </div>
                                                        </div>

                                                        &lt;!&ndash; TAB&ndash;&gt;
                                                        <mat-tab-group class="w-full activities-tab-group">
                                                            &lt;!&ndash; TAB CORSI&ndash;&gt;
                                                            <mat-tab [label]="t('common.didactic_activities') | titlecase"
                                                                     class="overflow-x-hidden">

                                                                <div style="border-radius: 6px;  border: 1px solid #e2e8f0;"
                                                                     class="mb-3 mt-2">
                                                                    <app-generic-table
                                                                        *ngIf="!!pianoStudi.courseConfiguration"
                                                                        [configuration]="pianoStudi.courseConfiguration"
                                                                        (clickAction)="tableClickAction($event)">
                                                                    </app-generic-table>
                                                                </div>
                                                            </mat-tab>

                                                            &lt;!&ndash; TAB ATTIVITà EXTRA&ndash;&gt;
                                                            <mat-tab [label]="t('student.extra_activities') | titlecase"
                                                                     class="overflow-x-hidden">

                                                                <div style="border-radius: 6px;  border: 1px solid #e2e8f0;"
                                                                     class="mb-3 mt-2">
                                                                    <app-generic-table
                                                                        *ngIf="!!pianoStudi.extraActivitiesConfiguration"
                                                                        [configuration]="pianoStudi.extraActivitiesConfiguration"
                                                                        (clickAction)="tableClickAction($event)">
                                                                    </app-generic-table>
                                                                </div>

                                                            </mat-tab>


                                                            &lt;!&ndash; TAB ATTIVITà TRASVERSALI&ndash;&gt;
                                                            <mat-tab [label]="t('student.transversal_activities') | titlecase"
                                                                     class="overflow-x-hidden">
                                                                <div class="w-full pt-2.5 pb-2.5">
                                                                    <app-transversal-activities
                                                                        [pianoStudi]="pianoStudi"
                                                                        [showButtonModificaCorsi]="false"
                                                                        [currentPianoInfoView]="currentPianoInfoView"
                                                                        [myIdStudente]="myIdStudente"
                                                                        (updatePianoDiStudiConfigurations)="updatePianoDiStudiConfigurations($event)"
                                                                        (tableClickAction)="tableClickAction($event)"
                                                                        [annoInsegnamento]="pianoStudi.annoInsegnamento"
                                                                        [isApprover]="true">
                                                                    </app-transversal-activities>
                                                                </div>
                                                            </mat-tab>

                                                            &lt;!&ndash; TAB ATTIVITà STUDENTE&ndash;&gt;
                                                            <mat-tab [label]="t('study_plan_status.student_extra_activities') | titlecase"
                                                                     class="overflow-x-hidden">

                                                                <div class="w-full pt-2.5 pb-2.5">
                                                                    <app-extra-activities-custom
                                                                        [pianoStudi]="pianoStudi"
                                                                        [showButtonModificaCorsi]="false"
                                                                        [currentPianoInfoView]="currentPianoInfoView"
                                                                        [myIdStudente]="myIdStudente"
                                                                        (updatePianoDiStudiConfigurations)="updatePianoDiStudiConfigurations($event)"
                                                                        (tableClickAction)="tableClickAction($event)"
                                                                        [annoInsegnamento]="pianoStudi.annoInsegnamento"
                                                                        [isApprover]="true">
                                                                    </app-extra-activities-custom>
                                                                </div>

                                                            </mat-tab>

                                                        </mat-tab-group>
                                                    </mat-expansion-panel>
                                                </mat-accordion>
                                            </ng-container>
                                            <div class="text-sm w-full text-start mt-2">
                                                <span style="vertical-align: super" class="text-xs pr-0.5">1</span>{{t('study_plan_status.transversal_cfu_count_warning')}}
                                            </div>
                                        </div>

                                    </div>
-->
                                </ng-container>
                            </ng-container>
                        </div>
                    </mat-tab>

                    <!-- CREA PIANO - NUOVA PRESENTAZIONE -->
                    <ng-container *ngIf="currentRuolo === _studente || currentRuolo === AuthorityType.SUPERADMIN">
                        <mat-tab
                                 #newPresentationTab
                                 [aria-label]="StudyPlanFragments.NUOVA_PRESENTAZIONE">
                            <ng-template mat-tab-label>
                                <div class="flex flex-row items-center gap-2">
                                    <span>{{t('student.new_presentation') | titlecase}}</span>
                                    <mat-chip-list *ngIf="studentDetailsService.studyPlanDraftCreationMode === StudyPlanDraftCreationMode.EDIT"
                                                   [matTooltip]="t('study_plan_status.draft_edit')">
                                        <mat-chip
                                            class="justify-between flex flex-row gap-2 cursor-pointer text-sm bg-yellow-100 py-1 px-2">
                                            <mat-icon class="text-yellow-600 icon-size-4">
                                                edit
                                            </mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>
                            </ng-template>
                            <app-tab-creation-study-plan
                                [isTabActive]="newPresentationTab.isActive"
                                (tableClickAction)="tableClickAction($event)"
                                [currentPiano]="currentPianoInfoView"
                                [showButtonModificaCorsi]="showButtonModificaCorsi"
                                (updatePianoDiStudiConfigurations)="updatePianoDiStudiConfigurations($event)"
                                [myIdStudente]="myIdStudente"
                                (sottomettiPiano)="openModaleConfermaPresentazionePiano($event)"
                                [guidaCoordinatore]="guidaCoordinatore"
                                [guidaAteneo]="guidaAteneo"
                                [numberOfArchivedRemovableActivities]="numberOfArchivedRemovableActivities"
                                [archivedRemovableActivities]="archivedRemovableActivities"
                                [offertaFormativa]="offertaFormativa"
                                (onOpenGuideERegoleTab)="openGuideERegoleTab()"
                                (onCancelDraftEdit)="cancelDraftEdit()">
                            </app-tab-creation-study-plan>
                        </mat-tab>
                    </ng-container>

                    <!-- GUIDE E REGOLE -->
                    <ng-container *ngIf="currentRuolo === _studente && offertaFormativa?.stato === OffertaFormativaStatus.PUBBLICA">
                        <mat-tab [label]="t('study_plan_status.rules_and_guide') | titlecase"
                                 [aria-label]="StudyPlanFragments.GUIDE_REGOLE">
                            <ng-template matTabContent>
                                <div class="flex flex-col gap-2 w-full">
                                    <!-- GUIDE COMPILAZIONE -->
                                    <app-guide-compilazione class="w-full mb-2"
                                                            [guidaAteneo]="guidaAteneo"
                                                            [guidaCoordinatore]="guidaCoordinatore"
                                                            [currLang]="currentLanguage?.toUpperCase()">
                                    </app-guide-compilazione>
                                    <!--REGOLE COMPILAZIONE-->
                                    <app-regole-compilazione class="w-full mb-2"
                                                             [offertaFormativa]="offertaFormativa">
                                    </app-regole-compilazione>
                                </div>
                            </ng-template>
                        </mat-tab>
                    </ng-container>

                </mat-tab-group>
            </div>
        </div>
    </ng-template>
</ng-container>

