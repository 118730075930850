<ng-container *transloco="let t">

    <div class="w-full mt-2 rounded-lg bg-gray-50 border-solid border-[1px] border-gray-500 p-2 items-center">
        <div class="w-full flex flex-col md:flex-row gap-2 justify-between items-center">
            <div class="flex flex-col gap-0">
                <div *ngIf="utenteCaricamento">
                    <span class="text-gray-600">{{t('common.delibera_uploaded_from_user')}} </span>
                    <span class="font-medium text-gray-600">{{utenteCaricamento.cognome | uppercase}} {{utenteCaricamento.nome | uppercase}}</span>
                </div>
                <div class="flex flex-row gap-1 items-center" *ngIf="dataCaricamento">
                    <mat-icon>calendar_month</mat-icon>
                    <span class="text-secondary text-sm mt-0.5">{{moment.utc(dataCaricamento).local().format('DD/MM/YYYY HH:mm')}}</span>
                </div>
            </div>
            <button [color]="'primary'"
                    mat-raised-button
                    class="multi-line-button"
                    type="button"
                    (click)="downloadDelibera(delibera)">
                <mat-icon class="mr-2">visibility</mat-icon>
                {{t('study_plan_status.download_delibera')}}
            </button>
        </div>
    </div>

</ng-container>
