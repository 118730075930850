<ng-container *transloco="let t ">
    <app-dialog-wrap class="w-full"
                     [class.readonly-all]="showMiniLoader"
                     [data]="data" [ngClass]="{'dialog-approval-wrap-with-custom': showCustomActivitiesTabs}">

        <mat-dialog-content>

            <div class="flex flex-row w-full pb-6 pt-4" *ngIf="showCustomActivitiesTabs">
                <fuse-alert [appearance]="'outline'" class="w-full" >
<!--                    {{t('study_plan_status.total_cfu_student_activities')}}: {{totalCfuAllYears}}
                    <br>
                    {{t('study_plan_status.first_year')}}: {{totalCfuFirstYear}} -
                    {{t('study_plan_status.second_year')}}: {{totalCfuSecondYear}} -
                    {{t('study_plan_status.third_year')}}: {{totalCfuThirdYear}}-->
                    <div class="text-left">{{t('study_plan_status.verify_extra_activities')}}</div>
                </fuse-alert>
            </div>

             <ng-container class="w-full" *ngIf="showCustomActivitiesTabs">
                <div style="border-radius: 6px;  border: 1px solid #e2e8f0;" class="mb-3 mt-2">
                    <app-generic-table-editable
                        [parentFormGroup]="formGroup"
                        *ngIf="!!extraActivitiesTableConfiguration"
                        [configuration]="extraActivitiesTableConfiguration"
                        (clickAction)="tableClickAction($event)">
                    </app-generic-table-editable>
                </div>
            </ng-container>

            <div class="flex flex-row w-full pb-6 pt-4" *ngIf="data.sottoruolo === AuthorityType.COORDINATORE && !data?.pianoDaApprovare?.deliberaApprovazione"> <!--&& data.pianoDaApprovare?.isNuovaPresentazione-->
<!--                <fuse-alert [appearance]="'outline'" class="w-full" >
                    <div class="text-left">{{t('study_plan_status.add_delibera_for')}}</div>
                </fuse-alert>-->
                <!-- DELIBERA -->
                <div class="w-full flex flex-col lg:flex-row gap-3 items-center lg:items-start">

                    <form class="w-full" [formGroup]="deliberaForm">
                        <mat-form-field appearance="outline"
                                        class="w-full add-mobilita-mat-form-field"
                                        *ngIf="deliberaForm?.get('deliberaCollegio') as fieldCtrl">
                            <mat-label><strong>{{t('mobility.collegio_approval')}}</strong></mat-label>
                            <mat-select formControlName='deliberaCollegio'>
                                <mat-option *ngFor="let delibera of delibere" [value]="delibera.id">
                                    {{t('mobility.delibera_name')}} {{delibera.numero}} - {{formatDeliberaDate(delibera?.data)}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="fieldCtrl.hasError('required')">
                                {{t('form.required')}}
                            </mat-error>

                        </mat-form-field>
                    </form>

                    <button [color]="'primary'"
                            mat-raised-button
                            type="button"
                            class="add-delibera-button"
                            [disabled]="showMiniLoader"
                            (click)="this.openAddNewDeliberaDialog()">
                        <mat-icon class="mr-2">add</mat-icon>
                        {{t('common.add_delibera')}}
                    </button>

                </div>
            </div>

            <!-- DELIBERA GIA' CARICATA -->
            <app-info-delibera-caricata *ngIf="data.sottoruolo === AuthorityType.COORDINATORE && data?.pianoDaApprovare?.deliberaApprovazione"
                                        [utenteCaricamento]="data?.pianoDaApprovare?.utenteCaricamentoDelibera"
                                        [dataCaricamento]="data?.pianoDaApprovare?.dataCaricamentoDelibera"
                                        [delibera]="data?.pianoDaApprovare?.deliberaApprovazione">
            </app-info-delibera-caricata>

        </mat-dialog-content>

    </app-dialog-wrap>
    <div class="w-full pb-4 pt-4 pr-6 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <div fxLayout="row" fxLayoutAlign="end center" class="mt-0.5 mb-0.5 gap-2">
            <button [color]="'accent'" [disabled]="showMiniLoader" mat-raised-button (click)="closeDialog()">
                <mat-icon class="mr-2">close</mat-icon>
                {{t('common.close')}}
            </button>

            <button [color]="'primary'" mat-raised-button
                    [disabled]="showMiniLoader"
                    (click)="onConfirm()">
                <mat-spinner *ngIf="showMiniLoader" [diameter]="20" color="accent" class="mr-2"></mat-spinner>
                <mat-icon class="mr-2" *ngIf="!showMiniLoader">done</mat-icon>
                {{t('dialog.confirm')}}
            </button>
        </div>
    </div>


</ng-container>
