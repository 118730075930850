import {Component, Inject, OnInit, Pipe} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DialogWrapI} from '../../../layout/common/dialog-wrap/dialog-wrap.component';
import {SafeUrl} from "@angular/platform-browser";
import {FormControl} from "@angular/forms";
import {ComponentType} from "@angular/cdk/overlay";



export enum TypeInfo {
    TEXTAREA = 'TEXTAREA',
    TEXT = 'TEXT',
    CHIP = 'CHIP',
    SECTION = 'SECTION',
    AMOUNT = 'AMOUNT'
}

export interface DialogInfoI extends DialogWrapI {
    info?: Info[];
    readonlyData?: Array<{ key: string; value: any; style?: string; pipe?: Pipe }>;
    imageSafeUrl?: SafeUrl;
    imageIniziali?: string;
    subTextImg?: string;
    showImage?: boolean;
    isTextArea?: boolean;
    text?: string;
    bottomComponent?: ComponentType<any>,
    bottomComponentData?: any,
    bottomComponentLabel?: string
}

export interface Info {
    nameGroup: string;
    children: { key: string; value?: any; flex?: string; type?: TypeInfo }[];

}

@Component({
    selector: 'app-dialog-info',
    templateUrl: './dialog-info.component.html',
    styleUrls: ['./dialog-info.component.scss']
})
export class DialogInfoComponent implements OnInit {
    type = TypeInfo;

    separatorKeysCodes: number[] = [ENTER, COMMA];
    ctrlUrlFoto = new FormControl(null);


    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInfoI,
                public dialogRef: MatDialogRef<DialogInfoComponent>) {

    }

    ngOnInit(): void {
        if(!!this.data && !!this.data.imageSafeUrl) {
            this.ctrlUrlFoto.setValue(this.data.imageSafeUrl);
        }
        this.ctrlUrlFoto.disable();
    }

    closeDialog() {
        this.dialogRef.close();
    }


}
