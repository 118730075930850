<ng-container *transloco="let t">

    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" style="margin-top: 10px" class="mb-2">
        <input #inputFile hidden type="file" (change)="onFileSelected($event)" placeholder="Carica file"
               accept="image/jpeg, image/jpg, image/png">
        <div class="rounded-full ring-4 ring-gray-300 container mb-4" fxLayout="row"
             [ngClass]="[wImg, hImg]"
             fxLayoutAlign="center center"
             [class.cursor-pointer]="urlFoto?.enabled || enable"
             (click)="(urlFoto?.enabled || enable) ? inputFile.click() : null">
            <ng-container *ngIf="image; else noImg">
                <img class="rounded-full image object-cover" [src]="image" alt="Avatar image">
                <div *ngIf="urlFoto?.enabled || enable" class="overlay rounded-full" fxLayout="row"
                     fxLayoutAlign="center center">
                    <mat-icon svgIcon="heroicons_outline:camera"
                    ></mat-icon>
                </div>
            </ng-container>
            <ng-template #noImg>
                <span class="relative">
                    <div [ngClass]="[wImg, hImg]"
                        class="flex flex-0 items-center justify-center rounded-full overflow-hidden">
                        <ng-container>
                            <div
                                class="flex items-center justify-center w-full h-full rounded-full text-2xl uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                                {{iniziali?.toUpperCase() || '?'}}
                            </div>
                        </ng-container>
                    </div>
                </span>
            </ng-template>
        </div>
        <button mat-flat-button
                *ngIf="showCaricaFoto"
                style="margin-top: 5px"
                (click)=" inputFile.click()"
                color="primary">
            <mat-icon class="mr-2">photo_camera</mat-icon>
            <span>{{t('common.change_img')}}</span>
        </button>
        <!-- <div class=" text-lg font-medium tracking-tight" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2px" style="word-break: break-word;">
             <div class="text-center " *ngIf="nome">
                 {{nome}}
             </div>
             <div class="text-center" *ngIf="cognome">
                 {{cognome}}
             </div>
         </div>-->

    </div>


</ng-container>


