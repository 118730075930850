<ng-container *transloco="let t">
    <app-dialog-wrap class="w-full" [data]="data">

        <mat-dialog-content>

            <ng-container *ngIf="!data.isAccordion; else accordionTemplate">
                <div *ngIf="membriStorici?.length > 0 as storico; else noHistory" class="w-full">
                    <ng-container *ngFor="let storicoElement of membriStorici; let i = index">
                        <div class="w-full flex flex-row items-center justify-between p-4 pt-3 border-b-2 border-slate-200 border-solid"
                             [ngClass]="{'bg-slate-100': i % 2 == 0}">
                            <div class="flex flex-col w-full">
                                <div class="w-full flex flex-row gap-1 items-center justify-start">
                                    <div class="font-medium my-1">
                                        {{(storicoElement.membro ? storicoElement.membro.cognome + ' ' + storicoElement.membro.nome :
                                        (storicoElement.membri?.length >= 1 ? storicoElement.membri[0].cognome + ' ' + storicoElement.membri[0].nome : (
                                            storicoElement.membroCognome + ' ' + storicoElement.membroNome
                                        ))) | uppercase}}
                                    </div>
                                    <mat-chip-list *ngIf="!storicoElement?.dataFine">
                                        <mat-chip class="text-green-500 bg-green-100">
                                            {{t('common.current')}}
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>

                                <div class="flex flex-col sm:flex-row sm:items-center gap-1 w-full">
                                    <div class="flex flex-row items-center gap-1" *ngIf="storicoElement.dataInizio">
                                        <!-- <mat-icon
                                             class="icon-size-5 text-hint mr-1"
                                             [svgIcon]="'heroicons_solid:clock'"></mat-icon>-->
                                        <div class="text-md text-secondary">{{t('common.from')}}</div>
                                        <div
                                            class="text-md text-secondary">{{italianDateWithFormat(storicoElement.dataInizio)}}</div>
                                    </div>
                                    <div class="flex items-center gap-1" *ngIf="storicoElement.dataFine">
                                        <!--<mat-icon
                                            class="icon-size-5 text-hint mr-1"
                                            [svgIcon]="'heroicons_solid:clock'"></mat-icon>-->
                                        <div class="text-md text-secondary">{{t('common.to')}}</div>
                                        <div
                                            class="text-md text-secondary">{{italianDateWithFormat(storicoElement.dataFine)}}</div>
                                    </div>
                                </div>
<!--                                <div class="flex flex-row gap-1 items-center text-sm text-secondary ">
                                    <div *ngIf="storicoElement?.dataCaricamentoDelibera as dataCaricamentoDelibera">
                                        Modificato il {{moment(dataCaricamentoDelibera).format('DD/MM/YYYY HH:mm')}}
                                    </div>
                                    <div *ngIf="storicoElement?.utenteCaricamentoDelibera as utenteCaricamentoDelibera">
                                        da {{utenteCaricamentoDelibera.cognome | uppercase}} {{utenteCaricamentoDelibera.nome | uppercase}}
                                    </div>
                                    <div *ngIf="storicoElement?.delibera as delibera">
                                        con delibera {{delibera.numero}} - {{moment(delibera.data).format('DD/MM/YYYY')}}
                                    </div>
                                </div>-->
                                <!-- DELIBERA -->
                                <app-info-delibera-caricata class="mt-1"
                                                            *ngIf="storicoElement?.delibera && data?.currentRuolo !== AuthorityType.STUDENTE"
                                                            [utenteCaricamento]="storicoElement?.utenteCaricamentoDelibera"
                                                            [dataCaricamento]="storicoElement?.dataCaricamentoDelibera"
                                                            [delibera]="storicoElement?.delibera">
                                </app-info-delibera-caricata>
                            </div>
                            <!--<button mat-icon-button>
                                <mat-icon [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                            </button>-->
                        </div>
                    </ng-container>
                </div>
                <ng-template #noHistory>
                    <div class="mt-4 text-lg font-medium">
                        <fuse-alert
                            class="w-full"
                            [showIcon]="true"
                            [appearance]="'outline'"
                            [type]="'warning'"
                        >{{t('common.empty_history')}}</fuse-alert>
                    </div>
                </ng-template>
            </ng-container>

            <ng-template #accordionTemplate>
                <div *ngIf="membriStorici.length > 0; else noHistory" class="flex flex-col gap-2 py-4">
                    <ng-container *ngFor="let storicoElement of membriStorici">
                        <mat-accordion class="w-full">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div class="flex flex-col sm:flex-row sm:items-center gap-1">
                                            <div class="flex flex-row items-center gap-1">
                                                <!-- <mat-icon
                                                     class="icon-size-5 text-hint mr-1"
                                                     [svgIcon]="'heroicons_solid:clock'"></mat-icon>-->
                                                <div class="text-md text-secondary">{{t('common.from')}}</div>
                                                <div
                                                    class="text-md text-secondary">{{italianDateWithFormat(storicoElement.dataInizio)}}</div>
                                            </div>
                                            <div class="flex items-center gap-1" *ngIf="storicoElement?.dataFine">
                                                <!--<mat-icon
                                                    class="icon-size-5 text-hint mr-1"
                                                    [svgIcon]="'heroicons_solid:clock'"></mat-icon>-->
                                                <div class="text-md text-secondary">{{t('common.to')}}</div>
                                                <div
                                                    class="text-md text-secondary">{{italianDateWithFormat(storicoElement.dataFine)}}</div>
                                            </div>
                                            <mat-chip-list *ngIf="!storicoElement?.dataFine" class="ml-1">
                                                <mat-chip class="text-green-500 bg-green-100">
                                                    {{t('common.current')}}
                                                </mat-chip>
                                            </mat-chip-list>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <!-- MEMBRI -->
                                <div class="flex flex-col w-full p-6 ">
                                    <!-- CHIPS -->
                                    <ng-container
                                        *ngIf="!!storicoElement.membri && storicoElement.membri.length > 0; else noMembers">
                                        <mat-chip-list>
                                            <div
                                                class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full">
                                                <ng-container *ngFor="let member of sortList(storicoElement.membri)">
                                                    <div class="flex flex-col w-full items-center">
                                                        <mat-chip color="primary" style="width:97%; word-break: break-word"
                                                                  class="justify-between h-full"
                                                                  [matTooltip]="buildNomeCompleto(member).toUpperCase()">
                                                                <span
                                                                    class="">{{buildNomeCompleto(member) | uppercase}}</span>
                                                        </mat-chip>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </mat-chip-list>
                                    </ng-container>

                                    <ng-template #noMembers>
                                        <div class="mt-4 text-lg font-medium">
                                            <fuse-alert
                                                class="w-full"
                                                [showIcon]="true"
                                                [appearance]="'outline'"
                                                [type]="'warning'"
                                            >{{t('cycle_doctorate.no_members')}}</fuse-alert>
                                        </div>
                                    </ng-template>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                </div>
                <ng-template #noHistory>
                    <div class="mt-4 text-lg font-medium">
                        <fuse-alert
                            class="w-full"
                            [showIcon]="true"
                            [appearance]="'outline'"
                            [type]="'warning'"
                        >{{t('common.empty_history')}}</fuse-alert>
                    </div>
                </ng-template>
            </ng-template>

        </mat-dialog-content>

    </app-dialog-wrap>

    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
    </div>
</ng-container>
