// returns true if in draft there is an activity with the same id which is only association-archived
// (respect to this, activity only changed years: since now they are both in the same year, they are identical, no need to select the newest)
// (if also global-archived, means that the activity changed, and probably you want to replace it (if you can, in initial state)


export function filterActivitiesAccordingArchived(tableData: any) {
    // taking only non archived and archived but in previous plan
    tableData = tableData.filter(a => (!a.isArchiviatoActivity && !a.isArchiviatoAssociazione) || a.includedInPreviousPlan);

    const alreadyShowedIdsCorso = []
    return tableData.filter(a => {
        // taking activity only one time
        const show = !alreadyShowedIdsCorso.includes(a.id);
        if (show) {
            alreadyShowedIdsCorso.push(a.id);
        } else {
            const alreadyAddedActivity = tableData.find(aToFind => aToFind.id === a.id);
            alreadyAddedActivity.isArchiviatoAssociazione = false;
            alreadyAddedActivity.isArchiviato = alreadyAddedActivity.isArchiviatoActivity;
        }
        return show;
    });
}
