/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipodi lavoro svolto da inserire in registro attività. Valori previsti: *ANALISI_DATI*, *PREPARAZIONE_LAVORO_SCIENTIFICO*, *PREPARAZIONE_TESI*, *PRESENTAZIONE_CONFERENZA*, *PRESENTAZIONE_POSTER*, *RICERCA_BIBLIOGRAFICA*, *STESURA_LAVORI*, *STUDIO_AUTONOMO*, *ALTRO*
 */
export type TipoLavoroSvolto = 'ANALISI_DATI' | 'PREPARAZIONE_LAVORO_SCIENTIFICO' | 'PREPARAZIONE_TESI' | 'PRESENTAZIONE_CONFERENZA' | 'PRESENTAZIONE_POSTER' | 'RICERCA_BIBLIOGRAFICA' | 'STESURA_LAVORI' | 'STUDIO_AUTONOMO' | 'ALTRO';

export const TipoLavoroSvolto = {
    ANALISIDATI: 'ANALISI_DATI' as TipoLavoroSvolto,
    PREPARAZIONELAVOROSCIENTIFICO: 'PREPARAZIONE_LAVORO_SCIENTIFICO' as TipoLavoroSvolto,
    PREPARAZIONETESI: 'PREPARAZIONE_TESI' as TipoLavoroSvolto,
    PRESENTAZIONECONFERENZA: 'PRESENTAZIONE_CONFERENZA' as TipoLavoroSvolto,
    PRESENTAZIONEPOSTER: 'PRESENTAZIONE_POSTER' as TipoLavoroSvolto,
    RICERCABIBLIOGRAFICA: 'RICERCA_BIBLIOGRAFICA' as TipoLavoroSvolto,
    STESURALAVORI: 'STESURA_LAVORI' as TipoLavoroSvolto,
    STUDIOAUTONOMO: 'STUDIO_AUTONOMO' as TipoLavoroSvolto,
    ALTRO: 'ALTRO' as TipoLavoroSvolto
};