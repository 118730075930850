<ng-container *transloco="let t">
    <!--<app-dialog-wrap class="w-full" [data]="data">

        <mat-dialog-content>

            <div class="w-full flex flex-col py-4 items-center">

                <span>Lavori in corso.</span>

            </div>

        </mat-dialog-content>


    </app-dialog-wrap>-->

    <div class="w-full flex flex-col py-6 px-4 items-center gap-4">

        <div class="flex flex-col gap-2 items-center">
            <div class="w-48">
                <img src="assets/images/logo/logo_colori_1282_377.png" alt="Atena logo">
            </div>
            <div>{{t('common.version')}} <span class="font-medium">{{ this.data?.tagFE || '-' }}/{{ this.data?.tagBE || '-' }}</span></div>
        </div>

        <hr class="m-0 w-full" style="border-bottom-width: 1px">

        <div class="font-base mt-1 text-center px-6" *ngIf="licenses">{{t('common.libraries_list')}}</div>

        <div class="w-full flex flex-col gap-1.5 items-center max-h-[50vh] md:w-[70vw] lg:w-[50vw] px-4 overflow-auto py-1"
             *ngIf="licenses; else cannotLoadDependenciesTemplate">

            <mat-accordion class="w-full">
                <mat-expansion-panel *ngFor="let license of licenses"
                                     class="mb-2 w-full licenses-panel">
                    <mat-expansion-panel-header class="min-h-6 pl-4">

                        <!--<a [href]="'https://npmjs.com/package/'+license.name"
                           style="line-height: 20px; height: 20px"
                           target="_blank">
                            <mat-icon class="cursor-pointer min-h-[unset]"
                                      style="height: 20px"
                                      [matTooltip]="t('common.go_to_npmjs')">
                                info_outline
                            </mat-icon>
                        </a>-->

                                <span class="text-sm text-start">
                            <a class="font-medium"
                               target="_blank">
                                {{license.name}}
                            </a>
                            <!--<span class="">{{license.version}} </span>-->
                                    <!--
                                                        <span class="italic">({{t('common.license')}} {{license.license}})</span>
                                    -->
                        </span>

                    </mat-expansion-panel-header>

                    <ng-template matExpansionPanelContent>

                        <div class="text-sm w-full"
                             *ngIf="license.license_text_html; else licenseNotAvailableTmpl"
                             [innerHTML]="license.license_text_html"></div>

                        <ng-template #licenseNotAvailableTmpl>
                            <span class="text-sm italic">{{t('common.not_available_license')}}</span>
                        </ng-template>

                    </ng-template>

                </mat-expansion-panel>

            </mat-accordion>


        </div>

        <ng-template #cannotLoadDependenciesTemplate>

            <div class="flex flex-col items-center justify-center text-center gap-2 max-h-[50vh] px-8 py-12">
                <mat-icon
                    class="text-amber-500"
                    style="font-size: 40px; height: 40px; width: 40px; color: #FFD374">warning</mat-icon>
                <span>{{t('common.cannot_load_libraries_list')}}</span>
            </div>

        </ng-template>

    </div>

    <div class="w-full p-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <div fxLayout="row" fxLayoutAlign="end center" class="mt-0.5 mb-0.5">
            <button [color]="'primary'" mat-raised-button (click)="closeDialog()">
                <mat-icon class="mr-2">close</mat-icon>
                {{t('common.close')}}
            </button>
        </div>
    </div>
</ng-container>
