import {DialogWrapI} from "app/layout/common/dialog-wrap/dialog-wrap.component";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {PianoDiStudiStatus} from "../../../../../../api-clients/generated/services";
import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslocoService} from "@ngneat/transloco";
import {takeUntil} from "rxjs";
import {FuseMediaWatcherService} from "../../../../../../@fuse/services/media-watcher";
import {
    ClickEvent,
    ConfigurazioneColonna,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {YesOrNotEnum} from "../../../../../shared/costants/app-constants";
import {get} from "lodash";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";

export type PianoDiStudiStatusExtended = 'NON_PRESENTATO' | 'ATTESA_APPROVAZIONE' | 'RIFIUTATO' | 'APPROVATO' | 'APPROVATONONVALIDO';

export interface UtenteConPianoNonPiuValidoI {
    nome?: string;
    cognome?: string;
}

export const PianoDiStudiStatusExtended = {
    NONPRESENTATO: 'NON_PRESENTATO' as PianoDiStudiStatus,
    ATTESAAPPROVAZIONE: 'ATTESA_APPROVAZIONE' as PianoDiStudiStatus,
    RIFIUTATO: 'RIFIUTATO' as PianoDiStudiStatus,
    APPROVATO: 'APPROVATO' as PianoDiStudiStatus,
    APPROVATOVALIDO: 'APPROVATOVALIDO' as PianoDiStudiStatus,
    APPROVATONONVALIDO: 'APPROVATONONVALIDO' as PianoDiStudiStatusExtended
};
export interface DialogInvalidStudyPlansComponentI extends DialogWrapI {
    utentiConPianoNonPiuValido: UtenteConPianoNonPiuValidoI[];
    onConfirm: (dialogRef2: MatDialogRef<DialogInvalidStudyPlansComponent>) => void;
}

@Component({
    selector: 'app-dialog-invalid-study-plans',
    templateUrl: './dialog-invalid-study-plans.component.html',
    styleUrls: ['./dialog-invalid-study-plans.component.scss']
})
export class DialogInvalidStudyPlansComponent extends AbstractDefaultComponent implements OnInit {

    statusPlansTableConfiguration: GenericTableConfigurationModel;
    enumValues: Array<PianoDiStudiStatusExtended> = Object.values(PianoDiStudiStatusExtended);
    isScreenSmall: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInvalidStudyPlansComponentI,
                public dialogRef: MatDialogRef<DialogInvalidStudyPlansComponent>,
                private _translocoService: TranslocoService,
                private fuseMediaWatcherService: FuseMediaWatcherService,
                private fuseConfirmationService: FuseConfirmationService,
    ) {
        super();
        this.fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({matchingAliases}) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngOnInit(): void {
        this.statusPlansTableConfiguration = this.buildStatusPlansTableConfigurations();
    }

    closeDialog() {
        this.dialogRef.close(undefined);
    }

    buildStatusPlansTableConfigurations(): GenericTableConfigurationModel {

        return {
            configuration: {
                data: this.data.utentiConPianoNonPiuValido?.map(studenteContent => ({
                    ...studenteContent,
                    studente: (studenteContent?.cognome + ' ' + studenteContent?.nome)?.toUpperCase(),
                    currentStatus: PianoDiStudiStatusExtended.APPROVATO,
                    futureStatus: PianoDiStudiStatusExtended.APPROVATONONVALIDO,
                })),
                sticky: true,
                pageSize: 5,
                pageSizeOptions: [5],
                isPaginatedBE: false,
                messaggioDatiAssenti: 'custom_table.no_data_student',
                hidePaginator: false,
                cssMatTable: {
                    'max-height': this.isScreenSmall ? 'unset' : '50vh',
                },
                configurazioneTabella: this.getConfigurazioneTabella()
            }
        };
    }


    getConfigurazioneTabella(): ConfigurazioneColonna[] {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        console.log(get(translation, 'common.' + YesOrNotEnum.YES.toLowerCase(), 'SI'), ' get(translation, \'common.\' + YesOrNotEnum.YES.toLowerCase(), \'SI\') ')
        const def: ConfigurazioneColonna[] = [
            ...this.defConfigStatusStudyPlans,
        ];

        return def;
    }

    defConfigStatusStudyPlans = [
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'students_list.student',
            colonnaKey: 'studente',
            flex: 20,
        },
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'menu_filter.enrollment_number',
            colonnaKey: 'matricola',
            flex: 10,
        },
        {
            tipo: TipoColonnaEnum.STRING,
            nomeColonna: 'students_list.university_email',
            colonnaKey: 'emailAteneo',
            flex: 25,
        },
        {
            tipo: TipoColonnaEnum.CHIP_STATUS,
            nomeColonna: ' ',
            colonnaKey: 'currentStatus',
            flex: 15,
            statusType: PianoDiStudiStatusExtended,
        },
        {
            tipo: TipoColonnaEnum.ICON,
            icon: [{
                nameIcon: 'arrow_forward',
                colorIcon: 'accent',
                classes: 'text-gray-500'
            }],
            nomeColonna: ' ',
            colonnaKey: 'denominazione',
            flex: 5,
        },
        {
            tipo: TipoColonnaEnum.CHIP_STATUS,
            nomeColonna: ' ',
            colonnaKey: 'futureStatus',
            flex: 15,
            statusType: PianoDiStudiStatusExtended,
            tooltip: (value) => value === PianoDiStudiStatusExtended.APPROVATONONVALIDO ? 'study_plan_status.approved_not_valid' : undefined,
            showIconInChipStatus: (value) => value === PianoDiStudiStatusExtended.APPROVATONONVALIDO ? {
                icon: 'warning_amber',
                classes: 'text-amber-500'
            } : undefined,
        },
        {
            nomeColonna: 'common.additional_information',
            colonnaKey: 'descrizione',
            flex: 10,
            tipo: TipoColonnaEnum.ACTION,
            button: [{
                nameIconButton: 'info',
                click: TipoClickEnum.SHOW,
                tooltip: 'common.additional_information',
                color: 'accent'
            }]
        },
    ] as ConfigurazioneColonna[];

    confirmOperation() {
        this.data.onConfirm(this.dialogRef);
    }

    tableClickAction($event: ClickEvent) {
        if($event.tipoClick === TipoClickEnum.SHOW){
            this.openInvalidMotivationsForPlan($event?.value?.errors, $event?.value?.studente)
        }
    }

    private openInvalidMotivationsForPlan(errors: string[], studente: string) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: 'training_offer.plan_is_not_valid_because',
                message: get(translation, 'training_offer.plan_is_not_valid_because_message', null),
                subtitle: studente,
                icon: {
                    show: true,
                    name: 'info',
                    color: 'basic'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                listData: errors?.map(e => ({ mainText: e })),
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                ]
            },
        );
    }
}
