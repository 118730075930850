import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {Observable, of} from "rxjs";
import {FuseConfirmationService} from "../../../@fuse/services/confirmation";
import {TrainingOfferService} from "../../modules/landing/cycle/training-offer/training-offer.service";
import {get} from "lodash";
import moment from "moment";
import {TranslocoService} from "@ngneat/transloco";


@Injectable({
    providedIn: 'root'
})
export class TrainingOfferGuard implements CanDeactivate<any> {
    /**
     * Constructor
     */
    constructor(
        private fuseConfirmationService: FuseConfirmationService,
        private translocoService: TranslocoService,
        private trainingOfferService: TrainingOfferService,
    ) {
    }

    canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (this.trainingOfferService.canEditOffer && !this.trainingOfferService.isTrainingOfferValid) {
            return new Observable<boolean>((subscriber) => {
                this.fuseConfirmationService.open({
                        message: get(translation, 'training_offer.invalid_activities_message', null),
                        icon: {
                            show: true,
                            name: 'mat_solid:info',
                            color: 'basic'
                        },
                        onBackdrop: {
                            show: false,
                            backdrop: true
                        },
                        actions: [
                            {
                                color: 'accent',
                                label: get(translation, 'common.close', null), icon: 'close',
                                function: () => {
                                    subscriber.next(false);
                                    subscriber.complete();
                                }
                            },
                            {
                                color: 'primary',
                                label: get(translation, 'dialog.confirm', null), icon: 'check',
                                function: () => {
                                    subscriber.next(true);
                                    subscriber.complete();
                                }
                            }]
                    }
                );
            })
        } else {
            return of(true);
        }
    }


}
