/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CodiceRispostaEnum } from '../model/codiceRispostaEnum';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { PageAudit } from '../model/pageAudit';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AuditService {

    protected basePath = 'https://api-dev-1-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Restituisce il byte array del body di tipo file presente nel dettaglio di una richiesta.
     * 
     * @param uri_file uri del file allegato da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoAudit(uri_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoAudit(uri_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoAudit(uri_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoAudit(uri_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uri_file === null || uri_file === undefined) {
            throw new Error('Required parameter uri_file was null or undefined when calling getAllegatoAudit.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (uri_file !== undefined && uri_file !== null) {
            queryParameters = queryParameters.set('uri_file', <any>uri_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/audit/get-allegato`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di audit con i filtri applicati.
     * 
     * @param dataDa 
     * @param dataA 
     * @param codiceRisposta 
     * @param codiceRispostaNon 
     * @param nominativo 
     * @param uri 
     * @param operazione 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAuditsForm(dataDa?: Date, dataA?: Date, codiceRisposta?: CodiceRispostaEnum, codiceRispostaNon?: CodiceRispostaEnum, nominativo?: string, uri?: string, operazione?: string, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageAudit>;
    public getAuditsForm(dataDa?: Date, dataA?: Date, codiceRisposta?: CodiceRispostaEnum, codiceRispostaNon?: CodiceRispostaEnum, nominativo?: string, uri?: string, operazione?: string, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageAudit>>;
    public getAuditsForm(dataDa?: Date, dataA?: Date, codiceRisposta?: CodiceRispostaEnum, codiceRispostaNon?: CodiceRispostaEnum, nominativo?: string, uri?: string, operazione?: string, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageAudit>>;
    public getAuditsForm(dataDa?: Date, dataA?: Date, codiceRisposta?: CodiceRispostaEnum, codiceRispostaNon?: CodiceRispostaEnum, nominativo?: string, uri?: string, operazione?: string, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dataDa !== undefined) {
            formParams = formParams.append('dataDa', <any>dataDa) as any || formParams;
        }
        if (dataA !== undefined) {
            formParams = formParams.append('dataA', <any>dataA) as any || formParams;
        }
        if (codiceRisposta !== undefined) {
            formParams = formParams.append('codiceRisposta', <any>codiceRisposta) as any || formParams;
        }
        if (codiceRispostaNon !== undefined) {
            formParams = formParams.append('codiceRispostaNon', <any>codiceRispostaNon) as any || formParams;
        }
        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (uri !== undefined) {
            formParams = formParams.append('uri', <any>uri) as any || formParams;
        }
        if (operazione !== undefined) {
            formParams = formParams.append('operazione', <any>operazione) as any || formParams;
        }

        return this.httpClient.request<PageAudit>('post',`${this.basePath}/api/v1/audit/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
