import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {
    ButtonInterface,
    ClickEvent,
    ConfigurazioneColonna,
    ConfigurazioneTabella,
    GenericTableConfigurationModel,
    IconInterface,
    TableData,
    TableDataRows,
    TipoClickEnum,
    TipoColonnaEnum
} from '../model/generic-table-model';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {get, isArray, isEmpty, isEqual, isNil, isString, sortBy} from 'lodash';
import {MatSort, Sort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {cloneDeep} from "lodash-es";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ExpandedGenericTableComponent} from "./expanded-generic-table/expanded-generic-table.component";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {BehaviorSubject, takeUntil} from "rxjs";
import {AbstractDefaultComponent} from "../../../abstracts/abstract-default-component/abstract-default-component";
import {filter} from "rxjs/operators";
import {MatMenuTrigger} from '@angular/material/menu';


export interface SelectedRowI {
    key: string;
    data: any;
    parentId?: string;
}


@Component({
    selector: 'app-generic-table',
    styleUrls: ['generic-table.component.scss'],
    templateUrl: 'generic-table.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class GenericTableComponent extends AbstractDefaultComponent implements AfterViewInit {
    @ViewChild(ExpandedGenericTableComponent) expandedGenericTableComponent: ExpandedGenericTableComponent
    public dataSource$: BehaviorSubject<any[] | undefined> = new  BehaviorSubject<any[] | undefined>(undefined);
    private sort: MatSort;
    private paginator: MatPaginator;
    private isPaginatedBE: boolean;

    private _righeAggiunte: Array<SelectedRowI> = [];
    private _righeRimosse: Array<SelectedRowI> = [];
    private disableAllCheck: boolean;
    private singleChoiceSelectedKey: any;

    _righeOriginali: Array<SelectedRowI> = [];
    _righeSelezionate: Array<SelectedRowI> = [];
    @Input() expandedElementId: string;
    @Output() rowExpanded = new EventEmitter<string>();
    initialCustomRowSelected: any[];

    constructor(private router: Router,
                private _translocoService: TranslocoService,
                private changeDetectorRef: ChangeDetectorRef,
                private fuseConfirmationService: FuseConfirmationService) {
        super();
    }

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        if(ms){
            this.sort = ms;
            this.setDataSourceAttributes()
        }
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        if(mp){
            this.paginator = mp;
            this.setDataSourceAttributes()
        }
    }

    totalElements = 25;
    displayedColumns: string[] = [];
    dataSourcePaginated: MatTableDataSource<any> = new MatTableDataSource<any>();
    _configuration: ConfigurazioneTabella;
    tipoColonna = TipoColonnaEnum;
    messaggioDatiAssenti: string;
    isSelectable: boolean = false;
    selectedColumnKeyToShow: string = undefined;
    selectedColumnKeyPrimaryKey: string = "id";
    chipClickType = TipoClickEnum.CHIP_DETAIL;

    @Output() onReplaceCustomRow: EventEmitter<any> = new EventEmitter<any>();
    @Output() customRowChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() clickAction: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();
    @Output() pageAction: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() sortAction: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() tableDataEmitter: EventEmitter<TableData> = new EventEmitter<TableData>();

    @Output() outputTableData: EventEmitter<Sort> = new EventEmitter<Sort>();

    @Input() infoRigheInSolaLettura: { iconName: string; tooltip: string };
    @Input() righeConConfermaDiRimozioneParent: { key: string}[];
    @Input() messageConfirmRemove: string;
    @Input() set righeSelezionate(selezione: Array<SelectedRowI>) {
        if (this._configuration?.isPaginatedBE) {
            selezione?.forEach(elementoSelezionato => this._righeSelezionate.push(elementoSelezionato));
            if (this._righeSelezionate.length > 0) {
                this._righeOriginali = cloneDeep(this._righeSelezionate);
            }
        } else {
            if (this.dataSourcePaginated.data.length > 0 && selezione.length > 0) {
                this.dataSourcePaginated.data.forEach((value) => {
                    const selectedElement = selezione.find((elementoSelezionato) => {
                        if(!!elementoSelezionato.parentId && elementoSelezionato.parentId === value[this._configuration.expandRow.parentIdKey]){
                            const valueChild = value[this._configuration.expandRow?.dataKey];
                            return valueChild && valueChild[this._configuration.expandRow.selection.selectedColumnKeyPrimaryKey] === elementoSelezionato.data[this._configuration.expandRow.selection.selectedColumnKeyPrimaryKey];
                        } else if(!elementoSelezionato.parentId) {
                            return  value[this.selectedColumnKeyPrimaryKey] === elementoSelezionato.data[this.selectedColumnKeyPrimaryKey];
                        }
                    })
                    if (selectedElement && !this._righeSelezionate.find(riga => riga.key === selectedElement.key)) {
                        this._righeSelezionate.push(selectedElement);
                    }
                })
                if (this._righeSelezionate.length > 0) {
                    this._righeOriginali = cloneDeep(this._righeSelezionate);
                }

            } else if(selezione?.length > 0) {
                selezione.forEach(selectedElement => {
                    if (selectedElement && !this._righeSelezionate.find(riga => riga.key === selectedElement.key)) {
                        this._righeSelezionate.push(selectedElement);
                    }
                });
                if (this._righeSelezionate.length > 0) {
                    this._righeOriginali = cloneDeep(this._righeSelezionate);
                }
            }
        }
    }

    @Input() idRigheSolaLettura: string[] = [];
    @Input() disabledRowTooltipText: string;
    @Input() disableAllRows: boolean;
    @Input() hideColumns: string[] = [];

    _righeSelezionateCustom: any[] = [];
    @Input() set righeSelezionateCustom(r: any[]) {
        this._righeSelezionateCustom = r;
        this.changeDetectorRef.detectChanges();
    }

    get righeSelezionate(): Array<SelectedRowI> {
        return this._righeSelezionate;
    }

    get righeOriginali(): Array<SelectedRowI> {
        return this._righeOriginali;
    }

    get righeAggiunte() {
        if (this.righeOriginali.length === 0) {
            return this._righeSelezionate
        }
        if (this._righeOriginali.length > 0 && this._righeSelezionate.length === 0) {
            return new Array<any>()
        } else {
            return this._righeSelezionate.filter((rigaSelezionata) => {
                const element = this._righeOriginali.find(rigaOriginale => rigaSelezionata.key === rigaOriginale.key  && rigaSelezionata.parentId === rigaOriginale.parentId);
                if (!element) {
                    return rigaSelezionata
                }
            })
        }
    }

    get righeRimosse() {
        if (this.righeOriginali.length === 0) {
            return new Array<any>()
        }
        if (this._righeOriginali.length > 0 && this._righeSelezionate.length === 0) {
            return this._righeOriginali
        } else {
            return this._righeOriginali.filter((rigaOriginale) => {
                const element = this._righeSelezionate.find(rigaSelezionata => rigaSelezionata.key === rigaOriginale.key && rigaSelezionata.parentId === rigaOriginale.parentId);
                if (!element) {
                    return rigaOriginale
                }
            })
        }
    }

    @Input() set configuration(data: GenericTableConfigurationModel) {
        if (!!data) {
            this._configuration = data.configuration;
            this._configuration.configurazioneTabella.forEach(colonna => {
                colonna.css = this.combineStyles(this._configuration.css, colonna?.flex, colonna.css);
            });
            // Gestione della selezione se opportunamente configurata. Se non configurata rimuovo elementi di selezione.
            this.isSelectable = this._configuration.selection !== undefined ? this._configuration.selection.isSelectable : false;
            this._configuration.configurazioneTabella = this._configuration.configurazioneTabella.filter(value => value.tipo !== TipoColonnaEnum.SELECTION)
            if (this.isSelectable) {
                this.selectedColumnKeyToShow = this._configuration.selection.selectedColumnKeyToShow;
                this.selectedColumnKeyPrimaryKey = this._configuration.selection.selectedColumnKeyPrimaryKey
                this._configuration.configurazioneTabella.splice(0, 0, {
                    tipo: TipoColonnaEnum.SELECTION,
                    nomeColonna: 'common.selection',
                    colonnaKey: 'selezione',
                    flex: 8,
                    css: this.combineStyles(this._configuration.css, 8, undefined)
                })
            }

            if (this._configuration?.expandRow?.expand && !this._configuration?.configurazioneTabella?.some(c => c.tipo == TipoColonnaEnum.EXPAND)) {
                this._configuration.configurazioneTabella.push({
                    tipo: TipoColonnaEnum.EXPAND,
                    nomeColonna: 'common.expand',
                    colonnaKey: 'expand',
                    flex: 10,
                });
            }

            this.displayedColumns = this._configuration.configurazioneTabella.filter(value => !value.hideColonna && !this.hideColumns.includes(value.colonnaKey)).map(value => value.colonnaKey);


            this.dataSource$.next(this._configuration.data || []);

            this.totalElements = this._configuration.totalElements ?? this._configuration?.data?.length ?? this.totalElements;
            if (this._configuration.data.length > 0) {
                this.messaggioDatiAssenti = 'Nessun dato corrispondente al filtro';
            } else {
                this.messaggioDatiAssenti = 'Non ci sono dati da visualizzare nella pagina corrente'
            }
            this.isPaginatedBE = this._configuration.isPaginatedBE;
        }
    }

    @Input() readOnly: boolean

    @Input() groupsHeaderTmpl?: TemplateRef<any>;

    @ViewChildren(MatMenuTrigger) trigger: QueryList<MatMenuTrigger>;
    @HostListener('window:scroll', [])
    scrollHandler() {
        for (let index = 0; index < this.trigger.toArray().length; index++) {
            this.trigger.toArray()[index].closeMenu();
        }
    }

    @Output() selectedByDefaultIfAndCallbackOnUnselect = new EventEmitter<any>();

    @Input() hideSelectionHeader: boolean;
    @Input() hideDashForEmptyChipStatus: boolean;

    setDataSourceAttributes() {
        if (!this.isPaginatedBE && (this.paginator && !this._configuration?.hidePaginator) && this.sort) {
            this.dataSourcePaginated.paginator = this.paginator;
            this.dataSourcePaginated.sort = this.sort;
            this.dataSourcePaginated.sortingDataAccessor = (item, property) => {
                if (this._configuration.configurazioneTabella?.find(v => v?.colonnaKey === property)?.sortByNumber) {
                    const onlyNumber = item[property]?.replace(/\D/g, '');
                    return onlyNumber ? Number(item[property].replace(/\D/g, '')) : this.castSort(item[property]);
                } else {
                    return this.castSort(item[property]);
                }
            };
            this.setDataSourceWithTimeout();
        } else if (!isNil(this._configuration.pageBE) && this.getPaginator()) {
            this.setDataSource();

            this.getPaginator().pageIndex = this._configuration.pageBE;
        } else if(this.isPaginatedBE) {
            this.setDataSource();
        } else {
            this.setDataSourceWithTimeout();
        }
    }

    private setDataSourceWithTimeout() {
        this.dataSource$.asObservable().pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe((x) => {
            setTimeout(() => {
                this.dataSourcePaginated.data = x;
                this.changeDetectorRef.detectChanges();
            })
        });
    }

    private setDataSource() {
        this.dataSource$.asObservable().pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe((x) => {
                this.dataSourcePaginated.data = x;
                this.changeDetectorRef.detectChanges();
        });
    }

    ngAfterViewInit() {
        this.initialCustomRowSelected = cloneDeep(this._righeSelezionateCustom);


    }


    updateDataSource(dataSource: any[]): void {
        this.dataSourcePaginated = new MatTableDataSource<any>(dataSource);
        this.setDataSourceAttributes();
    }

    show(button: ButtonInterface, element, value: string | string []): boolean {
        return !!button.showEvery ?
            this.showEvery(button, element, value) : !!button.show ? !!value ? isArray(value)
                ? value.some(v => button.show(get(element, v, element[v]), element)) : button.show(get(element, value, element[value]), element) : true : true;
    }

    showEvery(button: ButtonInterface, element, value: string | string []): boolean {
        return !!button.showEvery ? !!value ? isArray(value) ? value.every(v => button.showEvery(element[v])) : button.showEvery(element[value]) : true : true;
    }


    getValue(colonna: ConfigurazioneColonna, element): string {
        return !!colonna.convertiValoreBoolean ? colonna.convertiValoreBoolean(element) : '';
    }


    convertiValoreInBo(colonna: ConfigurazioneColonna, elementElement): IconInterface | null {
        return !!colonna.convertiValoreBooleanInIcon ? colonna.convertiValoreBooleanInIcon(elementElement) : null;
    }

    castSort(itemElement: any): any {
        return isString(itemElement) ? itemElement.toLowerCase() : itemElement;
    }

    onPageChange(pageEvent: PageEvent) {
        if (this.isPaginatedBE) {
            this.pageAction.emit(pageEvent)
        }
    }

    onSortChange($event: Sort) {
        if (this.isPaginatedBE) {
            this.sortAction.emit($event)
        }
    }

    goTo(colonna: ConfigurazioneColonna, element, colonnaKey: string) {
        if (colonna.goTo.value && colonna.goTo.path) {
            const baseUrl = window.location.href.replace(this.router.url, '');
            const url = new URL([...colonna.goTo.path, element[colonna.goTo.value]].join('/'), baseUrl).href
            window.open(url, '_blank');
        }
    }

    toggleSelectedRowData(element: any, event?: MatCheckboxChange) {
        if(this._configuration.selection?.selectedByDefaultIfAndCallbackOnUnselect?.(element)){
            this.selectedByDefaultIfAndCallbackOnUnselect.emit(element);
            event?.source.toggle();
        } else if(!this._configuration.selection?.selectedAndDisableByDefaultIf?.(element)) {
            const elementExtracted = this.righeSelezionate.findIndex(value => value.key === element[this.selectedColumnKeyPrimaryKey]);
            this.addOrRemoveSeletedRowData(elementExtracted, element);
        }
    }

    private addOrRemoveSeletedRowData(elementExtracted: number, element: any) {
        if (elementExtracted !== -1) {
            if(!this.righeConConfermaDiRimozioneParent?.length || !this.righeConConfermaDiRimozioneParent?.some(riga => riga.key === this.righeSelezionate[elementExtracted].key)){
                this.righeSelezionate.splice(elementExtracted, 1);
                this.disableAllCheck = false;
                if(this._configuration.checkboxColumnFormGroup){
                    const indexToRemove = this.getInputFieldsFormArray().controls.findIndex(c =>
                        c.get('key')?.value === element[this.selectedColumnKeyPrimaryKey]);
                    this.getInputFieldsFormArray().removeAt(indexToRemove);
                }
            } else {
                this.confirmRemove(elementExtracted, element);
                return;
            }
        } else {
            // toggle to true element
            this.righeSelezionate.push({key: element[this.selectedColumnKeyPrimaryKey], data: element});
            if(this._configuration.checkboxColumnFormGroup){
                this.getInputFieldsFormArray().push(new FormGroup({
                    key: new FormControl(element[this.selectedColumnKeyPrimaryKey]),
                    checked: new FormControl(false)
                }));
            }
        }
        this.disableAllCheck = this._configuration.singleChoiceSelection && this.righeSelezionate.length > 0;
        this.tableDataEmitter.emit({
            selectedRows: this.sortRigheSelezionate(this.righeSelezionate),
            removedRows: this.righeRimosse,
            addedRows: this.righeAggiunte
        });
    }

    removeToggleSelectedRowData(element: SelectedRowI) {
        const elementExtracted = this.righeSelezionate.findIndex(value => value.key === element.key && value.parentId === element.parentId);
        this.addOrRemoveSeletedRowData(elementExtracted, element);
        if (this.expandedGenericTableComponent) {
            this.expandedGenericTableComponent?.updateRigheSelezionate(this.righeSelezionate);
        }
    }

    toggleSelectedRowDataChild(element: SelectedRowI): void {
        const elementExtracted = this.righeSelezionate.findIndex(value => value.key === element.key && value.parentId === element.parentId);
        if (elementExtracted !== -1) {
            this.righeSelezionate.splice(elementExtracted, 1);
            this.disableAllCheck = false;
        } else {
            this.righeSelezionate.push(element);
        }
        this.disableAllCheck = this._configuration.singleChoiceSelection && this.righeSelezionate.length > 0;
        this.tableDataEmitter.emit({
            selectedRows: this.sortRigheSelezionate(this.righeSelezionate),
            removedRows: this.righeRimosse,
            addedRows: this.righeAggiunte
        });
    }

    sortRigheSelezionate(list: TableDataRows[]): TableDataRows[] {
        return sortBy(list, [function (o) {
            return o.data?.nomeCognome;
        }]);
    }


    checkIfSelected(key: string) {
        if (this._configuration.singleChoiceSelection) {
            this.singleChoiceSelectedKey = this.righeSelezionate.find(value => value.key === key)?.key;
        }
        return this.righeSelezionate.find(value => value.key === key);
    }

    disableNonSelectedCheck(element: any): boolean {
        if (this._configuration.singleChoiceSelection) {
            if (this.righeSelezionate.length > 0) {
                return element.id !== this.singleChoiceSelectedKey;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }


    checkIfRigaReadOnly(element: any): boolean {
        if(this.disableAllRows) {
            return true;
        }if (!element?.id || isEmpty(this.idRigheSolaLettura)) {
            return false;
        } else {
            return this.idRigheSolaLettura?.includes(element?.id);
        }
    }

    trackByFn(index: number, item: ConfigurazioneColonna): any {
        return item.colonnaKey || index;
    }



    onCellClick(element, tipo: TipoColonnaEnum): void {
        if (tipo !== TipoColonnaEnum.EXPAND) {
            if ((!this._configuration?.expandRow?.expand || !element[this._configuration.expandRow.dataKey]?.length)
                && this._configuration.selection?.isSelectable
                && !(this.readOnly || this.disableNonSelectedCheck(element) || this. checkIfParentIsDifferent(element) || this.checkIfRigaReadOnly(element))
                && !this._configuration.selection?.selectedAndDisableByDefaultIf?.(element)
            ) {
                this.toggleSelectedRowData(element);
            }
        }
    }

    protected readonly undefined = undefined;




    getPaginator(): MatPaginator {
        return this.paginator;
    }


    checkIfParentIsDifferent(element: any): boolean {
        return !!this.righeSelezionate.length ?  this.righeSelezionate?.some(riga => undefined !== riga.parentId && riga.key === element[this.selectedColumnKeyPrimaryKey]) : false;
    }

    toggleSelectedCustomRowData(row) {
        let indextToRemove;
        if(row.data?.id) {
            indextToRemove = this._righeSelezionateCustom?.findIndex(r => r.data?.id === row.data?.id);
        } else {
            indextToRemove = this._righeSelezionateCustom?.findIndex(r => r.data?.nome === row.data?.nome && r.data?.cognome === row.data?.cognome);
        }
        this._righeSelezionateCustom?.splice(indextToRemove, 1);
        this.customRowChanged.emit(this.checkIfCustomRowChanged());
    }

    private checkIfCustomRowChanged() {
        // compare array with initial
        return !isEqual(this.initialCustomRowSelected, this._righeSelezionateCustom);
    }

    private confirmRemove(elementExtracted: number, element?: any){
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'common.attention', null),
                message: get(translation, this.messageConfirmRemove, null),
                icon: {
                    show: true,
                    name: 'edit',
                    color: 'primary'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'dialog.confirm', null), icon: 'check',
                        function: () => {
                            this.righeSelezionate.splice(elementExtracted, 1);
                            this.disableAllCheck = false;
                            this.disableAllCheck = this._configuration.singleChoiceSelection && this.righeSelezionate.length > 0;
                            if(this._configuration.checkboxColumnFormGroup){
                                const indexToRemove = this.getInputFieldsFormArray().controls.findIndex(c =>
                                    c.get('key')?.value === element[this.selectedColumnKeyPrimaryKey]);
                                this.getInputFieldsFormArray().removeAt(indexToRemove);
                            }
                            this.tableDataEmitter.emit({
                                selectedRows: this.sortRigheSelezionate(this.righeSelezionate),
                                removedRows: this.righeRimosse,
                                addedRows: this.righeAggiunte
                            });
                        }
                    }]
            }
        );
    }

    expandRow($event: MouseEvent, element: any) {
        this.expandedElementId = this.expandedElementId === element?.[this._configuration?.expandRow?.parentIdKey] ? null : element?.[this._configuration?.expandRow?.parentIdKey];
        this.rowExpanded.emit(this.expandedElementId);
        $event.stopPropagation();
    }

    getFormControlOfCheckboxColonna(element): FormControl {
        let index = this.getInputFieldsFormArray().controls.findIndex(
            (group: FormGroup) => group.get('key')?.value === element.id
        );
        return (this.getInputFieldsFormArray()?.at(index) as FormGroup)?.get('checked') as FormControl;
    }

    getInputFieldsFormArray(): FormArray {
        return this._configuration.checkboxColumnFormGroup?.get('array') as FormArray;
    }


    setCheckBoxValue(element, colonnaKey: string | undefined) {
        element[colonnaKey] = !element[colonnaKey];
        this.tableDataEmitter.emit({
            selectedRows: this.sortRigheSelezionate(this.righeSelezionate),
            removedRows: this.righeRimosse,
            addedRows: this.righeAggiunte
        });
    }

    combineStyles(configurationCss: any, flex: number, colonnaCss: any): any {
        return {
            ...(configurationCss || {}),
            ...(colonnaCss || {}),
            'max-width': flex ? `${flex}%` : 'initial'
        };
    }


    closeMenus() {
        for (let index = 0; index < this.trigger.toArray().length; index++) {
            this.trigger.toArray()[index].closeMenu();
        }
    }

}
