import {
    AggiornamentoPianoDiStudi,
    AnnoRiferimentoValues,
    AttivitaCategoriaAssociationInfoView,
    CategoriaInAggiornamentoPianoDiStudi,
    CategoriaInPianoDiStudiInfoView, CategoriaOffertaFormativaInfoView, ConfigurazioneAttivitaPresunteOrProposte,
    ConfigurazioneCategoriaPerAnni, CorsoPianoDiStudiInfoView, DatiCorsoOffertaFormativa,
    ElementoOffertaFormativaType,
    OffertaFormativaInfoViewImpl,
    PianoDiStudiInfoViewImpl
} from "api-clients/generated/services";
import {TrainingOfferCategoryByTypeDataUI} from "../../cycle/training-offer/training-offer.component";
import {Translation} from "@ngneat/transloco";
import {sortBy} from "lodash";
import {GenericTableConfigurationModel} from "../../../../shared/components/table/model/generic-table-model";
import {
    TrainingOfferCategoryDataUI
} from "../../training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component";
import {
    annoInsegnamentoValueToNumber,
    annoRiferimentoToRomanNumeral,
    elementoOffertaFormativaTypeToNumber
} from "../../../../shared/utils/utils";
import {
    StudyPlanActivityTypeDataUI,
    StudyPlanAggiornamentoDataUI,
    StudyPlanCategoryDataUI
} from "../../../../shared/interface/piano-di-studi-data-u-i";
import {calculateTotalCFUOfActivitiesInCategories, calculateTotalCFUOfActivitiesInCategory} from "./study-plan-utils";
import {
    getOfferCategoriesByType,
} from "../../cycle/training-offer/training-offer-utils";

export type UIActivityI = DatiCorsoOffertaFormativa
    & CorsoPianoDiStudiInfoView
    & {
    id?: string,
    idCategoriaOffertaFormativa?: string,
    idAttivitaTrasversaleNotSavedYet?: string,
    dettaglio?: string,
    idsVersioniPrecedenti?: string[]};


function annoRiferimentoToCheckActivityInCategoryYearCallback(year: AnnoRiferimentoValues): (c: any) => boolean {
    return (c) =>
        (c?.datiCorsoOffertaFormativa?.annoInsegnamento === year || c?.annoInsegnamento === year);
}

// utils functions
export const getPlanCategoriesByYear = (piano: PianoDiStudiInfoViewImpl | AggiornamentoPianoDiStudi,
                                        year: AnnoRiferimentoValues,): (CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoPianoDiStudi)[] => {
    const yearLabel = annoRiferimentoToRomanNumeral(year);
    const checkActivityInCategoryYearCallback = annoRiferimentoToCheckActivityInCategoryYearCallback(year);
    const checkCategoryConfigurationYearCallback = annoRiferimentoToCheckCategoryConfigurationYearCallback(year);
    const categorie = (piano.categorie as CategoriaInPianoDiStudiInfoView[])
        ?.filter(categoria => categoria?.configurazione?.configurazioni?.find(configurazione => checkCategoryConfigurationYearCallback(configurazione))) ?? [];
    let categories = categorie.map(categoria => ({
        ...categoria,
        corsi: categoria.corsi
            ?.filter(a => checkActivityInCategoryYearCallback(a)),
        numberOfActivitiesAcrossAllYears: categoria.corsi
            ?.filter(a => checkActivityInCategoryYearCallback(a))
            ?.length ?? 0,
        year: yearLabel
    }));
    categories = sortBy(categories, [
        category => category.denominazione,
    ], ['asc']);
    return categories;
};

export const getDraftCategoriesByYearInReadMode = (piano: PianoDiStudiInfoViewImpl | AggiornamentoPianoDiStudi,
                                                   year: AnnoRiferimentoValues,
                                                   offertaFormativa: OffertaFormativaInfoViewImpl): (CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoPianoDiStudi)[] => {
    const yearLabel = annoRiferimentoToRomanNumeral(year);
    const checkActivityInCategoryYearCallback = annoRiferimentoToCheckActivityInCategoryYearCallback(year);
    const checkCategoryConfigurationYearCallback = annoRiferimentoToCheckCategoryConfigurationYearCallback(year);
    const categorieFromOfferta = (offertaFormativa?.categorie as CategoriaOffertaFormativaInfoView[])
        ?.filter(categoria => categoria?.configurazione?.configurazioni?.find(configurazione => checkCategoryConfigurationYearCallback(configurazione))) ?? [];
    // categories to show must be from offerta, then they are matched with plan draft. This is done to show also empty categories in draft
    let categories = categorieFromOfferta.map(categoriaFromOfferta => {
        const categoryInPlan = (piano.categorie as any)?.find(categoryInPlan => categoryInPlan.idCategoriaOffertaFormativa === categoriaFromOfferta.id);
        return  {
            ...categoriaFromOfferta,
            idCategoriaOffertaFormativa: categoriaFromOfferta.id,
            corsi: categoryInPlan?.corsi
                ?.filter(a => checkActivityInCategoryYearCallback(a)) ?? [],
            numberOfActivitiesAcrossAllYears: categoryInPlan?.corsi
                ?.filter(a => checkActivityInCategoryYearCallback(a))
                ?.length ?? 0,
            year: yearLabel
        }});
    categories = sortBy(categories, [
        category => category.denominazione,
    ], ['asc']);
    return categories;
};

export const getPlanCategoriesByType = (list: Array<CategoriaInPianoDiStudiInfoView| CategoriaInAggiornamentoPianoDiStudi>,
                                        type: ElementoOffertaFormativaType): (CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoPianoDiStudi)[] | [] =>
    list?.filter(item => item.tipoAttivitaContenute === type) || [];

export function annoRiferimentoToCheckCategoryConfigurationYearCallback(year: AnnoRiferimentoValues): (config: ConfigurazioneCategoriaPerAnni) => boolean {
    switch (year) {
        case AnnoRiferimentoValues.PRIMO:
            return (c) => c.primo_anno;
        case AnnoRiferimentoValues.SECONDO:
            return (c) => c.secondo_anno;
        case AnnoRiferimentoValues.TERZO:
            return (c) => c.terzo_anno;
    }
}

export const createPlanCategoriesByActivityTypeConfigurations = (
    categoriesForThisYear: (CategoriaInPianoDiStudiInfoView | CategoriaInAggiornamentoPianoDiStudi)[],
    t: Translation,
    year: AnnoRiferimentoValues,
    createTableConfigurationForCourses: (translation: Translation, category?: CategoriaInPianoDiStudiInfoView) => GenericTableConfigurationModel,
    createTableConfigurationForExtraActivities: (inputList?: CategoriaInPianoDiStudiInfoView) => GenericTableConfigurationModel,
    createTableConfigurationForTransversalActivities: (inputList?: CategoriaInPianoDiStudiInfoView) => GenericTableConfigurationModel,
    isUpdate?: boolean
): StudyPlanActivityTypeDataUI[] => {
    const checkConfigurationYearCallback = annoRiferimentoToCheckCategoryConfigurationYearCallback(year);
    const categoriesOfCorsi = getPlanCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.CORSO);
    const categoriesOfExtra = getPlanCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.ATTIVITAEXTRA);
    const categoriesOfTrasversal = getPlanCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.ATTIVITATRASVERSALE);
    return [
        {
            activityType: ElementoOffertaFormativaType.CORSO,
            activityLabel: 'common.didactic_activities',
            fragmentLabel: 'attivita_didattiche_programmate',
            categoriesConfiguration: categoriesOfCorsi?.map(category => ({
                ...category,
                activitiesTableConfiguration: createTableConfigurationForCourses(t, category),
                configurazioneForThisYear: category.configurazione?.configurazioni?.find(checkConfigurationYearCallback),
                totaleCfu: isUpdate ? calculateTotalCFUOfActivitiesInCategory(category) : undefined,
                numeroAttivitaSelezionate: category?.corsi?.length
            } as StudyPlanCategoryDataUI)),
            totaleCfu: isUpdate ? calculateTotalCFUOfActivitiesInCategories(categoriesOfCorsi as CategoriaInAggiornamentoPianoDiStudi[]) : undefined,
        },
        {
            activityType: ElementoOffertaFormativaType.ATTIVITAEXTRA,
            activityLabel: 'student.extra_activities',
            fragmentLabel: 'altre_attivita_didattiche',
            categoriesConfiguration: categoriesOfExtra.map(category => ({
                ...category,
                activitiesTableConfiguration: createTableConfigurationForExtraActivities(category),
                configurazioneForThisYear: category.configurazione?.configurazioni?.find(checkConfigurationYearCallback),
                totaleCfu: isUpdate ? calculateTotalCFUOfActivitiesInCategory(category) : undefined,
                numeroAttivitaSelezionate: category?.corsi?.length
            } as StudyPlanCategoryDataUI)),
            totaleCfu: isUpdate ? calculateTotalCFUOfActivitiesInCategories(categoriesOfExtra as CategoriaInAggiornamentoPianoDiStudi[]) : undefined,
        },
        {
            activityType: ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
            activityLabel: 'student.transversal_activities',
            fragmentLabel: 'attivita_formative_di_ricerca',
            categoriesConfiguration: categoriesOfTrasversal.map(category => ({
                ...category,
                activitiesTableConfiguration: createTableConfigurationForTransversalActivities(category),
                configurazioneForThisYear: category.configurazione?.configurazioni?.find(checkConfigurationYearCallback),
                totaleCfu: isUpdate ? calculateTotalCFUOfActivitiesInCategory(category) : undefined,
                numeroAttivitaSelezionate: category?.corsi?.length
            } as StudyPlanCategoryDataUI)),
            totaleCfu: isUpdate ? calculateTotalCFUOfActivitiesInCategories(categoriesOfTrasversal as CategoriaInAggiornamentoPianoDiStudi[]) : undefined,
        }
    ]
};

export const getDraftCategoriesByYearInEditMode = (offerta: OffertaFormativaInfoViewImpl,
                                                   draft: AggiornamentoPianoDiStudi,
                                                   year: AnnoRiferimentoValues,): TrainingOfferCategoryDataUI[] => {
    const yearLabel = annoRiferimentoToRomanNumeral(year);
    const checkActivityInCategoryYearCallback = annoRiferimentoToCheckActivityInCategoryOfferYearCallback(year);
    const checkCategoryConfigurationYearCallback = annoRiferimentoToCheckCategoryConfigurationYearCallback(year);
    const categorie = offerta?.categorie?.filter(categoria =>
        categoria.configurazione.configurazioni.find(configurazione => checkCategoryConfigurationYearCallback(configurazione)))
    let categories = categorie?.map(value => {
        // corsi & extra, non presunte o proposte
        const corsiAndExtra = value.tipoAttivitaContenute !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE ?
            (value.attivitaCategoriaAssociations?.filter(a => checkActivityInCategoryYearCallback(a)) ?? []) : [];
        // trasversali, non presunte o proposte
        const trasversali = value.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITATRASVERSALE ?
            (draft.categorie.find(c => c.idCategoriaOffertaFormativa === value.id)
                ?.corsi
                ?.filter(att =>
                    !att.datiCorsoOffertaFormativa?.idAttivitaPresunta && !att.datiCorsoOffertaFormativa?.idAttivitaProposta && att.datiCorsoOffertaFormativa?.annoInsegnamento === year)
                ?.map(att => ({
                    attivitaOffertaFormativa: att.datiCorsoOffertaFormativa
                } as AttivitaCategoriaAssociationInfoView)) ?? []) : [];
        // presunte o proposte
        const presunteProposte = draft.categorie.find(c => c.idCategoriaOffertaFormativa === value.id)
            ?.corsi
            ?.filter(att =>
                (att.datiCorsoOffertaFormativa?.idAttivitaPresunta || att.datiCorsoOffertaFormativa?.idAttivitaProposta) && att.datiCorsoOffertaFormativa?.annoInsegnamento === year)
            ?.map(att => ({
                attivitaOffertaFormativa: att.datiCorsoOffertaFormativa
            } as AttivitaCategoriaAssociationInfoView)) ?? [];

        return {
            ...value,
            attivitaCategoriaAssociations:
                [
                    // corsi & extra, non presunte o proposte. These are ALL ACTIVITIES FROM OFFERTA
                    ...corsiAndExtra,
                    // trasversali, non presunte o proposte. These are ACTIVITIES ADDED FROM STUDENT, AND THEREFORE ACTUALLY SELECTED
                    ...trasversali,
                    // presunte o proposte. These are ACTIVITIES ADDED FROM STUDENT, AND THEREFORE ACTUALLY SELECTED
                    ...presunteProposte,
                ],
            numberOfActivitiesAcrossAllYears: value?.attivitaCategoriaAssociations?.length ?? 0,
            year: yearLabel
        }
    });
    categories = sortBy(categories, [
        category => category.denominazione,
    ], ['asc', 'asc']);
    return categories;
};

function annoRiferimentoToCheckActivityInCategoryOfferYearCallback(year: AnnoRiferimentoValues): (c: AttivitaCategoriaAssociationInfoView) => boolean {
    switch (year) {
        case AnnoRiferimentoValues.PRIMO:
            return (c) => c.primoAnno;
        case AnnoRiferimentoValues.SECONDO:
            return (c) => c.secondoAnno;
        case AnnoRiferimentoValues.TERZO:
            return (c) => c.terzoAnno;
    }
}

function canAddProposedPresumedForYear(configurazioneAttivitaPresunteOrProposte: ConfigurazioneAttivitaPresunteOrProposte, year: AnnoRiferimentoValues): boolean {
    if(year === AnnoRiferimentoValues.PRIMO){
        return configurazioneAttivitaPresunteOrProposte?.primo_anno;
    }
    if(year === AnnoRiferimentoValues.SECONDO){
        return configurazioneAttivitaPresunteOrProposte?.secondo_anno;
    }
    if(year === AnnoRiferimentoValues.TERZO){
        return configurazioneAttivitaPresunteOrProposte?.terzo_anno;
    }
    return false;
}

export const createEditModeCategoriesByActivityTypeConfigurations = (
    categoriesForThisYear: (TrainingOfferCategoryDataUI)[],
    t: Translation,
    year: AnnoRiferimentoValues,
    createTableConfigurationForCourses: (translation: Translation, category?: TrainingOfferCategoryDataUI, selectedAtt?: { key: string, data: UIActivityI }[]) => GenericTableConfigurationModel,
    createTableConfigurationForExtraActivities: (inputList?: TrainingOfferCategoryDataUI, selectedAtt?: { key: string, data: UIActivityI }[]) => GenericTableConfigurationModel,
    createTableConfigurationForTransversalActivities: (inputList?: TrainingOfferCategoryDataUI) => GenericTableConfigurationModel,
    createTableConfigurationForTransversalTipesActivities: (inputList?: TrainingOfferCategoryDataUI) => GenericTableConfigurationModel,
    draftForPickingSelectedActivities?: AggiornamentoPianoDiStudi,
    currentDraftData?: StudyPlanAggiornamentoDataUI,
    updateWithoutChangeSelected = false,
): TrainingOfferCategoryByTypeDataUI[] => {
    const checkConfigurationYearCallback = annoRiferimentoToCheckCategoryConfigurationYearCallback(year);
    const categoriesOfCorsi = getOfferCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.CORSO);
    const categoriesOfExtra = getOfferCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.ATTIVITAEXTRA);
    const categoriesOfTrasversal = getPlanCategoriesByType(categoriesForThisYear, ElementoOffertaFormativaType.ATTIVITATRASVERSALE);

    return [
        {
            activityType: ElementoOffertaFormativaType.CORSO,
            activityLabel: 'common.didactic_activities',
            fragmentLabel: 'attivita_didattiche_programmate',
            categoriesConfiguration: categoriesOfCorsi?.map(category => {
                const selectedCorsi = draftForPickingSelectedActivities ? getSelectedActivitiesIdByYearAndType(draftForPickingSelectedActivities?.categorie, category.id, ElementoOffertaFormativaType.CORSO, year, currentDraftData, updateWithoutChangeSelected) : undefined
                const confForThisYear = category.configurazione?.configurazioni?.find(checkConfigurationYearCallback);
                return {
                    ...category,
                    activitiesTableConfiguration: createTableConfigurationForCourses(t, category, selectedCorsi),
                    // These are selected COURSES
                    selectedActivities: selectedCorsi,
                    configurazioneForThisYear: confForThisYear,
                    canAddProposed: confForThisYear.flag_aggiunta_attivita_proposte && canAddProposedPresumedForYear(confForThisYear.configurazione_attivita_proposte, year),
                    canAddPresumed: confForThisYear.flag_aggiunta_attivita_presunte && canAddProposedPresumedForYear(confForThisYear.configurazione_attivita_presunte, year),
                } as TrainingOfferCategoryDataUI
            }),
        },
        {
            activityType: ElementoOffertaFormativaType.ATTIVITAEXTRA,
            activityLabel: 'student.extra_activities',
            fragmentLabel: 'altre_attivita_didattiche',
            categoriesConfiguration: categoriesOfExtra?.map(category => {
                const selectedExtra = draftForPickingSelectedActivities ? getSelectedActivitiesIdByYearAndType(draftForPickingSelectedActivities?.categorie, category.id, ElementoOffertaFormativaType.ATTIVITAEXTRA, year, currentDraftData, updateWithoutChangeSelected) : undefined;
                const confForThisYear = category.configurazione?.configurazioni?.find(checkConfigurationYearCallback);
                return {
                    ...category,
                    activitiesTableConfiguration: createTableConfigurationForExtraActivities(category, selectedExtra),
                    // These are selected EXTRAS
                    selectedActivities: selectedExtra,
                    configurazioneForThisYear: confForThisYear,
                    canAddProposed: confForThisYear.flag_aggiunta_attivita_proposte && canAddProposedPresumedForYear(confForThisYear.configurazione_attivita_proposte, year),
                    canAddPresumed: confForThisYear.flag_aggiunta_attivita_presunte && canAddProposedPresumedForYear(confForThisYear.configurazione_attivita_presunte, year),
                } as TrainingOfferCategoryDataUI
            }),
        },
        {
            activityType: ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
            activityLabel: 'student.transversal_activities',
            fragmentLabel: 'attivita_formative_di_ricerca',
            categoriesConfiguration: categoriesOfTrasversal?.map(category => {
                const confForThisYear = category.configurazione?.configurazioni?.find(checkConfigurationYearCallback);
                return {
                    ...category,
                    activitiesTableConfiguration: createTableConfigurationForTransversalActivities(category),
                    // Transversal don't need selectedActivities, since the ones in draftInEditMode are the currently selected
                    configurazioneForThisYear: confForThisYear,
                    canAddProposed: confForThisYear.flag_aggiunta_attivita_proposte && canAddProposedPresumedForYear(confForThisYear.configurazione_attivita_proposte, year),
                    canAddPresumed: confForThisYear.flag_aggiunta_attivita_presunte && canAddProposedPresumedForYear(confForThisYear.configurazione_attivita_presunte, year),
                } as TrainingOfferCategoryDataUI
            }),
            transversalTypologiesCategoriesConfiguration: categoriesOfTrasversal?.map(category => {
                const confForThisYear = category.configurazione?.configurazioni?.find(checkConfigurationYearCallback);
                return {
                    ...category,
                    activitiesTableConfiguration: createTableConfigurationForTransversalTipesActivities(category),
                    configurazioneForThisYear: confForThisYear,
                } as TrainingOfferCategoryDataUI
            }),
        }
    ]
};

// returns selected activity in current draft, excluding presunte and proposte
// presunte e proposte are showed directly in table and are not selectable
export const getSelectedActivitiesIdByYearAndType = (draftCategories: Array<CategoriaInAggiornamentoPianoDiStudi>,
                                                     categoryId: string,
                                                     type: ElementoOffertaFormativaType,
                                                     year: AnnoRiferimentoValues,
                                                     currentDraftData?: StudyPlanAggiornamentoDataUI,
                                                     updateWithouthChangingSelected = false): { key: string, data: any }[] => {


    // not change selected
    if(updateWithouthChangingSelected){
        const yearIndex = annoInsegnamentoValueToNumber(year) - 1;
        const typeIndex = elementoOffertaFormativaTypeToNumber(type);
        return currentDraftData
            ?.draftInEditModeCategoriesByYear[yearIndex]
            ?.categoriesByActivityType[typeIndex]
            ?.categoriesConfiguration
            ?.find(c => c.id === categoryId)?.selectedActivities ?? [];
    }

    // adding activities from draft
    const category = draftCategories
        ?.filter(item => item.tipoAttivitaContenute === type)
        ?.find(c => c.idCategoriaOffertaFormativa === categoryId);
    return category?.corsi
        ?.filter(a => a.datiCorsoOffertaFormativa?.annoInsegnamento === year
            && !a.datiCorsoOffertaFormativa?.idAttivitaProposta && !a.datiCorsoOffertaFormativa?.idAttivitaPresunta)
        ?.map(activity => ({
            key: activity?.datiCorsoOffertaFormativa?.idCorsoInOffertaFormativa ?? activity?.datiCorsoOffertaFormativa?.idAttivitaTrasversale,
            data: activity.datiCorsoOffertaFormativa
        })) ?? [];

};

