import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {of, Subject, switchMap, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {Navigation} from 'app/core/navigation/navigation.types';
import {LogoutService} from '../../../../shared/service/logout.service';
import {PathEnum} from '../../../../app.routing';
import {LocalStorageService} from '../../../../shared/service/local-storage.service';
import {
    AuthorityType,
    CicloAbilitatoViewImpl,
    UserAmministrazioneInfoCicliDTO,
    UserView
} from '../../../../../api-clients/generated/services';
import {AppInitService} from '../../../../shared/service/app-init.service';
import {CicloCorsoRuoloInterface} from '../../../../shared/interface/CicloCorsoRuoloInterface';
import {MatDialog} from '@angular/material/dialog';
import {get, groupBy, head} from 'lodash';
import {FuseConfirmationService} from '../../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
import {tap} from 'rxjs/operators';
import {versionInfo} from '../../../../../../version-info';
import {uniqBy} from "lodash-es";
import {
    AbstractDefaultComponent
} from "../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {
    DialogLicensesComponent,
    DialogLicensesI
} from "../../../../shared/dialogs/dialog-licenses/dialog-licenses.component";
import {MobilitaERicercaGuard} from "../../../../core/guards/mobilita-e-ricerca.guard";
import {SynchronizationsGuard} from "../../../../core/guards/synchronizations.guard";
import {GestioneDocumentiGuard} from "../../../../core/guards/gestione-documenti.guard";
import {AuditGuard} from "../../../../core/guards/audit.guard";
import {OperazioniMassiveGuard} from "../../../../core/guards/operazioni-massive.guard";
import {RichiesteDaDeliberareGuard} from "../../../../core/guards/richieste-da-deliberare.guard";
import {UsersListGuard} from "../../../../core/guards/users-list.guard";
import {
    OperazioniMassiveCaricamentoDelibereGuard
} from "../../../../core/guards/operazioni-massive-caricamento-delibere.guard";
import {ConfigurationsGuard} from "../../../../core/guards/configurations.guard";
import {DocentiEsterniListGuard} from "../../../../core/guards/docenti-esterni-list.guard";
import {ConfigurationsGestionaleGuard} from "../../../../core/guards/configurations-gestionale.guard";
import {StudentsListGuard} from "../../../../core/guards/students-list.guard";
import {StatusStudyPlansGuard} from "../../../../core/guards/status-study-plans.guard";

interface Dictionary<T> {
    [Key: string]: T;
}

@Component({
    selector: 'classic-layout',
    templateUrl: './classic.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassicLayoutComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    @ViewChild(FuseVerticalNavigationComponent) fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    @Input() isAreaAdministrator: boolean;

    isScreenSmall: boolean;
    user: UserView;
    cicloCorsoRuolo: CicloCorsoRuoloInterface;
    currentCorsoStudi: string;
    currentRuolo: AuthorityType;
    currentCiclo: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AuthorityType = AuthorityType;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    versionInfo = versionInfo;
    tagFE: string | undefined;
    tagBE: string | undefined;

    listaCicliCorsiRuoli: CicloAbilitatoViewImpl[] = [];
    listaCicliCorsiRuoliGroupByCiclo: Dictionary<CicloAbilitatoViewImpl[]>;
    showButtonEdit: boolean;
    currentSottoruolo: AuthorityType | undefined;
    sottoruoliUtente: AuthorityType[];
    cicloAmmSelected: number;
    userAmministrazioneRolesFiltered: AuthorityType[];

    constructor(
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private logoutService: LogoutService,
        private localStorageService: LocalStorageService,
        public appInitService: AppInitService,
        private _changeDetectorRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private fuseConfirmationService: FuseConfirmationService,
        private _translocoService: TranslocoService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translocoService: TranslocoService,
    ) {
        super();
        this.appInitService.cicloCorsoRuoloSelected$.subscribe(value => this.cicloCorsoRuolo = value);
        this.appInitService.listaRuoliCodiciDipartimentiObs.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            this.listaCicliCorsiRuoli = val;
            this.listaCicliCorsiRuoliGroupByCiclo = groupBy(this.listaCicliCorsiRuoli, 'numeroCiclo');
            this.checkShowButtonEdit();
        });
        this.appInitService.cicloAmmSelected$.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            this.cicloAmmSelected = val;
        });
    }


    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {

        this.tagFE = this.appInitService.FEversion;
        this.tagBE = this.appInitService.BEversion;

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        if (this.localStorageService.getProfileResponse()) {
            this.user = this.localStorageService.getProfileResponse();
        }
        console.log('isAdministrator', this.isAreaAdministrator)
        if (this.isAreaAdministrator) {
            this.buildMenuAmministrazione();
        } else {
            this.checkGestionale();
        }

        if(this.appInitService.isAreaAdministrator) {
            this.userAmministrazioneRolesFiltered = this.appInitService.userAmministrazioneInfoCicli?.roles?.filter(
                r => [AuthorityType.PTAATENEOSYNCESTERNI, AuthorityType.PTAATENEOMOBILITA, AuthorityType.PTAATENEOCSVANS].includes(r)
            );
        }


    }

    private checkGestionale(): void {

        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            tap((value: CicloCorsoRuoloInterface) => console.log('Dipartimento ruolo ciclo', value)),
            tap((value: CicloCorsoRuoloInterface) => {
                this.currentCorsoStudi = value?.codiceCorsoStudi;
                this.currentRuolo = value?.ruolo;
                this.currentCiclo = value?.ciclo;
                console.log(value)
            }),
            takeUntil(this.destroy$),
            switchMap(() =>
                (this.currentRuolo === AuthorityType.PERSONALEPTA || this.currentRuolo === AuthorityType.DOCENTE || this.currentRuolo === AuthorityType.ESTERNO)
                    ? this.appInitService.currentSottoruoliUtente$ : of(null)),
        ).subscribe({
            next: (sottoruoli: AuthorityType[]) => {
                if (!!sottoruoli) {
                    this.sottoruoliUtente = sottoruoli
                } else {
                    this.sottoruoliUtente = [];
                }
                this.buildMenuGesionale();
                this.fuseVerticalNavigationComponent?.refresh();
                this._changeDetectorRef.detectChanges();
                console.log(this.appInitService.cicloCorsoRuoloSelected, 'this.appInitService.cicloCorsoRuoloSelected');
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => this.logoutService.logout(),
                    () => undefined,
                    'common.logout',
                    err?.error?.message);
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        super.ngOnDestroy();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    signOut(): void {
        this.logoutService.logout();
    }

    hideCourseMenuGroup(): boolean {
        return !(!!this.currentCiclo && !!this.currentRuolo
            && !!this.currentCorsoStudi && (this.currentRuolo !== AuthorityType.STUDENTE));
    }

    hideOperativityMenuGroup(): boolean {
        return !(!!this.currentCiclo && !!this.currentRuolo
            && !!this.currentCorsoStudi && (this.currentRuolo !== AuthorityType.STUDENTE && this.currentRuolo !== AuthorityType.COMMISSIONEDIAUTOVALUTAZIONE));
    }

    navigation: Navigation;


    openModalModificaCicloCorsoRuolo(): void {
        this.appInitService.openModalModificaCicloCorsoRuolo();
    }

    openPrivacyOrTerminiECondizioni(isPrivacy: boolean): void {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([isPrivacy ? PathEnum.PRIVACY : PathEnum.TERMS_CONDITIONS])
        );
        window.open(url, '_blank');
    }


    checkShowButtonEdit(): void {
        if (Object.keys(this.listaCicliCorsiRuoliGroupByCiclo)?.length === 1) {
            const ciclo = head(Object.keys(this.listaCicliCorsiRuoliGroupByCiclo));
            const codici = uniqBy(this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.map(el => el.ruoloUtenteCorsoDiStudi?.corsoDiStudi), 'codiceEsse3');
            const ruoli = this.listaCicliCorsiRuoliGroupByCiclo[ciclo]?.filter(el => (el.ruoloUtenteCorsoDiStudi?.corsoDiStudi?.codiceEsse3 === head(codici)?.codiceEsse3)).map((el => el.ruoloUtenteCorsoDiStudi?.ruolo));
            if (codici.length === 1) {
                this.showButtonEdit = ruoli.length !== 1;
            } else {
                this.showButtonEdit = true;
            }
        } else {
            this.showButtonEdit = true;
        }
    }


    navigateToSouth(): void {
        window.open('https://www.southengineering.it', '_blank', 'noopener, noreferrer');
    }

    openDialogLicenses() {
        const data: DialogLicensesI = {
            title: 'common.licenses',
            icon: {
                name: 'mat_solid:info',
                color: 'basic'
            },
            tagFE: this.tagFE,
            tagBE: this.tagBE
        };
        this.dialog.open(DialogLicensesComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            minWidth: '15rem',
        });
    }

    private buildMenuAmministrazione() {
        this.navigation = {
            default: [
                {
                    title: 'common.home',
                    type: 'basic',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.HOME,
                    icon: 'mat_outline:home',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                {
                    title: 'common.profile',
                    type: 'basic',
                    icon: 'heroicons_outline:user',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' +  PathEnum.PROFILE,
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                {
                    title: 'sidebar.audit',
                    hidden: item => !AuditGuard.accessCondition(this.appInitService.userAmministrazioneInfoCicli?.roles),
                    type: 'basic',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' +  PathEnum.AUDIT,
                    icon: 'mat_outline:rule',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                /*{
                    title: 'sidebar.manual',
                    hidden: item => !ManualGuard.accessCondition(this.cicloCorsoRuolo),
                    type: 'basic',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' +  PathEnum.MANUAL,
                    icon: 'heroicons_outline:book-open',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },*/
                {
                    title: 'home.synchronizations',
                    hidden: item => !SynchronizationsGuard.accessCondition(this.appInitService.userAmministrazioneInfoCicli?.roles),
                    type: 'basic',
                    icon: 'mat_outline:sync',
                    link: '/' + PathEnum.ADMINISTRATOR + '/' +  PathEnum.SYNCHRONIZATIONS,
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                {
                    title: 'common.courses',
                    type: 'group',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    },
                    children: [
                        {
                            title: 'common.information',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.INFORMATION,
                            icon: 'mat_outline:info',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.training_offer',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.TRAINING_OFFER,
                            icon: 'mat_outline:library_books',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.configurations',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.CONFIGURAZIONI,
                            hidden: item => !ConfigurationsGuard.accessCondition(this.appInitService.userAmministrazioneInfoCicli?.roles),
                            icon: 'mat_outline:settings',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        }
                    ]
                },
                {
                    title: 'sidebar.aggregate_data',
                    type: 'group',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    },
                    children: [
                        {
                            title: 'sidebar.budget',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.BUDGET_LIST,
                            icon: 'mat_outline:account_balance_wallet',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.document_dashboard',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.GESTIONE_DOCUMENTI,
                            icon: 'mat_outline:description',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'home.student_list',
                            type: 'basic',
                            icon: 'mat_outline:school',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.STUDENTS,
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.users_list',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.ESTERNI_INVITI_LIST,
                            icon: 'mat_outline:people',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.docenti_esterni_list',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.DOCENTI_ESTERNI_LIST,
                            icon: 'mat_outline:groups',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.mobility_administration',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.MOBILITA,
                            icon: 'mat_outline:card_travel',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.type_of_scholarship',
                            type: 'basic',
                            link: '/' + PathEnum.ADMINISTRATOR + '/' + PathEnum.SCHOLARSHIP,
                            icon: 'heroicons_outline:briefcase',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                    ],
                }
            ]
        }
    }

    buildMenuGesionale() {
        this.navigation = {
            default: [
                {
                    title: 'common.home',
                    type: 'basic',
                    link: '/' + PathEnum.HOME,
                    icon: 'mat_outline:home',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
                {
                    title: 'common.profile',
                    type: 'basic',
                    icon: 'heroicons_outline:user',
                    link: '/' + PathEnum.PROFILE,
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },
/*                {
                    title: 'sidebar.manual',
                    hidden: item => !ManualGuard.accessCondition(this.cicloCorsoRuolo),
                    type: 'basic',
                    link: '/' +  PathEnum.MANUAL,
                    icon: 'heroicons_outline:book-open',
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    }
                },*/
                {
                    title: 'common.course',
                    type: 'group',
                    hidden: item => this.hideCourseMenuGroup(),
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    },
                    children: [
                        {
                            title: 'common.information',
                            hidden: item => this.hideCourseMenuGroup(),
                            type: 'basic',
                            link: '/' + PathEnum.INFORMATION,
                            icon: 'mat_outline:info',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.training_offer',
                            hidden: item => this.hideCourseMenuGroup(),
                            type: 'basic',
                            link: '/' + PathEnum.TRAINING_OFFER,
                            icon: 'mat_outline:library_books',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.type_of_scholarship',
                            hidden: item => this.hideCourseMenuGroup(),
                            type: 'basic',
                            link: '/' + PathEnum.SCHOLARSHIP,
                            icon: 'heroicons_outline:briefcase',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'cycle_doctorate.curriculum',
                            hidden: item => this.hideCourseMenuGroup(),
                            type: 'basic',
                            link: '/' + PathEnum.CURRICULUM,
                            icon: 'mat_outline:assignment_ind',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.configurations',
                            type: 'basic',
                            link: '/' + PathEnum.CONFIGURAZIONI,
                            hidden: item => !ConfigurationsGestionaleGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                            icon: 'mat_outline:settings',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        }
                    ]
                },
                {
                    title: 'common.operativity',
                    type: 'group',
                    hidden: item => this.hideOperativityMenuGroup(),
                    classes: {
                        title: 'color-white',
                        subtitle: 'color-white',
                        icon: 'color-white',
                        wrapper: 'color-white'
                    },
                    children: [
                        {
                            id: 'main_hub.school',
                            title: 'common.students',
                            type: 'collapsable',
                            icon: 'mat_outline:school',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            },
                            children: [
                                {
                                    title: 'home.student_list',
                                    hidden: item =>  !StudentsListGuard.accessCondition(this.cicloCorsoRuolo),
                                    type: 'basic',
                                    icon: 'mat_outline:group',
                                    link: '/' + PathEnum.STUDENTS,
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    }
                                },
                                {
                                    title: 'common.status_study_plans',
                                    hidden: item =>  !StatusStudyPlansGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                                    type: 'basic',
                                    icon: 'mat_outline:library_books',
                                    link: '/' + PathEnum.STATUS_STUDY_PLANS,
                                    classes: {
                                        title: 'color-white',
                                        subtitle: 'color-white',
                                        icon: 'color-white',
                                        wrapper: 'color-white'
                                    }
                                },
                            ],
                        },
                        {
                            title: 'sidebar.requests_to_be_resolved',
                            hidden: item => !RichiesteDaDeliberareGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                            type: 'basic',
                            icon: 'mat_outline:verified',
                            link: '/' + PathEnum.REQUESTS,
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.operazioni_massive',
                            hidden: item => !OperazioniMassiveGuard.accessCondition(this.sottoruoliUtente),
                            type: 'basic',
                            link: '/' + PathEnum.OPERAZIONI_MASSIVE,
                            icon: 'mat_outline:grid_view',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.operazioni_massive_load_delibere',
                            hidden: item => !OperazioniMassiveCaricamentoDelibereGuard.accessCondition(this.cicloCorsoRuolo),
                            type: 'basic',
                            link: '/' + PathEnum.OPERAZIONI_MASSIVE_CARICAMENTO_DELIBERE,
                            icon: 'mat_outline:grid_view',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.mobility',
                            hidden: item => !MobilitaERicercaGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                            type: 'basic',
                            link: '/' + PathEnum.MOBILITA,
                            icon: 'mat_outline:card_travel',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.users_list',
                            hidden: item => !UsersListGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                            type: 'basic',
                            link: '/' + PathEnum.ESTERNI_INVITI_LIST,
                            icon: 'mat_outline:people',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.docenti_esterni_list',
                            hidden: item => !DocentiEsterniListGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                            type: 'basic',
                            link: '/' + PathEnum.DOCENTI_ESTERNI_LIST,
                            icon: 'mat_outline:groups',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                        {
                            title: 'sidebar.document_dashboard',
                            hidden: item => !GestioneDocumentiGuard.accessCondition(this.cicloCorsoRuolo, this.sottoruoliUtente),
                            type: 'basic',
                            link: '/' + PathEnum.GESTIONE_DOCUMENTI,
                            icon: 'mat_outline:description',
                            classes: {
                                title: 'color-white',
                                subtitle: 'color-white',
                                icon: 'color-white',
                                wrapper: 'color-white'
                            }
                        },
                    ]
                },
            ]
        };
    }

    openModalModificaCicloAmm() {
        this.appInitService.openModalModificaCicloAmm();
    }

    openAdministrationRoleInfoDialog(adminRole: AuthorityType) {
        const activeLang = this._translocoService.getActiveLang();
        const translation = this._translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'administration.' + adminRole + '_title', null),
            message: get(translation, 'administration.' + adminRole, null),
            icon: {
                name: 'mat_outline:info',
                color: 'info'
            },
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                ]
            });
    }
}
