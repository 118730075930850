<ng-container *transloco="let t">
    <div class="w-full h-full flex justify-center items-center" style="min-height: 500px" [mat-spinner]=" showSpinner">

        <app-loading-screen *ngIf="loading; else dialogDocentiTemplate" class="w-full h-full">

        </app-loading-screen>


        <ng-template #dialogDocentiTemplate>
            <div class="dialog-multi-select-container" style="min-height: 250px">
                <div class="pr-2 ">
                    <app-dialog-wrap [data]="data">
                        <div class="w-full " style="min-height: 250px">

                            <div class="flex flex-col lg:flex-row justify-between items-center gap-2">
                                <form [formGroup]="form" (ngSubmit)="applyFilters()"
                                      (keydown.tab)="$event.stopPropagation()">

                                    <div class="flex flex-col lg:flex-row justify-start items-center "
                                         style="padding: 0 !important; padding-top: 14px !important;"
                                    >

                                        <div class="flex flex-col lg:flex-row gap-2 items-center">
                                            <mat-form-field class="mat-form-field-no-padding pb-2 lg:pb-0"
                                                            style="width:43%; min-width: 300px;"
                                                            appearance="outline">
                                                <mat-label>{{ t('menu_filter.nominative') }}</mat-label>
                                                <input matInput
                                                       [placeholder]="t('menu_filter.nominative')"
                                                       [formControl]="filtroNomivativoControl"
                                                       [readonly]="(loading$ | async)">
                                                <button mat-icon-button
                                                        matSuffix
                                                        type="button"
                                                        (click)="svuotaFiltroNominativo()"
                                                        *ngIf="filtroNomivativoControl?.value"
                                                        [disabled]="(loading$ | async)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <mat-form-field class="mat-form-field-no-padding pb-2 lg:pb-0"
                                                            *ngIf="data.fetchDottorandi"
                                                            style="width:43%; min-width: 300px;"
                                                            appearance="outline">
                                                <mat-label>{{ t('menu_filter.phd_cicle') }}</mat-label>
                                                <input matInput
                                                       type="number"
                                                       [placeholder]="t('menu_filter.phd_cicle')"
                                                       [formControl]="filtroCicloControl"
                                                       [readonly]="(loading$ | async)">
                                                <button mat-icon-button
                                                        matSuffix
                                                        type="button"
                                                        (click)="svuotaFiltroCiclo()"
                                                        *ngIf="filtroCicloControl?.value"
                                                        [disabled]="(loading$ | async)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>

                                        <div class="lg:pl-2">
                                            <button mat-flat-button
                                                    type="submit"
                                                    class=" text-lg md:text-sm  lg:text-lg"
                                                    [color]="'primary'"
                                                    [disabled]="(loading$ | async)">
                                                <mat-icon class="mr-2">search</mat-icon>
                                                <span>{{ t('home.apply') }}</span>
                                            </button>
                                        </div>


                                    </div>
                                </form>


                            </div>


                            <div class="w-full" *ngIf="showTable">
                                <fuse-alert *ngIf="this.data?.isSingleChoiceSelection && this.data?.infoMessage "
                                            class="items-start"
                                            [appearance]="'outline'"
                                            [type]="alert.type"
                                            [pxPaddingInput]="'6px'"
                                            [pxPaddingRightInput]="'10px'">
                                    {{ t(this.data?.infoMessage) }}
                                </fuse-alert>
                                <div style="border-radius: 6px;  border: 1px solid #e2e8f0;" class="mb-3 mt-2">
                                    <app-generic-table [configuration]="data.table.configuration"
                                                       [righeSelezionate]="data.righeSelezionate"
                                                       [idRigheSolaLettura]="data.idRigheSolaLettura"
                                                       [infoRigheInSolaLettura]="data.showInfoRigheSolaLettura"
                                                       [righeSelezionateCustom]="docentiExtra"
                                                       (tableDataEmitter)="onDataTableChanged($event)"
                                                       [readOnly]="(loading$ | async)"
                                                       (pageAction)="pageAction($event)"
                                                       (customRowChanged)="customSelectedRowChange($event)"></app-generic-table>
                                </div>
                            </div>
                        </div>
                    </app-dialog-wrap>
                </div>

                <!-- Actions -->
                <ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
                    <div
                        class="flex flex-col justify-between lg:flex-row px-4 py-2 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10 mt-2">
                        <div class=" flex items-center justify-center">
                            <fuse-alert
                                *ngIf="(!tableData || (tableData?.removedRows.length === 0 && tableData?.addedRows.length === 0))
                                && (tableData?.selectedRows.length !== 0) && !hasCustomChanged"
                                class="items-start"
                                [appearance]="'outline'"
                                [type]="alert.type"
                                [pxPaddingInput]="'6px'"
                                [pxPaddingRightInput]="'10px'">
                                {{ t('dialog.not_modified_message') }}
                            </fuse-alert>
                        </div>
                        <div class="flex flex-row justify-center sm:justify-end px-4 py-2 space-x-3">
                            <!-- Cancel -->
                            <ng-container *ngIf="data.actions.cancel.show">
                                <button color="accent" mat-flat-button [disabled]="(loading$ | async)"
                                        [matDialogClose]="'cancelled'">
                                    <mat-icon class="mr-2">check</mat-icon>
                                    {{ t(data.actions.cancel.label) || t('common.close') }}
                                </button>
                            </ng-container>

                            <!-- Confirm -->
                            <ng-container *ngIf="data.actions.confirm.show && !data.readOnly">
                                <button mat-flat-button
                                        (click)="save()"
                                        [disabled]="(loading$ | async)
                                        || (!tableData && !hasCustomChanged)
                                        || (tableData?.removedRows.length === 0 && tableData?.addedRows.length === 0 && !hasCustomChanged)
                                        || (this.data?.isSingleChoiceSelection && tableData?.selectedRows.length === 0 && !hasCustomChanged)"
                                        [color]="data.actions.confirm.color">
                                    <mat-icon class="mr-2">check</mat-icon>
                            <span>
                                {{ t(data.actions.confirm.label) || t('dialog.confirm') }}
                            </span>
                                    <mat-progress-spinner
                                        style="margin-left: 5px"
                                        *ngIf="loading$ | async"
                                        [diameter]="24"
                                        [mode]="'indeterminate'"></mat-progress-spinner>
                                </button>

                            </ng-container>
                        </div>

                    </div>
                </ng-container>
            </div>
        </ng-template>

    </div>

</ng-container>

