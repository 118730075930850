<ng-container *transloco="let t">
    <app-dialog-wrap [data]="data">


            <ng-container *ngIf="!!data.isTextArea && !!data.text;">
                <div class="w-full h-full flex  flex-row ">
                    <div style="padding: 10px;">
                        {{data.text}}
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!data.isTextArea">
                <app-info-wrap [info]="data.readonlyData"
                               [data]="data"
                               [ctrlUrlFoto]="ctrlUrlFoto">
                </app-info-wrap>
            </ng-container>


    </app-dialog-wrap>

    <div  class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <button color="accent" mat-flat-button [matDialogClose]="'cancelled'">
            <mat-icon class="mr-2">close</mat-icon>
            {{t('common.close')}}
        </button>
    </div>
</ng-container>
