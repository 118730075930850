import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import moment from "moment";
import {DialogWrapI} from "../../../../../../layout/common/dialog-wrap/dialog-wrap.component";
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData
} from "../../../../../../shared/components/table/model/generic-table-model";
import {UIActivityI} from "../../study-plans-categories-utils";

export interface DialogDefinePresumedI extends DialogWrapI {
    onConfirm: (activity: UIActivityI) => void;
    activitiesConf: GenericTableConfigurationModel;
    activitiesToDisableIds: string[];
    onTableClickAction: (clickEvent: ClickEvent) => void;
}

@Component({
    selector: 'app-dialog-define-presumed',
    templateUrl: './dialog-define-presumed.component.html',
    styleUrls: ['./dialog-define-presumed.component.scss']
})
export class DialogDefinePresumedComponent implements OnInit {

    righeSelezionate: Array<any>;
    tableData: TableData;

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDefinePresumedI,
                public dialogRef: MatDialogRef<DialogDefinePresumedComponent>) {

    }

    ngOnInit(): void {

    }

    closeDialog() {
        this.dialogRef.close();
    }

    confirmAction() {
        this.data?.onConfirm(this.righeSelezionate?.[0]?.data);
        this.closeDialog();
    }


    protected readonly stop = stop;
    protected readonly moment = moment;
    protected readonly confirm = confirm;
    trackByIndex = (index: number, obj: any) => index;

    onAttivitaSelezionateChanged($event: TableData) {
        this.tableData = $event;
        this.righeSelezionate = $event.selectedRows;
    }

}
