import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'app-dialog-wrap',
    templateUrl: './dialog-wrap.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DialogWrapComponent implements OnInit {

    @Input() data: DialogWrapI;
    @Output() close$: EventEmitter<void> = new EventEmitter<void>();
    message: SafeHtml;
    @Input() showPaddingBottom = true;

    constructor(private _sanitizer: DomSanitizer) { }

    transform(value: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }

    ngOnInit(): void {
        if(this.data?.message){
            this.message = this.transform(this.data.message);
        }
    }

}
export interface DialogWrapI {
    close?: boolean;
    title?: string;
    subtitle?: string;
    message?: string;
    icon?: {
        name?: string;
        color?: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
    };
}
