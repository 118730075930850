<ng-container *transloco="let t">

    <form class="w-full" [formGroup]="form" (ngSubmit)="editPressed()">

    <app-dialog-wrap [data]="data">

        <div class="w-full flex flex-col gap-2 mt-2">
            <angular-editor formControlName="guidaCoordinatore"
                            [config]="editorConfig">
            </angular-editor>
            <mat-error *ngIf="formSubmitted && !form?.get('guidaCoordinatore')?.hasError('required') && form?.get('guidaCoordinatore')?.hasError('whitespace')"
                        class="text-xs">
                {{t('form.whitespace')}}
            </mat-error>
            <mat-error *ngIf="formSubmitted && form?.get('guidaCoordinatore')?.hasError('required')"
                       class="text-xs">
                {{t('form.required')}}
            </mat-error>
        </div>

    </app-dialog-wrap>

    <div class="w-full px-6 py-4 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <div fxLayout="row" fxLayoutAlign="end center" class="mt-0.5 mb-0.5 gap-4">
            <button [color]="'accent'" mat-raised-button type="button" (click)="closeDialog()">
                <mat-icon class="mr-2">close</mat-icon>
                {{t('common.close')}}
            </button>
            <div class="flex flex-row items-center gap-2">
                <button [color]="'primary'" mat-raised-button type="submit" [disabled]="!isFormValueChanged()">
                    <mat-icon class="mr-2">check</mat-icon>
                    {{t('common.edit')}}
                </button>
            </div>
        </div>
    </div>

    </form>


</ng-container>

