import {PathEnum} from '../app/app.routing';

export const environment = {
    name: 'vanvitelli-dev',
    production: false,
    servicesUrl: 'https://api-dev-vanvitelli.southengineering.it/dottorandi-backend',
    servicesKeycloak: {
        redirectUri: 'https://dev-vanvitelli.southengineering.it/'+PathEnum.SIGN_IN,
        idpLogout: 'https://idptest.unicampania.it',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_secret: 'EnTIdMpQuJ0Gu4mQbgDSyhnEiNIYw8hy', // for IDPTEST use 'g38vjYuYjVhYgrzll63O3MPp5dboZQ9f',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        client_id: 'atena-dev-internal-cli', // for IDPTEST use 'univ-vanvitelli-cli',
        basePath: 'https://dev-keycloak.southengineering.it/',
        realmName: 'univ-vanvitelli-realm'
    },
    errorsQueueCapacity: 5,
    showStatisticsInHome: false,
    recaptcha: {
        siteKey: '6Ld7ZdkhAAAAABMgYYwCAAUxt4hO7CGK-KKGOObF'
    },
    page: {
        showAnvur: false,
    },
};
